@import "../../../assets/scss/variables.scss";

.downloadapp-modal {
  max-width: calc(100vw - 46px);

  .ant-modal-close {
    right: 15px;
    top: 15px;

    .ant-modal-close-x {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $body-background-light;

      .icon {
        fill: $primary-color;
        width: 10px;
      }

      &:hover {
        background: $secondary-color;

        .icon {
          fill: $white;
        }
      }
    }
  }

  .ant-modal-body {
    padding: 4rem 3.5rem;
    @media screen and (max-width: 640px) {
      padding: 2rem 1.2rem;
    }
  }

  .download-left {
    max-width: 460px;
    position: relative;
    z-index: 2;

    h1 {
      font-size: 2.5rem;
      @media screen and (max-width: 767px) {
        font-size: 2rem;
      }
    }

    p {
      font-size: 1.1rem;
      color: #132C4A;
      line-height: 1.3;
      max-width: 92.5%;
    }

    .download-btns {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1.2rem;
      max-width: 330px;
      margin-top: 3rem;
      @media screen and (max-width: 640px) {
        grid-gap: 0.8rem 0.6rem;
      }

      .ant-btn {
        border: 1px solid #718092;
        height: 50px;
        line-height: 50px;
        padding: 0 15px;
        color: $white;
        font-size: 1rem;
        display: flex;
        align-items: center;
        border-radius: 9px;
        color: $primary-color;
        transition: all ease-in 0.1s;
        font-weight: normal;
        @media screen and (max-width: 640px) {
          font-size: 0.85rem;
          padding: 0 8px 0 10px;
        }
        @media screen and (max-width: 400px) {
          font-size: 0.75rem;
          height: 40px;
          line-height: 40px;
        }

        .icon {
          fill: $primary-color;
          height: 25px;
          width: 25px;
          margin-right: 10px;
          transition: all ease-in 0.1s;
          @media screen and (max-width: 640px) {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }

        &:hover {
          background: $primary-color;
          border-color: $primary-color;
          color: $white;
          transform: translateY(-4px);

          .icon {
            fill: $white;
          }
        }
      }
    }
  }

  .download-right {
    .right-inner {
      position: relative;
      max-width: 460px;
      width: 100%;
      margin-left: auto;
      text-align: center;
      display: flex;
      align-items: center;
      height: 100%;
      @media screen and (max-width: 767px) {
        min-height: 350px;
        margin-top: 1rem;
      }

      .download-circle {
        width: 229px;
        height: 229px;
        border-radius: 229px;
        display: inline-block;
        background: transparent linear-gradient(180deg, #FF7541 0%, #FFFFFF41 100%);
        z-index: 0;
        position: relative;
        bottom: -20%;
        margin: auto;
      }

      .download-img {
        max-height: 361px;
        position: relative;
        z-index: 2;
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        top: -10%;
        bottom: 0;
        z-index: 3;

        @media screen and (max-width: 453px) {
          right: 0;
          left: 0;
          max-width: 100%;
        }
      }
    }
  }
}