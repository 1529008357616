@import '../../../assets/scss/variables.scss';

.getlistedform-alt-wrapper{
    display:flex;
    max-width: 100vw;
    width: 100%;
    @media screen and (max-width:767px) {
        flex-direction: column-reverse;
    }
    .getlistedform-left{
        width: 50%;
        object-fit: fill;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        background: rgb(216, 244, 241);
        background: -moz-linear-gradient(224deg, rgba(216, 244, 241, 1) 0%, rgba(245, 233, 225, 1) 97%, rgba(255, 229, 219, 1) 100%);
        background: -webkit-linear-gradient(224deg, rgba(216, 244, 241, 1) 0%, rgba(245, 233, 225, 1) 97%, rgba(255, 229, 219, 1) 100%);
        background: linear-gradient(224deg, rgba(216, 244, 241, 1) 0%, rgba(245, 233, 225, 1) 97%, rgba(255, 229, 219, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d8f4f1", endColorstr="#ffe5db", GradientType=1);
        @media screen and (max-width:767px) {
            width: 100%;
        }
        .auth-bg {
            max-height: 100vh;
            width: 100%;
        }
        .banner-video{
            min-height: 100vh;
            max-height: 100vh;
            width: 100%;
            margin: auto;
            left: 0;
            right: 0;
            object-fit: cover;
        }
        .getlistedform-inner{
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: absolute;
            width: 100%;
            padding: 5% 8% 8% 8%;
            z-index: 1;
            @media screen and (max-width:767px) {
                position: initial;
            }
            h1{
                color: $primary-color;
                line-height: 1.3;
                margin-top: 13vh;
                font-size: 3.5rem;
                max-width: 600px;
            }
            p{
                font-size: 1.2rem;
                line-height: 1.4;
                max-width: 400px;
                color: $primary-color;
                margin-bottom: 0.5rem;
                margin-top: 0;
            }
        }
    }
    .getlistedform-right{ 
        width: 50%;
        min-height: 100vh;
        text-align: center;
        display: flex;
        align-items: center;
        @media screen and (max-width:767px) {
            width: 100%;
            min-height: 80vh;
        }
        .ant-form {
            width: 100%;
            margin: 0;
        }
        .getlistedform-inner{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: auto;
            width: 100%;
            padding: 0;
            min-height: 100vh;
            @media screen and (max-width:767px) {
                min-height: 80vh;
            }
            .right-form {
                padding: 2rem;
            }
            .right-upper{
                padding: 2rem 3rem 0 3rem;
                @media screen and (max-width:991px) {
                    padding: 2rem 2rem 0 2rem;
                }
                .mobile-logo {
                    display: none;
                    text-align: left;
                    margin-bottom: 2rem;
                    @media screen and (max-width:767px) {
                        display: block;
                    }
                }
                .upper-inner{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 100%;
                    .help-btn {
                        padding: 0;
                        height: 20px;
                        line-height: 20px;
                        background: none;
                        font-size: 13px;
                        &:hover {
                            color: $secondary-color;
                        }
                    }
                    ul {
                        list-style-type: none;
                        padding-left: 10px;
                        display: flex;
                        align-items: center;
                        margin: 0;
                        li {
                            height: 20px;
                            margin-left: -6px;
                            &:nth-child(1) {
                                margin-left: 0;
                            }
                            img {
                                width: 20px;
                            }
                        }
                    }
                    .right-head{
                        text-align: right;
                        display: flex;
                        align-items: center;
                    }
                    .auth-upper-btn{
                        border: 1px solid #F5FCFC;
                        background: #F5FCFC;
                        height: 30px;
                        line-height: 30px;
                        padding: 0 15px;
                        border-radius: 30px;
                        font-size: 12px;
                        font-weight: normal;
                        margin-left: 10px;
                        color: $primary-color;
                        min-width: 64px;
                        display: inline-block;
                        text-decoration: none;
                        text-align: center;
                        &:hover, &:focus{
                            color: $white;
                            border-color: $secondary-color;
                            background: $secondary-color;
                        }
                    }
                }
            }
            .right-form{
                .ant-form-item{
                    .ant-form-item-label{
                        margin-bottom: 5px;
                        padding-left: 0;
                        font-size: $font-size-base;
                        label {
                            color: $primary-color;
                        }
                    }
                    .ant-input-affix-wrapper{
                        .ant-input-prefix{
                            .icon {
                                width: 20px;
                                border-radius: 20px;
                                fill: $primary-color;
                            }
                            .ccode{
                                font-weight: 600;
                                color: $primary-color;
                                font-size: 1rem;
                                margin-left: 4px;
                            }
                        }
                        .ant-input{
                            font-size: 1rem;
                            &::placeholder {
                                font-weight: 600;
                                color: $primary-color;
                            }
                            &::-moz-placeholder {
                                font-weight: 600;
                                color: $primary-color;
                            }
                            &::-webkit-placeholder {
                                font-weight: 600;
                                color: $primary-color;
                            }
                        }
                    }
                }
                .category-list{
                    max-width: 500px;
                    margin: auto;
                    .ant-radio-group {
                        width: 100%;
                        .ant-radio-button-wrapper {
                            width: 100%;
                            margin: 8px 0;
                            height: 68px;
                            border-radius: 10px;
                            border-width: 1px;
                            &.ant-radio-button-wrapper-checked {
                                box-shadow: 0px 3px 16px #00000012;
                                border-color: $white;
                            }
                            &::before {
                                display: none;
                            }
                            .ctg-list {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                height: 66px;
                                padding-left: 8px;
                                h4 {
                                    margin-bottom: 0;
                                    font-weight: 600;
                                }
                                .ctg-img {
                                    width: 45px;
                                    height: 45px;
                                    border-radius: 9px;
                                    overflow: hidden;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }
                        }
                    }
                }
                .with-icon {
                    display: flex;
                    align-items: center;
                    flex-direction: row-reverse;
                    justify-content: center;
                    margin: auto;
                    .icon {
                        margin-left: 12px;
                    }
                }
                .agree-check{
                    text-align: left;
                    label{
                        font-weight: 500;
                        align-items: center;
                        line-height: 1;
                    }
                    .ant-checkbox{
                        top: 0;
                    }
                    .terms-link{
                        color: $secondary-color;
                    }
                }
            }
            .getlistedform-right-footer{
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-top: 1px solid $border-secondary;
                padding: 1.1rem 1.8rem 1.1rem 2.6rem;
                position: relative;
                &::before {
                    content: ' ';
                    position: absolute;
                    background: $primary-color;
                    top: -1px;
                    width: 80px;
                    left: 0;
                    height: 1px;
                }
                a {
                    color: $text-primary;
                    border-bottom: 1px solid $border-primary;
                }
                .ant-btn-secondary {
                    text-align: center;
                    .icon {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}