@import '../../../assets/scss/variables.scss';

.bmc-checkout-success{
    background: $white;
    padding: 0 2.5rem 3.5rem;
    max-width: 570px;
    margin: 6rem auto;
    text-align: center;
    .success-check{
        width: 147px;
        height: 147px;
        border-radius: 147px;
        border: 1px solid $success-color;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto auto 1.8rem auto;
        .icon {
            width: 60px;
            fill: $success-color;
        }
    }
    h2{
        font-size: 1.875rem;
        font-weight: 600;
        margin-bottom: 0.2rem;
    }
    p{
        font-size: 1.25rem;
    }
}