@import '../../assets/scss/variables.scss';

.auth-wrapper {
  .otpform-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .right-form-top {
    margin-top: auto;
    margin-bottom: auto;

    .otpform-inner {
      min-height: 65vh;

      .otpform-upper {
        height: 100%;
        text-align: left;

        .otp-input-container {
          .otp-input {
            margin-right: 1rem;

            input {
              border: 1px solid $border-primary;
              width: 70px;
              min-width: 70px;
              height: 70px;
              border-radius: 8px;
              outline: none;
              font-size: 1.5rem;
              font-family: $font-family;

              &:focus {
                border-color: $primary-color;
              }
            }
          }
        }

        .optsreend {
          font-size: 1rem;

          .resend-btn, a {
            color: $secondary-color;
            text-decoration: none;
            padding: 0 2px;
            
            &:hover,&:focus{
              text-decoration: underline;
              background: transparent;
            }
          }
        }

        h2 {
          font-size: 30px;
          font-weight: 600;
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }
}