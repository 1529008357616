@import '../../assets/scss/variables.scss';

.twl-details-wrapper {
  padding-top: 5rem;
  @media screen and (max-width: 767px) {
    position: relative;
    z-index: 3;
  }

  .prive-mode & {
    background: $primary-color;
    color: $white;
  }

  .mobile-redeem-action {
    position: fixed;
    bottom: 0;
    width: 100vw;
    left: 0;
    background: #F5FCFC;
    padding: 15px 20px;
    z-index: 9;
    display: flex;
    align-items: center;

    .prive-mode & {
      background: darken($primary-color, 3)
    }

    .left-actions {
      width: 50%;
      display: flex;
      align-items: center;

      .ant-btn-link {
        width: 42px;
        height: 42px;
        border-radius: 42px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover, &:focus {
          background: $secondary-color;

          .prive-mode & {
            background: $prive-color;
          }

          .icon {
            fill: $white;
          }
        }

        &:nth-child(2) {
          margin-left: 15px;
        }

        .icon {
          width: 14px;
          height: 14px;
        }
      }
    }

    .ant-btn-primary {
      width: 50%;
      font-size: 16px;
      font-weight: normal;
      height: 48px;
      line-height: 48px;
      margin: 0;
      border: 0 !important;

      .prive-mode & {
        background: darken($white, 50);
        color: $white;
      }
    }
  }

  .details-mobile-header {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    z-index: 4;
    padding: 0 18px;
    width: 100%;

    .back-arrow {
      width: 28px;
      height: 28px;
      border-radius: 28px;
      background: $white;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon {
        width: 10px;
        height: 11px;
        margin: auto;
      }
    }

    .right-action {
      display: flex;
      align-items: center;

      .ant-btn {
        width: 28px;
        height: 28px;
        border-radius: 28px;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin-left: 12px;

        .icon {
          width: 12px;
          height: 12px;
          margin: auto;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding-top: 0;
  }

  .container-upper {
    @media screen and (max-width: 767px) {
      max-width: 100%;
      padding: 0;
      overflow-x: hidden;
    }
  }

  .mobile-details-slider {
    .slick-slider {
      .slick-list {
        .slick-slide {
          height: 340px;

          div {
            height: 100%;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .details-images {
    display: flex;

    .left-image {
      width: 50%;
      padding-right: 6px;
      position: relative;

      @media screen and (max-width: 767px) {
        padding-right: 8px;
      }

      .square-image-container {
        height: calc(100% + 0px);
      }


      [data-rmiz-content] {
        height: 100%;
      }

      iframe {
        width: 100%;
        border-radius: 10px 0 0 10px;
      }

      img {
        width: 100%;
        border-radius: 10px 0 0 10px;
      }
    }

    .right-image {
      display: grid;
      grid-gap: 12px 12px;
      grid-template-columns: repeat(2, 1fr);
      width: 50%;
      position: relative;
      padding-left: 6px;

      @media screen and (max-width: 767px) {
        grid-gap: 10px;
      }

      [data-rmiz-content] {
        height: 100%;
      }

      [data-rmiz]:nth-child(2) {
        [data-rmiz-content] {
          img {
            border-radius: 0 10px 0 0;
          }
        }
      }

      [data-rmiz]:nth-child(4) {
        [data-rmiz-content] {
          img {
            border-radius: 0 0 10px 0;
          }
        }
      }

    }
  }

  .details-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    align-items: flex-start;
    -webkit-box-align: start;
    padding: 40px 0 0 0;
    width: 100%;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 20px 20px;
    }

    &:has(> .details-left:first-child  > .square-image-container:first-child) {
      padding: 0;
    }

    .details-left {
      width: 100%;
      padding-right: 3rem;

      .square-image-container:first-child {
        padding: 70% 0 0 0;
        margin-bottom: 20px;

        img {
          border-radius: 10px;
        }
      }

      @media screen and (max-width: 767px) {
        padding-right: 0;
      }

      .dtl-upper {
        display: flex;
        align-items: flex-end;
        width: 100%;
        border-bottom: 1px dashed $label-color;
        padding-bottom: 38px;
        margin-bottom: 30px;

        @media screen and (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
        }

        .dtl-upperleft {
          width: 100%;

          .rating {
            .icon {
              margin-right: 3px;
            }
          }

          .dlt-logo {
            width: 58px;
            height: 58px;
            border-radius: 58px;
            border: 1px solid #C6CCD3;
            overflow: hidden;
            margin-top: 1rem;
            margin-bottom: 0.5rem;

            img {
              width: 100%;
            }
          }

          h1 {
            font-size: 50px;
            line-height: 1.3;
            max-width: 500px;
            margin-bottom: 1.5rem;

            .prive-mode & {
              color: $white;
            }

            @media screen and (max-width: 767px) {
              font-size: 40px;
            }

            @media screen and (max-width: 480px) {
              font-size: 35px;
            }
          }

          .location {
            display: flex;
            align-items: center;
            color: $label-color;
            font-size: 18px;

            .prive-mode & {
              color: rgba($white, 0.6);
            }

            .icon {
              fill: $label-color;
              width: 9px;
              margin-right: 8px;
            }
          }
        }

        .dtl-upperright {
          display: flex;
          justify-content: flex-end;

          @media screen and (max-width: 767px) {
            margin-top: 20px;
          }

          .ant-btn {
            display: flex;
            align-items: center;
            padding: 0 0;
            height: 20px;
            font-size: 14px;
            line-height: 20px;

            .prive-mode & {
              background: none;
              color: $label-color;
            }

            &:first-child {
              margin-right: 20px;
            }

            @media screen and (max-width: 767px) {
              margin-left: 0;
            }

            .icon {
              margin-left: 6px;
              width: 15px;
              height: 15px;

              .prive-mode & {
                fill: $white;
              }
            }

            span {
              text-decoration: underline;
              color: $primary-color !important;

              .prive-mode & {
                color: $white !important;
              }
            }
          }
        }
      }

      .whatexpect-row {
        padding-bottom: 38px;
        margin-bottom: 30px;

        h2 {
          font-size: 40px;
          margin-bottom: 25px;
          font-weight: 600;

          .prive-mode & {
            color: $white;
          }
        }

        ul {
          list-style-type: none;
          padding-left: 0;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 25px 15px;

          @media screen and (max-width: 1440px) {
            grid-gap: 18px 15px;
          }

          li {
            font-size: 20px;
            display: flex;
            align-items: center;

            .check-circle {
              width: 40px;
              height: 40px;
              border-radius: 40px;
              min-width: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid $border-primary;
              margin-right: 18px;
              @media screen and (max-width: 767px) {
                width: 30px;
                height: 30px;
                border-radius: 30px;
                min-width: 30px;
              }

              .icon {
                @media screen and (max-width: 767px) {
                  width: 14px;
                }
              }
            }
          }
        }
      }

      .overview-row {
        border-bottom: 1px dashed $label-color;
        padding-bottom: 38px;
        margin-bottom: 30px;

        h2 {
          font-size: 40px;
          margin-bottom: 25px;
          font-weight: 600;

          .prive-mode & {
            color: $white;
          }
        }

        p {
          font-size: 16px;
          max-width: 700px;
          line-height: 1.5;
          margin-bottom: 0;
        }

        a {
          text-decoration: underline;
          color: $primary-color !important;

          .prive-mode & {
            color: $white;
          }
        }
      }

      .highlights-row {
        border-bottom: 1px dashed $label-color;
        padding-bottom: 38px;
        margin-bottom: 30px;

        @media screen and (max-width: 767px) {
          padding-bottom: 20px;
          margin-bottom: 20px;
        }

        h2 {
          font-size: 40px;
          margin-bottom: 25px;
          font-weight: 600;

          .prive-mode & {
            color: $white;
          }

          @media screen and (max-width: 767px) {
            font-size: 30px;
          }
        }

        ul {
          list-style-type: none;
          padding-left: 0;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 35px 15px;

          @media screen and (max-width: 1440px) {
            grid-gap: 18px 15px;
          }

          li {
            font-size: 20px;
            display: flex;
            align-items: center;

            @media screen and (max-width: 1140px) {
              font-size: 16px;
            }

            @media screen and (max-width: 640px) {
              font-size: 14px;
            }

            @media screen and (max-width: 576px) {
              font-size: 12px;
            }

            .left-icon {
              width: 60px;
              height: 60px;
              min-width: 60px;
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 30px;
              border: 1px solid $border-primary;

              .prive-mode & {
                border-color: $label-color;
              }

              @media screen and (max-width: 1140px) {
                margin-right: 15px;
                width: 40px;
                height: 40px;
                min-width: 40px;
                border-radius: 6px;
              }

              @media screen and (max-width: 767px) {
                margin-right: 10px;
              }

              @media screen and (max-width: 640px) {
                width: 35px;
                height: 35px;
                min-width: 35px;
                border-radius: 5px;
                margin-right: 8px;
              }

              @media screen and (max-width: 576px) {
                width: 30px;
                height: 30px;
                min-width: 30px;
              }

              .icon {
                width: 30px;
                height: 30px;
                fill: $primary-color;

                .prive-mode & {
                  fill: $white;
                }

                @media screen and (max-width: 1140px) {
                  width: 20px;
                  height: 20px;
                }
                @media screen and (max-width: 640px) {
                  width: 18px;
                  height: 18px;
                }
                @media screen and (max-width: 576px) {
                  width: 16px;
                  height: 16px;
                }
              }
            }

            label {
              color: $text-secondary;
              font-size: 12px;
              display: block;
            }

          }
        }
      }
    }

    .details-right {
      width: 100%;
      max-width: 380px;
      position: sticky;
      top: 28px;
      background: $white;
      padding: 28px;
      border-radius: 10px;
      box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.05);

      .prive-mode & {
        color: $white;
        background: darken($primary-color, 3);
      }

      @media screen and (max-width: 767px) {
        max-width: 100%;
      }

      .head {
        margin-bottom: 10px;

        h4 {
          font-size: 18px;
          margin-bottom: 5px;

          .prive-mode & {
            color: $white;
          }
        }

        p {
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 1.5px;

          .prive-mode & {
            color: $white;
          }
        }
      }

      .ant-list {
        .ant-list-item {
          position: relative;
          padding: 6px 0;
          border: unset;

          .offer-item {
            background: rgb(196, 234, 230);
            background: linear-gradient(249deg, rgba(196, 234, 230, 1) 0%, rgba(255, 195, 172, 1) 100%);
            border-radius: 8px;
            width: 100%;
            position: relative;
            padding: 8px;

            .prive-mode & {
              background: rgb(85,62,62);
              background: linear-gradient(135deg, rgba(85,62,62,1) 0%, rgba(0,45,62,1) 100%);
            }

            .circleTop {
              position: absolute;
              right: 24px;
              top: -4px;
              background: $white;
              width: 16px;
              height: 8px;
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
              
              .prive-mode & {
                background: darken($primary-color, 3);
              }
              
            }

            .circleBottom {
              position: absolute;
              right: 24px;
              bottom: -4px;
              width: 16px;
              height: 8px;
              background: $white;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;

              .prive-mode & {
                background: darken($primary-color, 3);
              }
            }

            .offer-item-inner {
              background: $white;
              width: 100%;
              border-radius: 4px;
              display: flex;
              align-items: center;

              .prive-mode & {
                background: transparent;
              }

              .offer-item-content {
                flex: 1;
                min-height: 100px;
                border-right: 2px dashed #C6CCD3;
                padding: 8px;
                display: flex;
                flex-direction: row;
                
                .prive-mode & {
                  border-right: 1px dashed #C6CCD3;
                }
                

                .left {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  flex-grow: 1;
                  
                  .prive-logo {
                    fill: $white;
                    height: 16px;
                  }

                  h4 {
                    font-size: 22px;
                    font-weight: 600;
                    color: $primary-color;
                    .prive-mode & {
                      color: $white;
                    }
                  }

                  p {
                    font-size: 12px;
                    color: $primary-color;
                    line-height: 1.2;
                    .prive-mode & {
                      color: $white;
                    }
                  }
                }

                .right {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  
                  .btn-available{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 10px;
                    height: 24px;
                    color: $primary-color;
                    text-align: center;
                    border-radius: 12px;
                    border: 1px solid #C6CCD3;
                    padding: 0 4px;
                    margin-top: 4px;
                    min-width: 50px;

                    .prive-mode & {
                      color: $white;
                    }
                  }
                  .btn-use{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: $primary-color;
                    font-size: 10px;
                    color: $white;
                    text-align: center;
                    height: 24px;
                    border-radius: 12px;
                    margin-bottom: 4px;
                    min-width: 50px;

                    .prive-mode & {
                      background: $prive-color;
                    }
                  }
                }
              }

              .offer-item-logo {
                width: 24px;
                display: flex;
                align-items: center;
                justify-content: center;

                [data-name="Group 30857"] {
                  .prive-mode & {
                    fill: white;
                  }
                }
              }
            }
          }

          .ant-list-item-action {
            margin-inline-start: 0;
            position: absolute;
            top: 0;
            right: 0;

            li {
              width: 28px;
              height: 28px;
              border-radius: 28px;
              background: #6FC3CE;
              border: 2px solid white;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0;
              box-shadow: 0 3px 6px #00000029;

              .icon {
                width: 12px;
                height: 12px;
                fill: $white;
              }
            }
          }
        }

        /*  .ant-list-item {
            padding: 15px 0;
            cursor: pointer;
  
            .prive-mode & {
              border-color: #FFFFFF33;
            }
            
            .ant-list-item-action {
              margin-left: 5px;
  
              li {
                .icon {
                  background: #6FC3CE;
                  padding: 7px;
                  width: 25px;
                  height: 25px;
                  border-radius: 25px;
                  fill: $white;
                }
              }
            }
  
            .ant-list-item-meta {
              .ant-list-item-meta-avatar {
                width: 52px;
                height: 52px;
                border-radius: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 12px;
  
                .ant-avatar {
                  background: none;
                }
  
                img {
                  width: 100%;
                }
              }
  
              .ant-list-item-meta-title {
                font-size: 17px;
                font-weight: 600;
                color: $primary-color;
                margin-bottom: 0;
  
                .prive-mode & {
                  color: $white;
                }
              }
  
              .ant-list-item-meta-description {
                font-size: 12px;
                color: $primary-color;
  
                .prive-mode & {
                  color: $white;
                }
              }
            }
  
            &:nth-child(1) {
              .ant-list-item-meta {
                .ant-list-item-meta-avatar {
                  background: #FFF2EC;
                }
              }
            }
  
            &:nth-child(2) {
              .ant-list-item-meta {
                .ant-list-item-meta-avatar {
                  background: #F5FCFC;
                }
              }
            }
  
            &:nth-child(3) {
              .ant-list-item-meta {
                .ant-list-item-meta-avatar {
                  background: #F1EAF2;
                }
              }
            }
          }*/
      }

      .bottom-action {
        margin-top: 1.8rem;

        .btn-row {
          @media screen and (max-width: 767px) {
            position: fixed;
            bottom: 0;
            width: 100vw;
            left: 0;
            background: $white;
            z-index: 99999;
          }
        }

        .ant-btn {
          margin-bottom: 18px;
          font-size: 18px;
          font-weight: 600;
          height: 60px;
          line-height: 60px;

          .prive-mode & {
            color: $white;
          }

          &:disabled {
            .prive-mode & {
              background: lighten($black, 50);
            }
          }

          &.ant-btn-background-ghost {
            .prive-mode & {
              color: $white;
            }
          }
        }

        .ant-btn-link {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          margin-bottom: 0;

          .prive-mode & {
            color: $border-secondary;
            background: none;
          }

          span {
            text-decoration: underline;
            font-size: 14px;
            font-weight: normal;
            color: $primary-color !important;

            .prive-mode & {
              color: $white !important;
            }
          }

          .icon {
            fill: $secondary-color;
            width: 20px;
            margin-right: 6px;
          }
        }
      }

      .getit-touch {
        border: 1px dashed $border-primary;
        padding: 1.6rem 1.4rem;
        border-radius: 10px;
        font-size: 0.875rem;
        margin-top: 2.5rem;

        p {
          margin-bottom: 0;
          max-width: 250px;
          color: #132C4A;
        }

        a {
          color: $secondary-color;
        }

        .upper-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0.5rem;

          h3 {
            font-weight: 600;
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .other-vanues {
    padding: 10px 0 65px;
    border-bottom: 1px dashed $border-primary;
    margin-bottom: 30px;

    @media screen and (max-width: 767px) {
      padding: 10px 25px 65px 25px;
    }

    h2 {
      font-size: 40px;
      margin-bottom: 15px;
      font-weight: 600;

      .prive-mode & {
        color: $white;
      }

      @media screen and (max-width: 767px) {
        font-size: 30px;
      }
    }

    .slick-slider {
      .slick-arrow {
        color: $primary-color;
        font-size: 1.25rem;
        z-index: 2;
        position: absolute;
        opacity: 1;
        border: 1px solid $border-secondary;
        width: 25px;
        height: 25px;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          fill: $primary-color;
          width: 5px;

          .prive-mode & {
            fill: $border-secondary;
          }
        }

        &::before {
          display: none;
        }

        &.slick-next {
          bottom: -40px;
          top: auto;
          left: 38px;
          right: auto;
        }

        &.slick-prev {
          bottom: -40px;
          top: auto;
          right: auto;
          left: 0;
        }

        &.slick-disabled {
          opacity: 0.5;
          cursor: inherit;
        }
      }

      .slick-list {
        margin: 0;

        .slick-slide {
          padding: 10px 20px 15px 0;

          .vanues-card {
            border: 1px solid $border-primary;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            overflow: hidden;

            .prive-mode & {
              border-color: darken($primary-color, 3);
              background: darken($primary-color, 3);
            }

            .card-left {
              display: flex;
              align-items: center;
              padding: 15px;

              .vanues-image {
                width: 60px;
                height: 60px;
                border-radius: 60px;
                margin-right: 15px;
                overflow: hidden;
                border: 1px solid #C6CCD3;

                img {
                  width: 100%;
                }
              }

              .vanues-info {
                h4 {
                  font-size: 20px;
                  font-weight: 600;
                  margin-bottom: 5px;

                  .prive-mode & {
                    color: $white;
                  }
                }

                p {
                  font-size: 16px;
                  margin-bottom: 0;

                  .prive-mode & {
                    color: $label-color;
                  }
                }
              }
            }

            .card-action {
              display: flex;
              flex-direction: column;
              border-left: 1px solid $border-primary;

              .prive-mode & {
                border-color: #132C4A;
              }

              .ant-btn {
                height: 50%;
                border-width: 0 0 1px 0;
                border-color: $border-primary;
                border-style: solid;
                border-radius: 0;
                padding: 8px 5px;
                width: 45px;

                .prive-mode & {
                  border-color: #132C4A;
                  background: none;
                }

                .icon {
                  width: 11px;

                  .prive-mode & {
                    fill: $white;
                  }
                }

                &:last-child {
                  border: 0;
                }

                &:hover {
                  border-color: $primary-color;

                  .icon {
                    fill: darken($white,50);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .faq-section {
    padding: 1rem 0 3rem;

    @media screen and (max-width: 767px) {
      padding: 1rem 0 2rem;
    }

    h2 {
      font-size: 40px;
      margin-bottom: 15px;
      font-weight: 600;

      .prive-mode & {
        color: $white;
      }

      @media screen and (max-width: 767px) {
        font-size: 30px;
      }
    }

    .faq-left {
      h1 {
        font-size: 3.125rem;
        max-width: 450px;
        line-height: 1.2;
        margin-bottom: 30px;

        .prive-mode & {
          color: $white;
        }

        @media screen and (max-width: 767px) {
          font-size: 2.125rem;
          margin-bottom: 1.5rem;
        }
      }

      .video-card {
        width: 100%;
        max-width: 362px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;

        @media screen and (max-width: 767px) {
          margin-bottom: 1.5rem;
        }

        .caption {
          position: absolute;
          top: 35px;
          left: 35px;

          .play-icon {
            width: 42px;
            height: 42px;
            border-radius: 42px;
            padding: 0;
            background: none;
            animation: ripple 0.7s linear infinite;

            &:hover {
              .icon {
                border-radius: 42px;
                fill: $secondary-color;
                background: $white;
              }
            }
          }

          .icon {
            width: 42px;
            fill: $white;
          }

          p {
            font-size: 1.375rem;
            font-weight: 600;
            margin-top: 0.95rem;
            color: $white;
            max-width: 160px;
            line-height: 1.2;
          }
        }

        img {
          width: 100%;
        }
      }
    }

    .faq-right {
      .download-btns {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1rem;
        max-width: 330px;
        margin-top: 1rem;

        @media screen and (max-width: 1280px) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (max-width: 991px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: 400px) {
          grid-gap: 0.5rem;
        }

        .ant-btn {
          border: 1px solid #718092;
          height: 50px;
          line-height: 50px;
          padding: 0 15px;
          font-size: 1rem;
          display: flex;
          align-items: center;
          border-radius: 9px;
          color: $primary-color;
          transition: all ease-in 0.1s;
          background: none;
          font-weight: normal;

          .prive-mode & {
            color: $white;
          }

          @media screen and (max-width: 767px) {
            font-size: 0.875rem;
            padding: 0 8px 0 12px;
          }

          @media screen and (max-width: 400px) {
            font-size: 0.8rem;
          }

          .icon {
            fill: $primary-color;
            height: 25px;
            width: 25px;
            margin-right: 10px;
            transition: all ease-in 0.1s;

            .prive-mode & {
              fill: $white;
            }

            @media screen and (max-width: 767px) {
              width: 20px;
              height: 20px;
              margin-right: 6px;
            }

            @media screen and (max-width: 400px) {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
          }

          &:hover {
            background: $primary-color;
            border-color: $primary-color;
            color: $white;
            transform: translateY(-4px);

            .prive-mode & {
              border-color: #718092;
            }

            .icon {
              fill: $white;
            }
          }
        }
      }

      .ant-collapse {
        background: none;
        border: 0;

        .ant-collapse-item {
          border: 0;
          margin-bottom: 0.4rem;

          .ant-collapse-header {
            background: none;
            border: 0;
            padding: 15px 0;
            font-size: 1.25rem;
            display: flex;
            flex-direction: row-reverse;
            border-bottom: 1px solid gainsboro;
            border-radius: 0px !important;
            color: $primary-color;


            .prive-mode & {
              color: $white;
            }

            &:hover {
              color: $secondary-color;
            }

            .ant-collapse-expand-icon {
              height: 30px;
              display: flex;
              align-items: center;
              padding-right: 0;

              .icon {
                width: 16px;
                height: 16px;

                .prive-mode & {
                  fill: $white;
                }
              }
            }

            .downArrow {
              transition: .3s;
              transform: rotate(0);
            }
          }

          &.ant-collapse-item-active {
            .ant-collapse-header {
              font-weight: 600;
            }

            .downArrow {
              transform: rotate(90deg);
              transition: .3s;
            }
          }

          .ant-collapse-content-box {
            padding: 15px 0 30px 0 !important;
          }

          .ant-collapse-content {
            border: 0;

            .prive-mode & {
              background: none;
              color: $white;
            }

            p {
              font-size: 1rem;
            }

            .ant-collapse-content-box {
              padding: 0 0 0 58px;
            }
          }
        }
      }
    }
  }

  .terms-section {
    padding: 1rem 0 3rem;

    @media screen and (max-width: 767px) {
      padding: 1rem 0 2rem;
    }

    h2 {
      font-size: 40px;
      margin-bottom: 15px;
      font-weight: 600;

      .prive-mode & {
        color: $white;
      }

      @media screen and (max-width: 767px) {
        font-size: 30px;
      }
    }

    ul {
      li {
        color: $primary-color;
        font-size: 20px;
        line-height: 48px;

        .prive-mode & {
          color: $white;
        }

        a {
          color: $primary-color !important;
          text-decoration: none;

          .prive-mode & {
            color: $white;
          }
        }
      }
    }
  }

  .similervanues-section {
    hr {
      margin-bottom: 38px;
      border-style: dashed;
      border-width: 1px;
      border-bottom: 0;
      border-color: #C6CCD3;
    }

    h2 {
      font-size: 40px;
      margin-bottom: 15px;
      font-weight: 600;
      @media screen and (max-width: 767px) {
        font-size: 30px;
      }
      
      .prive-mode &{
        color: $white;
      }
    }

    .slick-slider {
      .slick-arrow {
        color: $primary-color;
        font-size: 1.25rem;
        z-index: 2;
        position: absolute;
        opacity: 1;
        border: 1px solid $border-secondary;
        width: 25px;
        height: 25px;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          fill: $primary-color;
          width: 5px;

          .prive-mode & {
            fill: $border-secondary;
          }
        }

        &::before {
          display: none;
        }

        &.slick-next {
          top: -25px;
          bottom: auto;
          left: auto;
          right: 0;
        }

        &.slick-prev {
          top: -25px;
          bottom: auto;
          right: 38px;
          left: auto;
        }

        &.slick-disabled {
          opacity: 0.5;
          cursor: inherit;
        }
      }

      .slick-list {
        margin: 0 -15px;

        .slick-slide {
          padding: 20px 15px 20px 15px;
        }
      }
    }
  }
}