@import "../../../assets/scss/variables.scss";

.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 30px;
  z-index: 3;
  position: absolute;
  top: 0;


  @media screen and (max-width: 991px) {
    padding: 15px 0;
  }

  &.main-header-search {
    border-bottom: 1px solid rgba($border-secondary, 0.5);
    background: $white;
    position: fixed;
    z-index: 99;

    .prive-mode & {
      background: $primary-color;
    }

    .hader-inner {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .container {
      @media screen and (max-width: 767px) {
        padding: 0 15px;
      }
    }

    .logo-left {
      display: flex;
      align-items: center;
      padding-top: 8px;

      @media screen and (max-width: 767px) {
        display: none;
      }

      a {
        display: flex;
        align-items: center;
      }

      .logo-icon {
        width: 20px;
        margin-right: 10px;
      }

      .logo-name {
        width: 135px;
        fill: var(--text-color);

        .prive-mode & {
          fill: $white;
        }
      }

    }

    .tabssmall-header {
      position: absolute;
      top: -30px;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      z-index: 2;

      label {
        color: $text-secondary;
        font-size: 14px;
        margin: 0 8px;
        cursor: pointer;

        &.active {
          font-weight: 600;
          color: $text-primary;
        }
      }
    }

    .center-menu {
      width: 100%;
      text-align: center;

      @media screen and (max-width: 991px) {
        max-width: 50px;
        margin-left: auto;
        margin-right: 15px;
      }

      .ant-menu {
        margin: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        border: 0;
        background: none;

        .ant-menu-item {
          color: $primary-color;
          font-size: 16px;
          padding: 0 15px;

          a {
            color: $primary-color;

            &.active {
              color: $secondary-color;
            }
          }

          &::after {
            width: 30px;
            border-width: 1px;
            display: none;
          }

          &:hover {
            color: $secondary-color;

            &::after {
              border-color: $primary-color;
            }
          }
        }
      }
    }

    .main-search {
      display: flex;
      align-items: center;
      max-width: 520px;
      margin: auto;
      width: 100%;
      height: 50px;
      background: $white;
      border: 1px solid #DEE1E566;
      box-shadow: 0px 6px 16px #00000008;
      border-radius: 70px;
      padding: 10px;
      transition: all ease-in 0.1s;

      .globalsearch-country {
        display: flex;
        align-items: center;

        .globalsearch-country-inner {
          border-right: 1px solid #DEE1E566;

          .select-label {
            font-size: 10px;
            color: $label-color;
            padding-left: 10px;
          }

          .ant-select {
            .ant-select-arrow {
              display: none;
            }

            .ant-select-selector {
              border: 0;
              height: 28px;
              background: none;
              border: 0;
              box-shadow: none;
              outline: none;

              @media screen and (max-width: 767px) {
                font-size: 10px;
                padding: 0 8px;
              }

              .ant-select-selection-item {
                display: flex;
                align-items: center;
                color: $primary-color !important;

                .city-img {
                  width: 20px;
                  height: 20px;
                  min-width: 20px;
                  border-radius: 20px;
                  overflow: hidden;
                  margin-right: 3px;
                  display: flex;
                  align-items: center;

                  @media screen and (max-width: 767px) {
                    width: 15px;
                    height: 15px;
                    min-width: 15px;
                    border-radius: 15px;
                  }

                  svg {
                    width: 100%;
                    height: 100%;
                  }

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }

      .globalsearch-ctg {
        margin-right: 15px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 576px) {
          margin-right: 5px;
        }

        .globalsearch-ctg-inner {
          border-right: 1px solid #DEE1E566;

          .select-label {
            font-size: 10px;
            color: $label-color;
            padding-left: 10px;
          }

          .ant-select {
            .ant-select-arrow {
              display: none;
            }

            .ant-select-selector {
              border: 0;
              height: 28px;
              background: none;
              border: 0;
              box-shadow: none;
              outline: none;

              @media screen and (max-width: 767px) {
                font-size: 10px;
                padding: 0 8px;
              }

              .ant-select-selection-item {
                display: flex;
                align-items: center;
                color: $primary-color !important;

                .category-img {
                  width: 20px;
                  height: 20px;
                  min-width: 20px;
                  border-radius: 20px;
                  overflow: hidden;
                  margin-right: 3px;
                  display: flex;
                  align-items: center;

                  @media screen and (max-width: 767px) {
                    width: 15px;
                    height: 15px;
                    min-width: 15px;
                    border-radius: 15px;
                  }

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }

      .globalsearch-search {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;

        .globalsearch-search-inner {
          width: 100%;

          .select-label {
            font-size: 10px;
            color: $label-color;
            padding-left: 10px;
            @media screen and (max-width: 767px) {
              font-size: 7px;
            }
          }
        }

        .ant-select {
          width: 100%;

          .ant-select-selector {
            border: 0;
            outline: none;
            box-shadow: none;
            background: none;

            @media screen and (max-width: 767px) {
              font-size: 10px;
              padding: 0 8px;
            }

            .ant-select-selection-placeholder {
              color: $primary-color;
            }
          }
        }

        .search-icon-btn {
          width: 34px;
          height: 34px;
          min-width: 34px;
          border-radius: 34px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          border-color: $secondary-color;
          background: $secondary-color;

          @media screen and (max-width: 767px) {
            display: none;
          }

          .icon {
            fill: $white;
          }
        }

        .search-btn {
          height: 40px;
          width: 83px;
          border-radius: 40px;
          padding: 0 14px;
          font-size: 12px;
          display: flex;
          align-items: center;
          border-color: $secondary-color;
          background: $secondary-color;

          @media screen and (max-width: 767px) {
            display: none;
          }

          .icon {
            width: 14px;
            fill: $white;
          }
        }

        .filter-btn {
          background: unset;
          border-color: $border-primary;
          @media screen and (max-width: 767px) {
            position: absolute;
            right: 0;
            width: 36px;
            height: 36px;
            border-radius: 36px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-color: $border-primary;
            .icon {
              width: 13px;
            }
          }
        }

      }
    }

    .hedaer-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-weight: 500;
      padding-top: 11.5px;

      @media screen and (max-width: 767px) {
        display: none;
      }

      @media screen and (max-width: 576px) {
        font-size: 13px;
      }

      .get-listed {
        border-radius: 4px;
        box-shadow: none !important;
        outline: none;
        color: #132C4A;

        @media screen and (max-width: 567px) {
          display: none;
        }

        &:hover,
        &:focus {
          color: $white;
          background: $primary-color;
          border-color: $primary-color;
        }
      }

      .need-help {
        display: flex;
        align-items: center;
        color: $text-primary;

        .icons {
          width: 30px;
          height: 30px;
          border-radius: 10px;
          background: $purple-color;
          padding: 7px;
          margin-left: 5px;
          display: flex;
          align-items: center;
          justify-content: center;

          @media screen and (max-width: 576px) {
            width: 28px;
            height: 28px;
            border-radius: 7px;
          }

          img {
            width: 100%;
          }
        }
      }

      .logout {
        display: flex;
        align-items: center;
        margin-left: 18px;
        color: $text-primary;
        outline: none;

        .icons {
          width: 30px;
          height: 30px;
          border-radius: 10px;
          background: $primary-color;
          padding: 8px;
          margin-left: 5px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
          }
        }
      }

      .login-btn {
        border: 1px solid $border-primary;
        border-radius: 4px;
        height: 30px;
        line-height: 30px;
        padding: 0 8px;
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        box-shadow: none !important;
        outline: none;

        .icon {
          margin-left: 4px;
        }

        &:hover,
        &:focus {
          background: $primary-color;
          color: $white;
          border-color: $primary-color;

          .icon {
            fill: $white;
          }
        }
      }

      .download-link {
        display: flex;
        align-items: center;
        font-weight: normal;
        height: 30px;
        line-height: 30px;
        background: none;
        padding: 0 8px 0 15px;
        margin-right: 0.8rem;
        box-shadow: none !important;
        outline: none;
        font-size: 14px;

        @media screen and (max-width: 991px) {
          display: none;
        }

        &:hover,
        &:focus {
          color: $primary-color;
          outline: none;
          box-shadow: none;
          font-weight: 500;
        }

        .icon {
          height: 19px;
          margin-right: 4px;
          margin-top: -1px;
        }

        img {
          width: 45px;
        }
      }

      .user-right-btn {
        border: 1px solid $border-primary;
        border-radius: 4px;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        font-size: 0.875rem;

        .user-rightbtn-img {
          width: 15px;
          height: 15px;
          border-radius: 15px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 3px;

          img {
            width: 100%;
          }
        }

        &:hover,
        &:focus {
          color: $white;
          background: $primary-color;
        }
      }
    }

    &.expand-search {
      .overlay-mask {
        position: fixed;
        background: rgba($black, 0.3);
        width: 100vw;
        height: 100vh;
        left: 0;
        z-index: 0;
        top: 80px;
        @media screen and (min-width: 767px) {
          top: 156px;
        }
      }

      @media screen and (min-width: 767px) {
        .main-search {
          height: 86px;
          padding: 0 10px 0 0;
          background: #F5FCFC;
          border-color: #F5FCFC;
          max-width: 600px;
          position: relative;
          margin-top: 40px;

          .globalsearch-country {
            height: 66px;
            border-radius: 38px;

            .globalsearch-country-inner {
              border: 0;

              .select-label {
                display: block;
                position: relative;
                top: 15px;
                z-index: 2;
                left: 12px;
              }

              .ant-select {
                height: 66px;
                padding-top: 25px;
                margin-top: -12px;
                width: 120px;
                padding-left: 10px;

                &.ant-select-open {
                  background: $white;
                  border-radius: 32px;
                  box-shadow: 0px 6px 16px #00000008;
                  color: $primary-color;
                }
              }
            }

            &.selected {
              background: $white;
            }
          }

          .globalsearch-ctg {
            height: 66px;
            border-radius: 38px;

            .globalsearch-ctg-inner {
              border: 0;

              .select-label {
                display: block;
                position: relative;
                top: 15px;
                z-index: 2;
                left: 12px;
              }

              .ant-select {
                height: 66px;
                padding-top: 25px;
                margin-top: -12px;
                width: 120px;
                padding-left: 10px;

                &.ant-select-open {
                  background: $white;
                  border-radius: 32px;
                  box-shadow: 0px 6px 16px #00000008;
                  color: $primary-color;
                }
              }
            }

            &.selected {
              background: $white;
            }
          }

          .globalsearch-search {
            position: relative;

            .select-label {
              display: block;
              position: relative;
              top: 15px;
              left: 8px;
              z-index: 2;
            }

            .ant-select {
              height: 66px;
              padding-left: 8px;
              padding-right: 8px;
              padding-top: 25px;
              margin-top: -12px;

              &.ant-select-focused {
                background: $white;
                border-radius: 32px;
                box-shadow: 0px 6px 16px #00000008;
                color: $primary-color;
              }

              .ant-select-selector {
                box-shadow: none;
                outline: none;
                border: 0;
              }
            }
          }
        }
      }
    }
  }
}


.globalsearch-drop-header {
  background: none;
  min-width: 400px !important;
  box-shadow: none;
  top: 142px !important;
  box-shadow: 0 3px 36px rgba(0, 0, 0, 0.16);
  background: $white;

  @media screen and (max-width: 767px) {
    top: 80px !important;
  }

  .rc-virtual-list {
    border-radius: 10px !important;
    padding: 20px 15px;
    @media screen and (max-width: 767px) {
      padding: 10px;
    }
  }

  @media screen and (max-width: 580px) {
    min-width: 94vw !important;
    max-width: 94vw;
    left: 0 !important;
    right: 0 !important;
    margin: auto;
  }

  .ant-select-item {
    padding: 10px 15px;

    &:first-child {
      background: none;
    }

    .search-list-inner {
      display: flex;
      align-items: center;

      .ant-radio-group {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .ant-radio-button-wrapper {
          border: 1px solid $border-secondary;
          height: 30px;
          line-height: 30px;
          padding: 0 20px;
          border-radius: 30px;
          color: $primary-color;
          width: 25%;
          margin-right: 15px;
          text-align: center;

          @media screen and (max-width: 767px) {
            padding: 0 15px;
            font-size: 13px;
          }

          @media screen and (max-width: 480px) {
            padding: 0 10px;
            width: auto;
            font-size: 12px;
            min-width: 50px;
          }

          .ant-radio-button {
            font-size: 12px;
          }

          &:last-child {
            margin: 0;
          }

          &::before {
            display: none;
          }

          &.ant-radio-button-wrapper-checked {
            background: #FFF1EB;
            border-color: #FFF1EB;
          }
        }
      }

      h3 {
        font-size: 15px;
        font-weight: 500;
      }

      .search-tags-row {
        border-bottom: 1px dashed $border-primary;
        width: 100%;
        padding: 0 0 10px 0;
      }

      .search-tag-list {
        display: flex;
        align-items: center;

        .tags {
          background: #E2F8FF;
          display: flex;
          align-items: center;
          padding: 4px 10px;
          border-radius: 4px;
          margin-right: 10px;
          border: 1px solid transparent;

          &:nth-child(1) {
            background: #E2F8FF;

            &:hover,
            &.selected {
              border-color: #71C4CF;
            }
          }

          &:nth-child(2) {
            background: #FFF2FA;

            &:hover,
            &.selected {
              border-color: #FF7BCC;
            }
          }

          &:nth-child(3) {
            background: #F6EFEA;

            &:hover,
            &.selected {
              border-color: #FFB37C;
            }
          }

          .icon {
            margin-right: 4px;
          }
        }
      }

      .left-avatar {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        background: $white;
        border: 1px solid $border-secondary;

        img {
          height: 100%;
          object-fit: cover;
        }

        svg {
          height: 16px;
          fill: $primary-color;
        }
      }

      .search-list-right {
        line-height: 1.4;

        p {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 500;
          color: $primary-color;
        }

        label {
          font-size: 13px;
          color: $label-color;

          .icon {
            width: 8px;
            fill: $label-color;
          }
        }
      }
    }
  }
}

.expand-search {
  & ~ main {
    overflow: hidden;
  }

}