@import "../../../assets/scss/variables.scss";


.topbrand-logo-section {
  padding: 8rem 0 6rem 0;
  label {
    font-size: 13px;
    letter-spacing: 1px;
  }
  .heading {
    font-size: 3.125rem;
    font-weight: 600;
    margin: 0 auto 2rem auto;
  }

  .topbrand-grid {
    overflow: visible;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(6, 1fr);
    }

    @media screen and (min-width: 1124px) {
      grid-template-columns: repeat(8, 1fr);
    }

    img {
      border-radius: 50%;
      filter: grayscale(1);
      object-fit: cover;
      transition: all ease-in 0.2s;

      &:hover {
        filter: grayscale(0);
      }
    }
  }

  img {
    width: 100%;
  }
}