@import '../../assets/scss/variables.scss';

.rsa-wrapper {
  .rsa-banner {
    padding: 8rem 0;

    .rsa-logo {
      margin-left: 20px;
      margin-bottom: 30px;
      width: 120px;
    }

    .row {
      align-items: center;
    }

    .banner-left {
      h1 {
        font-size: 3.5rem;
        margin-bottom: 15px;
        @media screen and (max-width: 1100px) {
          font-size: 2.5rem;
        }
      }

      p {
        font-size: 1.3rem;
        max-width: 460px;
        margin-bottom: 2.5rem;
      }

      .download-btns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.2rem;
        max-width: 330px;
        margin-top: 3rem;
        @media screen and (max-width: 640px) {
          grid-gap: 0.8rem 0.6rem;
        }

        .store-icon {
          height: 50px;
          object-fit: fill;
          width: 160px;
          transition: all ease-in 0.1s;

          &:hover {
            transform: translateY(-4px);
          }
        }
      }
    }

    .banner-right {
      position: relative;

      @media screen and (max-width: 767px) {
        margin-top: 2.5rem;
      }

      .banner-img {
        width: 100%;
        max-height: 555px;
        object-fit: cover;
      }
    }
  }

}