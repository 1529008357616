@import "../../../assets/scss/variables.scss";

.thankyou-modal {
    max-width: calc(100vw - 46px);
    @media screen and (max-width:991px) {
        max-width: calc(100vw - 15px);
    }
    .ant-modal-close{
        right: 25px;
        top: 25px;
        .ant-modal-close-x{
            width: 30px;
            height: 30px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $body-background-light;
            .icon{
                fill: $primary-color;
                width: 10px;
            }
            &:hover{
                background: $secondary-color;
                .icon{
                    fill: $white;
                }
            }
        }
    }
    .ant-modal-body{
        padding: 2rem 1.5rem;
    }

    .ant-modal-content{
        border-radius: 0;
        text-align: center;
        padding: 0;
        h1 {
            font-size: 2.5rem;
            margin-bottom: 1rem;
            @media screen and (max-width:767px){
                font-size: 2rem;
            }
        }
        p {
            font-size: 1.2rem;
            color: #132C4A;
            line-height: 1.3;
        }
    }

}