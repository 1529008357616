@import "../../../assets/scss/variables.scss";

ul.membership-plan-list {
  padding-left: 0;

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid $border-primary;
    padding: 1.3rem 0;

    .badge {
      background: $secondary-color;
      border-radius: 0 20px 20px 20px;
      font-size: 11px;
      padding: 2px 6px;
      color: $white;
      margin-left: 10px;
      font-weight: 600;

      @media screen and (max-width: 400px) {
        font-size: 10px;
        border-radius: 0 15px 15px 15px;
        padding: 2px 4px;
        margin-left: 5px;
      }

      &.active {
        background: $success-color;
        border-radius: 20px;
      }
    }

    &:last-child {
      border-bottom: 0;
    }

    .list-left {

      .title-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }

      h3 {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 0;
        @media screen and (max-width: 400px) {
          font-size: 1.1rem;
        }

        span {
          color: $text-secondary;
          font-size: 1rem;
          text-decoration: line-through;
          @media screen and (max-width: 400px) {
            font-size: 0.75rem;
          }
        }
      }

      p {
        margin-bottom: 0;
        font-size: 0.875rem;
      }
    }

    .right-check {
      margin-left: auto;
      border: 1px solid $border-primary;
      width: 25px;
      height: 25px;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        width: 12px;
      }
    }

  }
}