@import '../../assets/scss/variables.scss';

.tp-checkout-wrapper{
    background: $body-background-light;
    min-height: 100vh;
    padding-top: 60px;
    .twl-row{
        display: flex;
        justify-content: space-between;
        margin-top: 1.5rem;
        @media screen and (max-width:991px) {
            flex-direction: column;
        }
        .twl-card{
            background: $white;
            .twl-header{
                border-bottom: 1px dashed $border-primary;
                padding: 1rem 1.5rem;
                font-size: 1.125rem;
                font-weight: 600;
            }
        }
        .checkout-left{
            width: 100%;
            .twlcontent-inner{
                max-width: 610px;
                margin: auto;
                padding: 2rem 0;
                width: 98%;
                .inner-title{
                    font-weight: 600;
                    font-size: 1.1rem;
                }
                .checkout-upper-detl{
                    max-width: 300px;
                    width: 100%;
                    margin: auto auto 30px auto;
                    text-align: center;
                    h3{
                        font-size: 20px;
                        margin-bottom: 8px;
                        font-weight: 600;
                    }
                    p{
                        font-size: 16px;
                        color: $text-secondary;
                    }
                    .otp-input-container{
                        width: 100%;
                        max-width: 300px;
                        justify-content: space-between;
                        margin-top: 15px;
                        margin-bottom: 10px;
                        .otp-input{
                            input {
                                width: 60px !important;
                                height: 60px;
                                background: $body-background-light;
                                border-radius: 10px;
                                border: 0;
                                outline: none;
                                font-size: 20px;
                                font-weight: 600 !important;
                            }
                        }
                    }
                    .bill-amount{
                        display: flex;
                        max-width: 254px;
                        background: $body-background-light;
                        border-radius: 10px;
                        position: relative;
                        height: 60px;
                        margin: auto;
                        .currency-icon{
                            color: black;
                            background: $white;
                            width: 48px;
                            border-radius: 10px 0 0 10px;
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 100%;
                            z-index: 1;
                            box-shadow: 0px 13px 16px #00000010;
                            font-size: 16px;
                            text-transform: uppercase;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-weight: 600;
                        }
                        .ant-input {
                            text-align: center;
                            padding-right: 48px;
                            font-size: 20px;
                            font-weight: 600;
                            border: 0;
                            background: $body-background-light;
                            border-radius: 10px;
                            margin-left: 80px;
                            outline: none;
                            box-shadow: none;
                            &::placeholder {
                                font-weight: 600;
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
            .payment-tabs{
                margin-top: 0.8rem;
                .ant-tabs-nav{
                    &::before{
                        display: none;
                    }
                    .ant-tabs-nav-wrap{
                        width: 100%;
                        .ant-tabs-ink-bar{
                            display: none;
                        }
                        .ant-tabs-nav-list{
                            width: 99%;
                            justify-content: space-between; 
                            .ant-tabs-tab{
                                width: 100%;
                                @media screen and (max-width:676px) {
                                    margin:  0 0 0 5px;
                                }
                                .ant-tabs-tab-btn{
                                    width: 100%;
                                    .options-card{
                                        text-align: center;
                                        @media screen and (max-width:676px) {
                                            height: 70px;
                                            max-width: 80px;
                                        }
                                        @media screen and (max-width:576px) {
                                            max-width: 60px;
                                            height: 60px;
                                        }
                                        p{
                                            font-size: 0.875rem;
                                            margin-top: 1.35rem;
                                            color: $primary-color;
                                        }
                                        .optionscard-inner{
                                            border: 1px solid $border-primary;
                                            border-radius: 7px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            height: 110px;
                                            @media screen and (max-width:676px) {
                                                height: 70px;
                                                height: 60px;
                                            }
                                            .icon{
                                                width: 100%;
                                                height: 20px;
                                                @media screen and (max-width:576px) {
                                                    height: 15px;
                                                }
                                            }
                                        }
                                    }
                                }
                                &:hover{
                                    .ant-tabs-tab-btn{
                                        .options-card{
                                            p{
                                                font-weight: 600;
                                                color: $primary-color !important;
                                            }
                                            .optionscard-inner{
                                                border: 1px solid $primary-color;
                                            }
                                        }
                                    }
                                }
                                &.ant-tabs-tab-active{
                                    .ant-tabs-tab-btn{
                                        .options-card{
                                            p{
                                                font-weight: 600;
                                                color: $primary-color !important;
                                            }
                                            .optionscard-inner{
                                                border: 1px solid $primary-color;
                                                position: relative;
                                                &::after{
                                                    content: " ";
                                                    width: 26px;
                                                    height: 26px;
                                                    border-radius: 20px 0 6px 0;
                                                    background-color: #6FC3CE;
                                                    position: absolute;
                                                    bottom: 0;
                                                    right: 0;
                                                    background-repeat: no-repeat;
                                                    background-position: 8px 9px;
                                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.666' height='9.758' viewBox='0 0 12.666 9.758'%3E%3Cpath id='Path_303889' data-name='Path 303889' d='M193.274 179.882l-4.154-4.152 1.248-1.248 2.906 2.906 7.267-7.265 1.246 1.246Z' transform='translate(-189.12 -170.124)' fill='%23fff'/%3E%3C/svg%3E");
                                                    @media screen and (max-width:676px) {
                                                        width: 22px;
                                                        height: 22px;
                                                        background-size: 11px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .checkout-right{
            width: 100%;
            max-width: 372px;
            margin-left: 1.8rem;
            @media screen and (max-width:1100px) {
                max-width: 320px;
                margin-left: 1rem;
            }
            @media screen and (max-width:991px) {
                max-width: 100%;
                margin-left: 0;
                margin-top: 1.5rem;
            }
            .twlcontent-inner{
                padding: 0.5rem 1.8rem 3rem 1.8rem;
                @media screen and (max-width:400px) {
                    padding: 0.5rem 1.2rem 3rem 1.2rem;
                }
                ul.summary-list{
                    padding-left: 0;

                    li {
                        display: flex;
                        align-items: center;
                        border-bottom: 1px dashed $border-primary;
                        padding: 1rem 0;

                        &:first-child {
                            padding-top: 0;
                        }

                        .image-col{
                            width: 90px;
                            height: 60px;
                            border-radius: 7px;
                            overflow: hidden;
                            margin-right: 12px;

                            img {
                                width: 100%;
                                min-height: 100%;
                                object-fit: cover;
                            }

                        }

                        .list-left {
                            h3 {
                                font-size: 1.25rem;
                                font-weight: 600;
                                margin-bottom: 4px;
                                @media screen and (max-width:400px) {
                                    font-size: 1rem;
                                }
                            }

                            p {
                                margin-bottom: 0;
                                font-size: 0.875rem;
                            }
                        }

                    }
                }
                .payment-details{
                    margin-bottom: 1.5rem;
                    .discount-row{
                        font-size: 0.875rem;
                        margin-bottom: 0.8rem;
                        .text-right{
                            font-weight: 600;
                        }
                    }
                    .willpay-row{
                        font-size: 1rem;
                        color: $secondary-color;
                        font-weight: 600;
                        .text-right{
                            font-size: 1.25rem;
                            font-weight: bold;
                        }
                    }
                }
                .pay-btn{
                    .icon {
                        fill: $white;
                        position: absolute;
                        right: 20px;
                        width: 24px;
                        margin-top: -12px;
                    }
                }
                .learn-btn{
                    font-weight: normal;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: auto;
                    margin-top: 10px;
                    span{
                        text-decoration: underline;
                        color: $primary-color;
                    }
                    .icon{
                        margin-right: 4px;
                        fill: $secondary-color;
                    }
                    &:hover {
                        span {
                            color: $secondary-color;
                        }
                    }
                }
                .getit-touch{
                    border: 1px dashed $border-primary;
                    padding: 1.6rem 1.4rem;
                    border-radius: 10px;
                    font-size: 0.875rem;
                    margin-top: 1.5rem;
                    p{
                        margin-bottom: 0;
                        max-width: 250px;
                        color: #132C4A;
                    }
                    a{
                        color: $secondary-color;
                    }
                    .upper-row{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 0.5rem;
                        h3{
                            font-weight: 600;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
    }
}
