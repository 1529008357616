@import "../../../assets/scss/variables.scss";

.cancel-sub {
    span {
        color: $primary-color;
        text-decoration: underline;
        text-align: center;
    }
}
.ant-btn-primary {
    @media screen and (max-width: 767px) {
        width: initial;
    }
}
.ant-btn.cancel-text {
    display: block;
    text-align: center;
    margin: 0 auto;
    font-size: 20px;
    letter-spacing: 0.4px;
    color: $primary-color !important;
    span {
        font-size: 16px;
        text-decoration: underline;
    }
}