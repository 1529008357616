@import '../../../assets/scss/variables.scss';

.footer {
  margin-top: auto;
  background: $footer-bg;

  &.small-footer {
    border-top: 1px solid rgba($border-primary, 0.3);
    position: fixed;
    bottom: 0;
    width: 100vw;
    z-index: 9;

    .prive-mode & {
      background: $primary-color;
      color: $white;
    }
  }

  .bottom-footer {
    text-align: center;
    padding: 1rem 0;
    border: 0;

    p {
      font-size: 0.75rem;
      color: $primary-color;
      margin: 0;

      .prive-mode & {
        color: $white;
      }
    }
  }
}