@import '../../assets/scss/variables.scss';

.twl-knowmore-wrapper {
    .savingstep-section {
        padding: 5rem 0;

        .heading {
            font-size: 3.125rem;
            font-weight: 600;
            margin: 0 auto 3.8rem auto;
        }

        .savingstep-section-bottom {
            background: transparent linear-gradient(243deg, #d8f4f1 0%, #f5e9e1 89%, #ffe5db 100%) 0% 0% no-repeat padding-box;
            padding: 6rem 0;

            @media screen and (max-width: 600px) {
                padding: 6rem 0 10rem 0;
            }

            .left-section {
                list-style-type: none;
                padding-left: 0;
                margin: 0;

                li {
                    margin-top: 2.5rem;

                    .list-inner {
                        display: flex;

                        .circle {
                            width: 42px;
                            height: 42px;
                            border-radius: 42px;
                            background: $white;
                            font-size: 30px;
                            font-weight: 600;
                            display: flex;
                            align-items: center;
                            text-indent: 20px;
                        }

                        .ant-divider {
                            width: 56px;
                            min-width: 56px;
                            margin: 21px 20px 0 20px;
                            border-color: $primary-color;
                            @media screen and (max-width: 767px) {
                                width: 30px;
                                min-width: 30px;
                            }
                        }

                        .text-text {
                            h3 {
                                font-size: 30px;
                                margin-bottom: 10px;
                                font-weight: 600;
                            }

                            p {
                                font-size: 1rem;
                                max-width: 320px;
                                line-height: 1.8;
                            }
                        }
                    }
                }
            }

            .right-section {
                position: relative;

                @media screen and (max-width: 600px) {
                    margin-top: 4rem;
                }

                .play-icon {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 3;
                    text-align: right;
                    margin-left: 20px;

                    @media screen and (max-width: 600px) {
                        top: 110%;
                        transform: translateY(0);
                    }

                    .watch-row {
                        display: flex;
                        align-items: center;
                        padding: 8px;
                        border-radius: 4px;
                        transition: all ease-in 0.2s;

                        .icon {
                            width: 25px;
                            fill: $secondary-color;
                            margin-left: 10px;
                        }

                        &:hover, &:focus {
                            background: rgba(0, 0, 0, 0.1);
                        }
                    }

                    .watch-arrow {
                        width: 48px;
                        margin-top: 8px;
                    }
                }

                .journey-img {
                    position: absolute;
                    max-width: 380px;
                    z-index: 2;
                    left: 0;
                    right: 0;
                    margin: auto;
                    top: 50%;
                    transform: translateY(-50%);

                    img {
                        width: 100%;
                    }
                }

                .circles-div {
                    width: 490px;
                    height: 490px;
                    border-radius: 490px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: auto;

                    @media screen and (max-width: 520px) {
                        width: 380px;
                        height: 380px;
                        border-radius: 380px;
                    }

                    @media screen and (max-width: 476px) {
                        width: 350px;
                        height: 350px;
                        border-radius: 350px;
                    }

                    .circle1 {
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                        background: rgb(255, 255, 255);
                        opacity: 0.13;
                        display: block;
                        position: absolute;
                    }

                    .circle2 {
                        width: 85%;
                        height: 85%;
                        border-radius: 100%;
                        display: block;
                        background: rgb(255, 255, 255);
                        opacity: 0.21;
                        position: absolute;
                    }

                    .circle3 {
                        width: 70%;
                        height: 70%;
                        border-radius: 100%;
                        display: block;
                        background: rgb(255, 255, 255);
                        opacity: 0.54;
                        position: absolute;
                    }

                    .circle4 {
                        width: 40%;
                        height: 40%;
                        border-radius: 100%;
                        display: block;
                        background: rgb(255, 255, 255);
                        opacity: 0.2;
                        position: absolute;
                    }

                    .circle5 {
                        width: 20%;
                        height: 20%;
                        border-radius: 100%;
                        display: block;
                        background: rgb(255, 255, 255);
                        opacity: 0.47;
                        position: absolute;
                    }
                }
            }
        }
    }
    .discover-section {
        padding: 5rem 0 0 0;
        margin-bottom: -15rem;
        position: relative;
        z-index: 1;

        @media screen and (max-width: 767px) {
            margin-bottom: -2rem;
        }

        .discover-card {
            display: flex;
            background: $primary-color;
            color: $white;

            @media screen and (max-width: 767px) {
                display: block;
            }

            .discover-card-left {
                width: 50%;
                overflow: hidden;
                background-size: cover;
                display: flex;
                align-items: center;
                justify-content: center;

                @media screen and (max-width: 767px) {
                    width: 100%;
                    padding: 4rem 1rem;
                }

                .icon {
                    width: 128px;
                    fill: $white;
                }
            }

            .discover-card-right {
                width: 50%;
                padding: 6rem 4.5rem 8rem 4.5rem;

                @media screen and (max-width: 767px) {
                    width: 100%;
                    padding: 3rem 2rem;
                }

                label {
                    color: $label-color;
                }

                h2 {
                    color: $white;
                    font-size: 40px;
                    font-weight: 600;
                    margin-top: 2.8rem;
                    margin-bottom: 1rem;

                    @media screen and (max-width: 767px) {
                        font-size: 28px;
                    }
                }

                ul {
                    list-style-type: none;
                    padding-left: 0;
                    margin: 0;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);

                    li {
                        width: 50%;
                        display: inline-block;
                        padding-top: 2.4rem;

                        .ds-icon {
                            width: 50px;
                            height: 50px;
                            border-radius: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid rgba($white, 0.31);
                            margin-bottom: 10px;

                            .icon {
                                width: 18px;
                                height: 18px;
                                fill: $white;
                            }
                        }

                        p {
                            color: rgba($white, 0.7);
                        }
                    }
                }
            }
        }
    }
    .download-section {
        background: #fff1eb;
        padding: 25rem 0 8rem 0;
        position: relative;
        overflow: hidden;

        @media screen and (max-width: 767px) {
            padding: 4rem 0 2rem 0;
        }

        .download-line {
            position: absolute;
            right: 0;
            top: 0;
            height: 103%;
        }

        .row {
            justify-content: space-between;
            align-items: center;
            min-height: 55vh;

            .download-left {
                max-width: 460px;
                position: relative;
                z-index: 2;

                h1 {
                    font-size: 3.125rem;
                }

                p {
                    font-size: 1.25rem;
                }

                .download-btns {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-gap: 1.5rem;
                    max-width: 330px;
                    margin-top: 4rem;

                    @media screen and (max-width: 767px) {
                        grid-gap: 0.5rem;
                    }

                    .ant-btn {
                        border: 1px solid #718092;
                        height: 50px;
                        line-height: 50px;
                        padding: 0 15px;
                        color: $white;
                        font-size: 1rem;
                        display: flex;
                        align-items: center;
                        border-radius: 9px;
                        color: $primary-color;
                        transition: all ease-in 0.1s;
                        background: none;
                        font-weight: normal;

                        @media screen and (max-width: 767px) {
                            font-size: 0.8rem;
                            padding: 0 8px 0 12px;
                            height: 40px;
                            line-height: 40px;
                        }

                        .icon {
                            fill: $primary-color;
                            height: 25px;
                            width: 25px;
                            margin-right: 10px;
                            transition: all ease-in 0.1s;

                            @media screen and (max-width: 767px) {
                                width: 16px;
                                height: 16px;
                                margin-right: 6px;
                            }
                        }

                        &:hover {
                            background: $primary-color;
                            border-color: $primary-color;
                            color: $white;
                            transform: translateY(-4px);

                            .icon {
                                fill: $white;
                            }
                        }
                    }
                }
            }

            .download-right {
                .right-inner {
                    position: relative;
                    max-width: 460px;
                    width: 100%;
                    margin-left: auto;
                    text-align: center;

                    @media screen and (max-width: 991px) {
                        margin: auto;
                        padding-top: 2.5rem;
                    }

                    .download-circle {
                        width: 500px;
                        height: 500px;
                        border-radius: 500px;
                        display: inline-block;
                        background: transparent linear-gradient(180deg, #ff7541 0%, #ffffff41 100%);
                        z-index: 0;
                        position: relative;

                        @media screen and (max-width: 991px) {
                            left: 0;
                            right: 0;
                            margin: auto;
                        }

                        @media screen and (max-width: 767px) {
                            width: 400px;
                            height: 400px;
                        }

                        @media screen and (max-width: 640px) {
                            width: 330px;
                            height: 330px;
                        }
                    }

                    .download-img-div {
                        z-index: 2;
                        position: absolute;
                        right: -40%;
                        margin: auto;
                        z-index: 3;

                        @media screen and (max-width: 991px) {
                            width: 100%;
                            right: auto;
                            top: 10% !important;
                        }

                        &.web {
                            top: -18%;

                            @media screen and (max-width: 991px) {
                                display: none;
                            }
                        }

                        &.mobile {
                            display: none;
                            top: 0;

                            @media screen and (max-width: 991px) {
                                display: block;
                            }
                        }
                    }

                    .download-img {
                        max-height: 530px;

                        @media screen and (max-width: 1100px) {
                            max-height: 450px;
                        }

                        @media screen and (max-width: 991px) {
                            right: 0;
                            max-width: 100%;
                            height: auto;
                            max-height: auto;
                        }
                    }
                }
            }
        }
    }
    .checkout-section {
        background: $secondary-color;
        color: $white;
        text-align: center;
        padding: 8rem 0;

        h1 {
            color: $white;
            font-size: 2.5rem;
            font-weight: normal;
            margin-bottom: 2.5rem;
            line-height: 1.35;
            @media screen and (max-width: 767px) {
                font-size: 2rem;
            }
        }

        .ant-btn {
            height: 50px;
            line-height: 50px;
            border: 1px solid $white;
            color: $white;
            background: none;
            padding: 0 45px;
            border-radius: 6px;

            &:hover {
                background: $white;
                color: $secondary-color;
            }
        }
    }

    .topbrand-section {
        padding: 3rem 0 8rem 0;

        .heading {
            font-size: 3.125rem;
            font-weight: 600;
            margin: 0 auto 2rem auto;
        }

        .topbrand-grid {
            overflow: visible;
            display: grid;
            grid-gap: 16px;
            grid-template-columns: repeat(4, 1fr);

            @media screen and (min-width: 768px) {
                grid-template-columns: repeat(6, 1fr);
            }

            @media screen and (min-width: 1124px) {
                grid-template-columns: repeat(8, 1fr);
            }

            img {
                border-radius: 50%;
                filter: grayscale(1);
                object-fit: cover;
                transition: all ease-in 0.2s;

                &:hover {
                    filter: grayscale(0);
                }
            }
        }

        img {
            width: 100%;
        }
    }
    .letsjoin-section {
        background: url('../../assets/images/lets-join.jpg');
        background-size: cover;
        padding: 12rem 0;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-position: center center;

        @media screen and (max-width: 991px) {
            background-size: 200%;
        }

        @media screen and (max-width: 767px) {
            padding: 4rem 0;
            background-attachment: inherit;
        }

        @media screen and (max-width: 576px) {
            padding: 2rem 0;
        }

        .letsjoin-row {
            .letsjoin-card {
                background: $secondary-color;
                color: $white;
                max-width: 375px;
                border-radius: 10px;
                padding: 2.5rem 90px 2.5rem 2.5rem;
                margin-left: auto;

                @media screen and (max-width: 767px) {
                    padding: 1.5rem;
                }

                @media screen and (max-width: 576px) {
                    max-width: 300px;
                }

                .upto-text {
                    line-height: 1;
                    margin-top: 0.5rem;
                    text-transform: uppercase;
                }

                h3 {
                    font-size: 1.875rem;
                    color: $white;
                    font-weight: 600;
                    margin-bottom: 2rem;

                    @media screen and (max-width: 576px) {
                        font-size: 1.5rem;
                    }
                }

                h2 {
                    font-size: 3.125rem;
                    color: $white;
                    font-weight: 600;
                    margin-bottom: 0;
                    margin-top: 0;

                    @media screen and (max-width: 576px) {
                        font-size: 2.5rem;
                    }
                }

                p {
                    font-size: 1.25rem;
                    color: $white;
                    margin-bottom: 0;

                    @media screen and (max-width: 576px) {
                        font-size: 1.0rem;
                    }
                }

                .icon {
                    width: 29px;
                    fill: $white;
                }

                .ant-btn {
                    border: 1px solid $white;
                    border-radius: 40px;
                    height: 40px;
                    line-height: 38px;
                    padding: 0 20px;
                    font-size: 14px;
                    color: $white;
                    background: none;
                    margin-top: 1.5rem;
                }
            }
        }
    }
    .testomonial-section {
        padding: 8rem 0;

        @media screen and (max-width: 767px) {
            padding: 4rem 0;
        }

        .heading {
            font-size: 3.125rem;
            max-width: 425px;
            margin-bottom: 3.5rem;
        }

        .heading-p-tag {
            font-size: 1rem;
        }

        .testomonial-row {
            display: flex;
            align-items: center;
            max-width: 1100px;
            padding: 4rem 0 5rem 0;

            @media screen and (max-width: 767px) {
                display: block;
                padding: 0 0 2rem 0;
            }

            .testomonial-image {
                max-width: 360px;
                min-width: 360px;
                border-radius: 17px;
                width: 100%;

                @media screen and (max-width: 767px) {
                    min-width: 100%;
                }
            }

            .right-text {
                padding-left: 3.5rem;

                @media screen and (max-width: 767px) {
                    padding-left: 0;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }

                p {
                    font-size: 1.875rem;
                    font-style: italic;
                    margin-bottom: 2rem;

                    @media screen and (max-width: 767px) {
                        font-size: 1.2rem;
                    }
                }

                h3 {
                    font-size: 3.4375rem;
                    font-family: 'Ephesis', cursive;
                    margin-bottom: 0.8rem;
                    font-weight: normal;

                    @media screen and (max-width: 767px) {
                        font-size: 3rem;
                        margin-top: 1rem;
                    }
                }

                hr {
                    width: 104px;
                    margin-left: 0;
                }
            }
        }

        .testomonial-slider {
            .slick-arrow {
                color: $primary-color;
                font-size: 1.25rem;
                z-index: 2;
                position: absolute;
                opacity: 1;
                border: 1px solid $border-secondary;
                width: 40px;
                height: 40px;
                border-radius: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    fill: $primary-color;
                    width: 6px;
                }

                &::before {
                    display: none;
                }

                &.slick-next {
                    bottom: 0;
                    top: auto;
                    right: 0;
                }

                &.slick-prev {
                    bottom: 0;
                    top: auto;
                    right: 50px;
                    left: auto;
                }

                &.slick-disabled {
                    opacity: 0.5;
                    cursor: inherit;
                }
            }
        }

        .bottom-arrow {
            position: absolute;
            right: 120px;
            width: 50px;
            margin-top: 5px;
            fill: #718092;
            bottom: 0;
            animation: iconcolor-change1 1s infinite;
        }
    }

}

@keyframes textcolor-change1 {
    0% {
        color: $primary-color;
    }

    50% {
        color: $secondary-color;
    }

    100% {
        color: $primary-color;
    }
}

@keyframes iconcolor-change1 {
    0% {
        fill: $secondary-color;
    }

    50% {
        fill: $primary-color;
    }

    100% {
        fill: $secondary-color;
    }
}

@keyframes MoveUpDown {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(15px);
    }
}


@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba($white, 0.3),
            0 0 0 0.55em rgba($white, 0.3),
            0 0 0 1.1em rgba($white, 0.3),
            0 0 0 1.6em rgba($white, 0.3);
    }

    100% {
        box-shadow: 0 0 0 0.5em rgba($white, 0.3),
            0 0 0 1em rgba($white, 0.3),
            0 0 0 1.5em rgba($white, 0.3),
            0 0 0 2em rgba($white, 0);
    }
}


.globalsearch-drop {
    border-radius: 10px !important;
    box-shadow: 0 3px 36px rgba(0, 0, 0, 0.16);
    min-width: 400px !important;

    @media screen and (max-width: 580px) {
        min-width: 94vw !important;
        max-width: 94vw;
        left: 0 !important;
        right: 0 !important;
        margin: auto;
    }

    .ant-select-item {
        padding: 10px 15px;

        &:first-child {
            background: none;
        }

        .search-list-inner {
            display: flex;
            align-items: center;

            .ant-radio-group {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .ant-radio-button-wrapper {
                    border: 1px solid $border-secondary;
                    height: 30px;
                    line-height: 30px;
                    padding: 0 20px;
                    border-radius: 30px;
                    color: $primary-color;
                    width: 25%;
                    margin-right: 15px;
                    text-align: center;

                    @media screen and (max-width:480px) {
                        padding: 0 15px;
                        width: auto;
                    }

                    .ant-radio-button {
                        font-size: 12px;
                    }

                    &:last-child {
                        margin: 0;
                    }

                    &::before {
                        display: none;
                    }

                    &.ant-radio-button-wrapper-checked {
                        background: #FFF1EB;
                        border-color: #FFF1EB;
                    }
                }
            }

            h3 {
                font-size: 15px;
                font-weight: 500;
            }

            .search-tags-row {
                border-bottom: 1px dashed $border-primary;
                width: 100%;
                padding: 0 0 10px 0;
            }

            .search-tag-list {
                display: flex;
                align-items: center;

                .tags {
                    background: #E2F8FF;
                    display: flex;
                    align-items: center;
                    padding: 4px 10px;
                    border-radius: 4px;
                    margin-right: 10px;
                    border: 1px solid transparent;

                    &:nth-child(1) {
                        background: #E2F8FF;

                        &:hover,
                        &.selected {
                            border-color: #71C4CF;
                        }
                    }

                    &:nth-child(2) {
                        background: #FFF2FA;

                        &:hover,
                        &.selected {
                            border-color: #FF7BCC;
                        }
                    }

                    &:nth-child(3) {
                        background: #F6EFEA;

                        &:hover,
                        &.selected {
                            border-color: #FFB37C;
                        }
                    }

                    .icon {
                        margin-right: 4px;
                    }
                }
            }

            .left-avatar {
                width: 40px;
                height: 40px;
                border-radius: 40px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 8px;
                background: $white;
                border: 1px solid $border-secondary;

                img {
                    height: 100%;
                    object-fit: cover;
                }

                svg {
                    height: 16px;
                    fill: $primary-color;
                }
            }

            .search-list-right {
                line-height: 1.4;

                p {
                    margin-bottom: 0;
                    font-size: 14px;
                    font-weight: 500;
                    color: $primary-color;
                }

                label {
                    font-size: 13px;
                    color: $label-color;

                    .icon {
                        width: 8px;
                        fill: $label-color;
                    }
                }
            }
        }
    }
}
