@import "../../../assets/scss/variables.scss";


.landing-main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 30px;
  z-index: 3;
  position: absolute;
  top: 0;

  --text-color: #{$white};

  &.dark-text {
    --text-color: #{$primary-color};
  }

  @media screen and (max-width: 991px) {
    padding: 15px 0;
  }

  .hader-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo-left {
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;
    }

    .logo-icon {
      width: 20px;
      margin-right: 10px;
    }

    .logo-name {
      width: 135px;
      fill: var(--text-color);

      .prive-mode & {
        fill: $white;
      }

      .prive-checkout & {
        fill: $primary-color !important;
      }
    }
  }

  .center-menu {
    width: 100%;
    text-align: center;
    @media screen and (max-width: 991px) {
      max-width: 50px;
      margin-left: auto;
      margin-right: 15px;
    }

    .ant-menu {
      margin: auto;
      width: 100%;
      display: flex;
      justify-content: center;
      border: 0;
      background: none;

      .ant-menu-overflow-item {
        .icon {
          fill: var(--text-color);
        }
      }

      .ant-menu-item {
        color: var(--text-color);
        font-size: 16px;
        padding: 0 15px;

        a {
          color: inherit;
          position: relative;

          &::after {
            height: 1px;
            display: block;
            width: 100%;
            position: absolute;
            bottom: -8px;
            background: var(--text-color);
            content: ' ';
            opacity: 0;
          }

          &.active {
            color: var(--text-color);
            font-weight: 500;

            &::after {
              opacity: 1;
            }
          }
        }

        &::after {
          width: 30px;
          border-width: 1px;
          display: none;
        }

        &:hover {
          color: $secondary-color;
        }
      }
    }
  }

  .hedaer-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 500;
    @media screen and (max-width: 576px) {
      font-size: 13px;
    }

    .get-listed {
      border-radius: 4px;
      box-shadow: none !important;
      outline: none;
      color: var(--text-color);
      background: none;
      margin-right: 15px;
      @media screen and (max-width: 567px) {
        display: none;
      }

      &:hover, &:focus {
        color: $white;
        background: $primary-color;
        border-color: $primary-color;
      }
    }

    .need-help {
      display: flex;
      align-items: center;
      color: $text-primary;

      .icons {
        width: 30px;
        height: 30px;
        border-radius: 10px;
        background: $purple-color;
        padding: 7px;
        margin-left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 576px) {
          width: 28px;
          height: 28px;
          border-radius: 7px;
        }

        img {
          width: 100%;
        }
      }
    }

    .logout {
      display: flex;
      align-items: center;
      margin-left: 18px;
      color: $text-primary;
      outline: none;

      .icons {
        width: 30px;
        height: 30px;
        border-radius: 10px;
        background: $primary-color;
        padding: 8px;
        margin-left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
        }
      }
    }

    .login-btn {
      border: 1px solid var(--text-color);
      border-radius: 4px;
      height: 30px;
      line-height: 30px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      box-shadow: none !important;
      outline: none;
      background: none;
      color: var(--text-color);

      .icon {
        margin-left: 6px;
        fill: var(--text-color);

        .prive-mode & {
          fill: $white;
        }
      }

      &:hover, &:focus {
        background: $primary-color;
        color: $white;
        border-color: $primary-color;

        .prive-mode & {
          background: $prive-color;
          border-color: $prive-color;
        }

        .icon {
          fill: $white;
        }
      }
    }

    .download-link {
      display: flex;
      align-items: center;
      font-weight: normal;
      height: 30px;
      line-height: 30px;
      background: none;
      padding: 0 10px;
      margin-right: 0.8rem;
      box-shadow: none !important;
      outline: none;
      font-size: 14px;
      color: var(--text-color);
      border-radius: 4px;
      @media screen and (max-width: 991px) {
        display: none;
      }

      .icon {
        height: 19px;
        margin-right: 4px;
        margin-top: -1px;
        fill: var(--text-color)
      }

      img {
        width: 45px;
      }

      &:hover, &:focus {
        color: $white;
        background: $primary-color;
        border-color: $primary-color;

        .icon {
          fill: $white;
        }
      }
    }

    .user-right-btn {
      border: 1px solid $border-primary;
      border-radius: 4px;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      font-size: 0.875rem;

      .user-rightbtn-img {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 3px;

        img {
          width: 100%;
        }
      }

      &:hover, &:focus {
        color: $white;
        background: $primary-color;
      }
    }
  }
}

.tpdownload-drop {
  background: none;

  .ant-dropdown-menu {
    box-shadow: 0px 3px 46px #00000029;
    background: $white;
    padding: 4.5rem 3.5rem 4.5rem 3.5rem;
    border-radius: 8px;
    width: 750px;
  }

  .download-left {

    h1 {
      font-size: 2.5rem;

      @media screen and (max-width: 767px) {
        font-size: 2rem;
      }
    }

    p {
      font-size: 1.1rem;
      color: #132C4A;
      line-height: 1.3;
      max-width: 92.5%;
    }

    .download-btns {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 0.5rem;
      margin-top: 3rem;

      @media screen and (max-width: 640px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 640px) {
        grid-gap: 0.8rem 0.6rem;
      }

      .ant-btn {
        border: 1px solid $border-primary;
        height: 46px;
        line-height: 46px;
        padding: 0 12px;
        color: $white;
        font-size: 0.95rem;
        display: flex;
        align-items: center;
        border-radius: 9px;
        color: $primary-color;
        transition: all ease-in 0.1s;
        font-weight: normal;

        @media screen and (max-width: 640px) {
          font-size: 0.85rem;
          padding: 0 8px 0 10px;
        }

        @media screen and (max-width: 400px) {
          font-size: 0.75rem;
          height: 40px;
          line-height: 40px;
        }

        .icon {
          fill: $primary-color;
          height: 20px;
          width: 20px;
          margin-right: 10px;
          transition: all ease-in 0.1s;

          @media screen and (max-width: 640px) {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }

        &:hover {
          background: $primary-color;
          border-color: $primary-color;
          color: $white;
          transform: translateY(-4px);

          .icon {
            fill: $white;
          }
        }
      }
    }
  }

  .download-right {
    .right-inner {
      position: relative;
      max-width: 460px;
      width: 100%;
      margin-left: auto;
      text-align: center;
      display: flex;
      align-items: center;
      height: 100%;
      @media screen and (max-width: 767px) {
        min-height: 350px;
        margin-top: 1rem;
      }

      .download-circle {
        width: 229px;
        height: 229px;
        border-radius: 229px;
        display: inline-block;
        background: transparent linear-gradient(180deg, #FF7541 0%, #FFFFFF41 100%);
        z-index: 0;
        position: relative;
        bottom: -20%;
        margin: auto;
      }

      .download-img {
        max-height: 361px;
        position: relative;
        z-index: 2;
        position: absolute;
        right: 3%;
        margin: auto;
        top: -4%;
        bottom: 0;
        z-index: 3;

        @media screen and (max-width: 453px) {
          right: 0;
          left: 0;
          max-width: 100%;
        }
      }
    }
  }
}

.ant-dropdown {
  &.user-drop-menu {
    min-width: 223px !important;

    .ant-dropdown-menu {
      box-shadow: 0px 3px 26px #00000029;
      border-radius: 5px;
      padding: 15px 24px 10px 24px;

      .user-upper {
        margin-bottom: 5px;

        h4 {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 5px;
        }

        p {
          font-size: 12px;
          margin: 0;
          color: $primary-color;

          &.light-text {
            color: $text-secondary;
          }
        }

        .exp-date {
          font-size: 10px;
          color: $primary-color;
          margin-top: 8px;

          span {
            font-weight: 600;
          }
        }
      }

      li {
        &.ant-dropdown-menu-item {
          padding: 10px 5px;
          color: $primary-color;

          .menu-icons {
            width: 25px;
            height: 25px;
            border-radius: 25px;
            background: $body-background-light;
            padding: 5px;
            margin-right: 14px;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
              width: 13px;
              fill: $primary-color;
            }
          }

          a {
            color: $primary-color;
          }
        }

        &:first-child {
          border-bottom: 1px solid #C6CCD343;
          cursor: default;
          background: none;
          margin-bottom: 5px;
        }

        &:nth-last-child(2) {
          text-align: center;
          border-top: 1px solid #C6CCD343;
          margin-top: 1rem;
        }

        &:nth-last-child(1) {
          text-align: center;
          border-top: 0;
          margin-top: 0;
          padding: 5px;
        }
      }
    }
  }
}