@import "../../../assets/scss/variables.scss";

.tp-item-card-wrapper {
  position: relative;

  .ant-btn:first-child {
    width: 24px;
    height: 24px;
    border-radius: 30px;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;


    .icon {
      width: 9.5px;
      fill: $primary-color;
    }

    &:hover {
      .icon {
        fill: $secondary-color;
      }
    }

    &.fev-selected {
      .icon {
        fill: $secondary-color;
      }
    }
  }

  .tp-item-card {
    border: 0;
    cursor: pointer;

    .prive-mode & {
      background: none;
    }

    .ant-card-head {
      border: 0;
      min-height: auto;
    }

    .ant-card-body {
      position: relative;
      padding: 30px 0 15px 0;
    }

    .ant-card-cover {
      overflow: hidden;
      border-radius: 10px;


      .deal-image-container {
        display: block;
        width: 100%;
        height: auto;
        position: relative;
        overflow: hidden;
        padding: 80% 0 0 0;

        img, iframe {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          object-fit: cover;
          transition: all ease-in 0.2s;
        }
      }
    }

    .offer-tag {
      background: $white;
      box-shadow: 0px 3px 36px #00000017;
      border-radius: 30px;
      padding: 0 10px;
      color: $secondary-color;
      font-weight: 600;
      display: inline-block;
      height: 30px;
      line-height: 30px;
      text-align: center;
      position: absolute;
      top: -15px;
      left: 15px;
    }

    .bottom-row {
      display: flex;
      justify-content: space-between;
      color: $primary-color;

      .left-col {
        h3 {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 5px;

          .prive-mode & {
            color: $white;
          }
        }

        p {
          font-size: 12px;
          color: $label-color;
          display: flex;
          align-items: center;
          margin-bottom: 0;

          .icon {
            width: 8px;
            margin-right: 5px;

            .prive-mode & {
              fill: $label-color;
            }
          }
        }
      }

      .right-col {
        text-align: right;

        h3 {
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 5px;
        }

        p {
          color: $label-color;
          font-size: 12px;
          margin-bottom: 0;
        }

        .price-striked {
          color: #718092;
          text-decoration: line-through;
          font-weight: normal;
        }
      }
    }

    &:hover {
      .ant-card-cover {
        img {
          transform: scale(1.2);
        }
      }
    }
  }
}
