@import "../../../assets/scss/variables.scss";

.cancel-sub {
    span {
        color: $primary-color;
        text-decoration: underline;
    }
}
.ant-btn.cancel-text {
    display: block;
    text-align: center;
    margin: 0 auto;
    font-size: 20px;
    letter-spacing: 0.4px;
    color: $primary-color !important;
    background-color: transparent;
}
.yes-cancel-btn {
    background: #FF3B00 !important;
        border: 1px solid #FF3B00 !important;
        color: $white !important;
        box-shadow: 0px 10px 20px #FF3B0050;
        border-radius: 6px;
        &:hover, &:focus {
            background: darken(#FF3B00, 3) !important;
            border-color: darken(#FF3B00, 3) !important;
            color: $white !important;
        }
}