$white: #fff;
$black: #000;

$primary-color: #132C4A; // primary color for all components
$secondary-color: #FF7541;
$success-color: #2AC18D; // success state color
$warning-color: #faad14; // warning state color
$error-color: #ff0050; // error state color
$purple-color: #944EF6;
$prive-color: #A88625;

$border-primary: #C6CCD3;
$border-secondary: #DEE1E5;

$text-primary: #11131E;
$text-secondary: #67696F;
$text-light: #6B6B6B;
$text-white: $white;
$label-color: #718092;

$body-background: $white;
$light-grey: #F6F6F6;
$footer-bg: #F0F9FA;
$body-background-light: #F5FCFC;

$font-family: 'Source Sans Pro', sans-serif;
$font-size-base: 0.875rem;
$font-size-16: 1rem;
$font-size-17: 1.063rem;
$font-size-18: 1.125rem;
$font-size-20: 1.25rem;