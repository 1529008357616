@import '../../assets/scss/variables.scss';

.react-tel-input {
  height: 50px;
  display: inline-flex;
}

.react-tel-input .form-control {

  width: 100% !important;
  border-radius: 10px !important;

  &:focus,
  &:hover {
    border-color: #1677ff !important;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1) !important;
  }
}