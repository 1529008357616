@import '../../assets/scss/variables.scss';

.twl-details-wrapper {

    .size-details2{
        padding: 35px 25px 25px 25px;
        background: #FFFFFF;
        box-shadow: 0px 8px 30px #00000007;
        border-radius: 10px;
        .prive-mode & {
            background: darken($primary-color, 3);
        }
        @media screen and (max-width:767px) {
            padding: 0;
        }
        .left-image-main{
            @media screen and (max-width:767px) {
                padding: 0;
            }
        }
        .left-image{
            height: 420px;
            overflow: hidden;
            border-radius: 10px;
            @media screen and (max-width:767px) {
                margin-bottom: 1.5rem;
            }
            @media screen and (max-width:640px) {
                height: 320px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .offer-details-right{
            @media screen and (max-width:767px) {
                padding: 0 25px;
            }
            .head {
                margin-bottom: 10px;
                h4 {
                    font-size: 18px;
                    margin-bottom: 5px;
                    .prive-mode & {
                        color: $white;
                    }
                }

                p {
                    font-size: 14px;
                    text-transform: uppercase;
                    letter-spacing: 1.5px;
                    .prive-mode & {
                        color: $white;
                    }
                }
            }

            .ant-list {
                .ant-list-item {
                    padding: 16px 0;
                    cursor: pointer;

                    .ant-list-item-action{
                        margin-left: 5px;
                        li {
                            padding: 15px 0;
                            .icon {
                                background: #6FC3CE;
                                padding: 7px;
                                width: 25px;
                                height: 25px;
                                border-radius: 25px;
                                fill: $white;
                            }
                        }
                    }

                    .ant-list-item-meta {
                        .ant-list-item-meta-avatar {
                            width: 52px;
                            height: 52px;
                            border-radius: 15px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 12px;

                            .ant-avatar {
                                background: none;
                            }

                            img {
                                width: 100%;
                            }
                        }

                        .ant-list-item-meta-title {
                            font-size: 18px;
                            font-weight: 600;
                            color: $primary-color;
                            margin-bottom: 0;
                            .prive-mode & {
                                color: $white;
                            }
                        }

                        .ant-list-item-meta-description {
                            font-size: 12px;
                            color: $primary-color;
                            .prive-mode & {
                                color: $white;
                            }
                        }
                    }

                    &:nth-child(1) {
                        .ant-list-item-meta {
                            .ant-list-item-meta-avatar {
                                background: #FFF2EC;
                            }
                        }
                    }

                    &:nth-child(2) {
                        .ant-list-item-meta {
                            .ant-list-item-meta-avatar {
                                background: #F5FCFC;
                            }
                        }
                    }

                    &:nth-child(3) {
                        .ant-list-item-meta {
                            .ant-list-item-meta-avatar {
                                background: #F1EAF2;
                            }
                        }
                    }
                }
            }
            .bottom-action{
                .ant-btn {
                    margin-bottom: 0;
                    margin-top: 1rem;
                    font-size: 18px;
                    font-weight: 600;
                    height: 60px;
                    line-height: 60px;
                    .prive-mode & {
                        color: $white;
                    }
                }
            }
        }
    }
    .learn-btn-row{
        text-align: left;
        margin-top: 10px;
        @media screen and (max-width:767px) {
            padding: 0 25px;
            margin-top: 20px;
            text-align: center;
        }
        .ant-btn-link{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            height: 30px;
            margin-top: 8px;
            .prive-mode & {
                background: none;
                color: $white;
            }
            @media screen and (max-width:767px) {
                margin: auto;
            }
            span {
                text-decoration: underline;
                font-size: 14px;
                font-weight: normal;
            }
            .icon {
                fill: $secondary-color;
                width: 20px;
                margin-right: 6px;
            }
        }
    }
}