@import '../../../assets/scss/variables.scss';

.buymembershicheckout-wrapper {
  background: $body-background-light;
  min-height: 100vh;
  overflow-y: auto;

  .bmc-row {
    display: flex;
    justify-content: space-between;
    margin-top: 90px;
    margin-bottom: 30px;
    @media screen and (max-width: 991px) {
      flex-direction: column-reverse;
    }

    .bmc-card {
      background: $white;

      .bmc-header {
        border-bottom: 1px dashed $border-primary;
        padding: 1rem;
        font-size: 1.125rem;
        font-weight: 600;
      }
    }

    .checkout-left {
      width: 100%;

      .bmccontent-inner {
        max-width: 610px;
        margin: auto;
        padding: 2rem 0;
        width: 98%;

        .inner-title {
          font-weight: 600;
          font-size: 1.1rem;
        }
      }

      .payment-tabs {
        margin-top: 0.8rem;

        .ant-tabs-nav {
          &::before {
            display: none;
          }

          .ant-tabs-nav-wrap {
            width: 100%;

            .ant-tabs-ink-bar {
              display: none;
            }

            .ant-tabs-nav-list {
              width: 100%;
              justify-content: space-between;

              .ant-tabs-tab {
                width: 100%;
                @media screen and (max-width: 676px) {
                  margin: 0 0 0 10px;
                }

                .ant-tabs-tab-btn {
                  width: 100%;

                  .options-card {
                    text-align: center;
                    @media screen and (max-width: 676px) {
                      height: 70px;
                      max-width: 80px;
                    }

                    p {
                      font-size: 0.875rem;
                      margin-top: 1.35rem;
                    }

                    .optionscard-inner {
                      border: 1px solid $border-primary;
                      border-radius: 7px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: 110px;
                      @media screen and (max-width: 676px) {
                        height: 70px;
                      }

                      .icon {
                        width: 100%;
                        height: 20px;
                      }
                    }
                  }
                }

                &:hover {
                  .ant-tabs-tab-btn {
                    .options-card {
                      p {
                        font-weight: 600;
                        color: $primary-color;
                      }

                      .optionscard-inner {
                        border: 1px solid $primary-color;
                      }
                    }
                  }
                }

                &.ant-tabs-tab-active {
                  .ant-tabs-tab-btn {
                    .options-card {
                      p {
                        font-weight: 600;
                        color: $primary-color;
                      }

                      .optionscard-inner {
                        border: 1px solid $primary-color;
                        position: relative;

                        &::after {
                          content: " ";
                          width: 26px;
                          height: 26px;
                          border-radius: 20px 0 6px 0;
                          background-color: #6FC3CE;
                          position: absolute;
                          bottom: 0;
                          right: 0;
                          background-repeat: no-repeat;
                          background-position: 8px 9px;
                          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.666' height='9.758' viewBox='0 0 12.666 9.758'%3E%3Cpath id='Path_303889' data-name='Path 303889' d='M193.274 179.882l-4.154-4.152 1.248-1.248 2.906 2.906 7.267-7.265 1.246 1.246Z' transform='translate(-189.12 -170.124)' fill='%23fff'/%3E%3C/svg%3E");
                          @media screen and (max-width: 676px) {
                            width: 22px;
                            height: 22px;
                            background-size: 11px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .checkout-right {
      width: 100%;
      max-width: 372px;
      margin-left: 1.8rem;
      @media screen and (max-width: 1100px) {
        max-width: 320px;
        margin-left: 1rem;
      }
      @media screen and (max-width: 991px) {
        max-width: 100%;
        margin-left: 0;
        margin-bottom: 1.5rem;
      }

      .bmccontent-inner {
        padding: 0.5rem 1.8rem 3rem 1.8rem;
        @media screen and (max-width: 400px) {
          padding: 0.5rem 1.2rem 3rem 1.2rem;
        }

        .membership-plan-list {
          li, &:last-child {
            border-bottom: 1px dashed $border-primary;
          }
        }
        

        .promocode-row {
          width: 100%;

          .promocode-colum {
            display: flex;
            align-items: center;
            width: 100%;
            margin: 1.6rem 0 1.5rem;

            .float-label {
              width: 100%;

              .label {
                top: 16px;
              }

              .label-float {
                top: -10px;
              }

            }

            
            
            .ant-input-affix-wrapper {
              padding-top: 0;
              padding-bottom: 0;
              border-radius: 6px 0 0 6px;

              .ant-input {
                height: 50px;
                border-radius: 6px 0 0 6px;
                padding-left: 8px;
                font-weight: 600;

                &::placeholder {
                  color: $primary-color;
                }

                &::-webkit-placeholder {
                  color: $primary-color;
                }

                &::-moz-placeholder {
                  color: $primary-color;
                }
              }
            }

            .ant-btn {
              border-radius: 0 6px 6px 0;
              padding: 0 10px;
              font-size: 0.735rem;
              min-height: 52px;
            }
          }

          .valid-invalid-row{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -16px;
            margin-bottom: 16px;

            svg {
              height: 16px;
              width: 16px;
            }

            span {
              margin-left: 8px;
            }
          }
        }

        .payment-details {
          margin-bottom: 1.5rem;

          .discount-row {
            font-size: 0.875rem;
            margin-bottom: 0.4rem;

            .text-right {
              font-weight: 600;
            }
          }

          .willpay-row {
            font-size: 1rem;
            color: $secondary-color;
            font-weight: 600;

            .text-right {
              font-size: 1.25rem;
              font-weight: bold;
            }
          }
        }

        .havemembid-btn {
          font-weight: normal;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto;

          span {
            text-decoration: underline;
            color: $primary-color !important;
          }

          .icon {
            margin-right: 4px;
          }
        }

        .getit-touch {
          border: 1px dashed $border-primary;
          padding: 1.6rem 1.4rem;
          border-radius: 10px;
          font-size: 0.875rem;
          margin-top: 1rem;

          p {
            margin-bottom: 0;
            max-width: 210px;
            color: #132C4A;
          }

          a {
            color: $secondary-color;
          }

          .upper-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0.5rem;

            h3 {
              font-weight: 600;
            }

            .enq-people-list {
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 30px;
                margin-left: -15px;

                &:nth-child(1) {
                  margin-left: 0;
                }

                &:hover {
                  box-shadow: 2px 2px 10px rgba($secondary-color, 0.2);
                }
              }
            }
          }
        }
      }
    }
  }
}
