@import "../../../../assets/scss/variables.scss";


.payment-form {
  padding: 16px;

  label {
    color: $primary-color;
    font-size: 16px;
  }

  #card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
  }

  #card-number-element {
    border-radius: 10px;
    padding: 12px;
    border: 1px solid #d9d9d9;
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;

    &:focus,
    &:hover {
      border-color: #1677ff !important;
      box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1) !important;
    }
  }


  #card-cvv-element {
    border-radius: 10px;
    padding: 12px;
    border: 1px solid #d9d9d9;
    max-height: 44px;
    background: white;
    box-sizing: border-box;

    &:focus,
    &:hover {
      border-color: #1677ff !important;
      box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1) !important;
    }
  }

  #card-expiry-element {
    border-radius: 10px;
    padding: 12px;
    border: 1px solid #d9d9d9;
    max-height: 44px;
    background: white;
    box-sizing: border-box;

    &:focus,
    &:hover {
      border-color: #1677ff !important;
      box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1) !important;
    }
  }

}

.pay-button {
  height: 52px;
  line-height: 100%;
  padding: 0 25px;
  background: $primary-color;
  border: 0;
  border-radius: 6px;
  font-weight: 400;
  font-size: $font-size-17;
  text-shadow: none;
  font-family: $font-family;
  color: $white;
  width: 100%;

  &:hover,
  &:focus {
    background: darken($primary-color, 2);
  }

  &:disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.04);
    box-shadow: none;
  }

  &:hover:disabled,
  &:focus:disabled {
    background: lighten($black, 60);
  }


}

#card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}