@import '../../assets/scss/variables.scss';

.landing-wrapper {
  overflow-x: hidden;
}

.twl-landing-wrapper {
  @media screen and (max-width: 767px) {
    padding-top: 0;
  }

  .landing-banner {
    position: relative;
    padding: 10rem 0 6rem;
    min-height: 640px;

    @media screen and (max-width: 767px) {
      min-height: 500px;
    }

    .background-image {
      position: absolute;
      z-index: 0;
      width: 100vw;
      height: 100%;
      top: 0;
      background-size: cover;
      background-position: center 100%;
    }

    .banner-row {
      position: relative;
      z-index: 2;

      h1 {
        font-size: 5rem;
        color: $white;
        margin-bottom: 1rem;
        font-weight: 600;
        line-height: 1.1;

        @media screen and (max-width: 991px) {
          font-size: 4rem;
        }

        @media screen and (max-width: 767px) {
          font-size: 3rem;
        }

        span {
          font-weight: normal;
          display: block;
        }
      }

      p {
        font-size: 1.8rem;
        color: $white;
        @media screen and (max-width: 767px) {
          font-size: 1.2rem;
        }
      }
    }
  }

  .globalsearch-section {
    margin-top: -60px;

    @media screen and (max-width: 767px) {
      margin-top: -80px;
    }

    .main-search {
      display: flex;
      align-items: center;
      max-width: 520px;
      margin: auto;
      width: 100%;
      height: 50px;
      border: 1px solid #dee1e566;
      box-shadow: 0px 6px 16px #00000008;
      border-radius: 70px;
      padding: 10px;
      transition: all ease-in 0.1s;
      background: $white;
      position: relative;

      .globalsearch-country {
        display: flex;
        align-items: center;
        border-right: 1px solid #dee1e566;

        .select-label {
          font-size: 10px;
          color: $label-color;
          padding-left: 10px;
        }

        .ant-select {

          .ant-select-arrow {
            display: none;
          }

          .ant-select-selector {
            border: 0;
            height: 28px;
            background: none;
            border: 0;
            box-shadow: none;
            outline: none;

            @media screen and (max-width: 767px) {
              font-size: 10px;
              padding: 0 4px;
            }

            .ant-select-selection-item {
              display: flex;
              align-items: center;
              color: $primary-color !important;

              .city-img {
                width: 20px;
                height: 20px;
                min-width: 20px;
                border-radius: 20px;
                overflow: hidden;
                margin-right: 3px;
                display: flex;
                align-items: center;

                @media screen and (max-width: 767px) {
                  width: 15px;
                  height: 15px;
                  min-width: 15px;
                  border-radius: 15px;
                }

                svg {
                  width: 100%;
                  height: 100%;
                }

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }

      .globalsearch-ctg {
        margin-right: 15px;
        display: flex;
        align-items: center;
        border-right: 1px solid #dee1e566;

        @media screen and (max-width: 576px) {
          margin-right: 5px;
        }


        .select-label {
          font-size: 10px;
          color: $label-color;
          padding-left: 10px;
        }

        .ant-select {
          
          .ant-select-arrow {
            display: none;
          }

          
          .ant-select-selector {
            border: 0;
            height: 28px;
            background: none;
            border: 0;
            box-shadow: none;
            outline: none;

            @media screen and (max-width: 767px) {
              font-size: 10px;
              padding: 0 4px;
            }

            .ant-select-selection-item {
              display: flex;
              align-items: center;
              color: $primary-color !important;

              .category-img {
                width: 20px;
                height: 20px;
                min-width: 20px;
                border-radius: 20px;
                overflow: hidden;
                margin-right: 3px;
                display: flex;
                align-items: center;

                @media screen and (max-width: 767px) {
                  width: 15px;
                  height: 15px;
                  min-width: 15px;
                  border-radius: 15px;
                }

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }

      }

      .globalsearch-search {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;

  
          .select-label {
            font-size: 10px;
            color: $label-color;
            padding-left: 10px;
            @media screen and (max-width: 767px) {
              font-size: 7px;
            }
          }
        

        .ant-select {
          width: 100%;

          .ant-select-selector {
            border: 0;
            outline: none;
            box-shadow: none;
            background: none;
            
            .ant-select-selection-search{
              inset-inline-start: unset;
              inset-inline-end: unset;
            }

            @media screen and (max-width: 767px) {
              font-size: 10px;
              padding: 0 4px;
            }

            .ant-select-selection-placeholder {
              color: $primary-color;
            }
          }
        }

        .search-icon-btn {
          width: 34px;
          height: 34px;
          min-width: 34px;
          border-radius: 34px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: -5px;

          .icon {
            fill: $white;
          }
        }

        .search-btn {
          height: 40px;
          width: 83px;
          border-radius: 40px;
          padding: 0 14px;
          font-size: 12px;
          display: flex;
          align-items: center;

          @media screen and (max-width: 767px) {
            display: none;
          }

          .icon {
            width: 14px;
            fill: $white;
            margin-right: 5px;
          }
        }

        .filter-btn {
          @media screen and (max-width: 767px) {
            position: absolute;
            right: 0;
            width: 36px;
            height: 36px;
            border-radius: 36px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-color: $border-primary;
            .icon {
              width: 13px;
            }
          }
        }
      }
    }
  }

  .handpickscollection-section {
    padding: 5rem 0;

    .slick-arrow {
      color: $primary-color;
      font-size: 1.25rem;
      z-index: 2;
      width: auto;
      position: absolute;
      opacity: 1;
      bottom: -50px !important;

      &::before {
        display: none;
      }

      &.slick-next {
        bottom: 0;
        top: auto;
        right: 0;
      }

      &.slick-prev {
        bottom: 0;
        top: auto;
        right: 50px;
        left: auto;
      }

      &.slick-disabled {
        color: #718092;
        cursor: inherit;
      }
    }

    .bottom-arrow {
      position: absolute;
      right: 120px;
      width: 50px;
      margin-top: 5px;
      fill: #718092;
      bottom: 0;
      animation: iconcolor-change1 1s infinite;
      bottom: -50px;
    }

    .heading {
      font-size: 3.125rem;
      font-weight: 600;
      margin: 0 auto 2.5rem auto;
    }

    .handpicks-collection-slider {
      .slick-list {
        margin: 0 -15px;

        .slick-track {
          .slick-slide {
            padding: 15px;
          }
        }
      }

      .ant-card {
        .ant-card-body {
          position: absolute;
          z-index: 1;
          bottom: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: flex-end;
          background: rgb(0, 0, 0);
          background: -moz-linear-gradient(
                          0deg,
                          rgba(0, 0, 0, 0.8827906162464986) 0%,
                          rgba(18, 18, 18, 0.4290091036414566) 23%,
                          rgba(0, 0, 0, 0) 100%
          );
          background: -webkit-linear-gradient(
                          0deg,
                          rgba(0, 0, 0, 0.8827906162464986) 0%,
                          rgba(18, 18, 18, 0.4290091036414566) 23%,
                          rgba(0, 0, 0, 0) 100%
          );
          background: linear-gradient(
                          0deg,
                          rgba(0, 0, 0, 0.8827906162464986) 0%,
                          rgba(18, 18, 18, 0.4290091036414566) 23%,
                          rgba(0, 0, 0, 0) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
          border-radius: 20px;
          color: $white;

          .meta-row {
            color: $white;
            position: relative;
            z-index: 2;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;

            .meta-left {
              p {
                font-size: 20px;
                margin-bottom: 0;
                line-height: 1;
              }

              h2 {
                font-size: 30px;
                margin-bottom: 10px;
                color: $white;
              }

              .venues-row {
                display: flex;
                align-items: center;
                font-size: 14px;

                ul {
                  list-style-type: none;
                  padding-left: 0;
                  margin: 5px 0 0 0;
                  display: flex;
                  align-items: center;

                  li {
                    width: 22px;
                    height: 22px;
                    border-radius: 22px;
                    overflow: hidden;
                    margin-right: 7px;

                    img {
                      height: 100%;
                    }
                  }
                }
              }
            }

            .ant-btn {
              height: 40px;
              border-radius: 40px;
              padding: 0 20px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .tour-section {
    padding: 1.5rem 0;
    @media screen and (max-width: 990px) {
      padding: 1rem 0;
    }

    h1 {
      font-size: 2.4rem;

      @media screen and (max-width: 767px) {
        font-size: 1.8rem;
      }
    }

    .more-btn {
      border: 1px solid $border-secondary;
      border-radius: 30px;
      font-size: 13px;

      &:hover {
        border-color: $secondary-color !important;
        color: $secondary-color !important;
      }
    }

    .tour-slider {
      .slick-list {
        margin: 0 -15px;

        .slick-slide {
          padding: 0 15px;
        }
      }

      .slick-arrow {
        border: 1px solid #d5d5d5;
        width: 25px;
        height: 25px;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;

        &::before {
          display: none;
        }

        &.slick-next {
          right: 75px;
          left: auto;
          top: -40px;

          @media screen and (max-width: 767px) {
            top: -35px;
          }
        }

        &.slick-prev {
          right: 108px;
          left: auto;
          top: -40px;

          @media screen and (max-width: 767px) {
            top: -35px;
          }
        }

        .icon {
          width: 5px;
          fill: $primary-color;
        }
      }

      @media screen and (min-width: 990px) {
        .image-list-card {
          height: 242px;
        }
      }
      @media screen and (max-width: 990px) {
        .image-list-card {
          height: 414px;
        }
      }
      @media screen and (max-width: 590px) {
        .image-list-card {
          height: 320px;
        }
      }
      @media screen and (max-width: 590px) {
        .image-list-card {
          height: 280px;
        }
      }
    }
  }

  .startyour-section {
    .startyour-row {
      align-items: center;
      padding: 8rem 0;

      @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
      }

      .startyour-left {
        @media screen and (max-width: 767px) {
          margin-top: 2rem;
        }

        h1 {
          margin-bottom: 2rem;
          font-size: 3.5rem;

          @media screen and (max-width: 767px) {
            font-size: 3rem;
          }
        }

        ul {
          list-style-type: none;
          padding-left: 0;
          margin-bottom: 3rem;

          li {
            font-size: 1.875rem;
            display: flex;
            align-items: center;
            padding: 0.6rem 0;

            @media screen and (max-width: 991px) {
              font-size: 1.5rem;
            }

            .icon {
              width: 30px;
              margin-right: 1.4rem;
            }
          }
        }

        .bottom-info {
          display: flex;
          align-items: center;
          max-width: 380px;
          font-style: italic;

          img {
            max-width: 50px;
            margin-right: 1rem;
          }

          p {
            font-size: 1rem;
            margin-bottom: 0;
            line-height: 1.3;
          }
        }
      }

      .startyour-right {
        position: relative;
        width: 100%;
        margin-top: 3rem;

        .right-img {
          max-width: 417px;
          width: 100%;
          border-radius: 10px;
        }

        .arrow {
          position: absolute;
          max-width: 140px;
          width: 100%;
          left: 53%;
          top: -15%;
          z-index: 1;
          animation: MoveUpDown 2s linear infinite;
          fill: $primary-color;
        }

        .subscriptions-card {
          position: absolute;
          right: 0;
          top: 50%;
          max-width: 382px;
          width: 100%;
          transform: translateY(-50%);

          .subscriptions-card-inner {
            background: $white;
            padding: 2rem 2.5rem 1.5rem 2.5rem;
            box-shadow: 0 17px 30px rgba(0, 0, 0, 0.03);
            width: 100%;

            @media screen and (max-width: 991px) {
              width: 90%;
              margin: auto;
              padding: 2rem 1.5rem 1.5rem 1.5rem;
            }
          }

          .upper-header {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 1rem;

            .ant-radio-group {
              background: #f5fcfc;
              height: 30px;
              border-radius: 30px;
              display: flex;
              align-items: center;
              padding: 0 5px;

              .ant-radio-button-wrapper {
                border: 0;
                height: 26px;
                line-height: 26px;
                border-radius: 30px;
                width: 50%;
                min-width: 50px;
                text-transform: uppercase;
                font-weight: normal;
                text-align: center;
                background: none;
                box-shadow: none;
                outline: none;

                &:hover {
                  color: $secondary-color;
                }

                &::before {
                  display: none;
                }

                .ant-radio-button {
                  box-shadow: none;
                  outline: none;
                }

                &.ant-radio-button-wrapper-checked {
                  background: #ffffff;
                  color: $primary-color;
                  font-weight: 600;
                }
              }
            }

            .need-help-icon {
              display: flex;
              align-items: center;
              color: $primary-color;
              text-decoration: underline;

              .icon {
                margin-right: 4px;
              }
            }
          }

          .header {
            text-transform: uppercase;
            font-size: 0.875rem;
          }

          ul {
            padding-left: 0;

            li {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid $border-primary;
              padding: 1.3rem 0;

              .save-badge {
                background: $success-color;
                border-radius: 0 20px 20px 20px;
                font-size: 11px;
                padding: 3px 8px;
                color: $white;
                margin-left: 10px;
                font-weight: 600;

                @media screen and (max-width: 400px) {
                  font-size: 10px;
                  border-radius: 0 15px 15px 15px;
                  padding: 3px 5px;
                  margin-left: 5px;
                }

                &.secondary-badge {
                  background: $secondary-color;
                }
              }

              &:last-child {
                border-bottom: 0;
              }

              .list-left {
                h3 {
                  font-size: 1.25rem;
                  font-weight: 600;
                  margin-bottom: 0;

                  @media screen and (max-width: 400px) {
                    font-size: 1.1rem;
                  }
                }

                p {
                  margin-bottom: 0;
                  font-size: 0.875rem;
                }
              }

              .right-check {
                border: 1px solid $border-primary;
                width: 25px;
                height: 25px;
                border-radius: 25px;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                  width: 12px;
                }
              }
            }
          }

          .ant-btn-link {
            font-weight: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1rem;

            .icon {
              width: 24px;
              margin-right: 6px;
            }

            &.text-decoration {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }



  .blog-section {
    padding: 10rem 0;

    .blog-upper {
      margin-bottom: 2.5rem;

      .blog-img {
        width: 92%;
        border-radius: 10px;
      }

      label {
        font-size: 12px;
        text-transform: uppercase;
      }

      h3 {
        font-size: 50px;
        margin-bottom: 2rem;
        margin-top: 0.5rem;
        @media screen and (max-width: 767px) {
          font-size: 30px;
        }
      }

      p {
        font-size: 20px;
        max-width: 450px;
        line-height: 1.5;
        margin-bottom: 2rem;
      }

      .bottom-text {
        margin-top: 2rem;
        text-transform: uppercase;
        letter-spacing: 1;
        font-weight: 500;
      }

      .ant-btn {
        .icon {
          fill: $white;
          margin-left: 1.5rem;
        }
      }
    }

    .ant-list {
      .ant-list-item {
        padding: 22px 0;
        font-family: $font-family !important;
        border-color: $border-secondary;

        &:last-child {
          border-block-end: 1px solid $border-secondary;
        }

        .ant-list-item-meta {
          align-items: center;

          .ant-list-item-meta-avatar {
            width: 80px;
            height: 80px;
            border-radius: 8px;
            overflow: hidden;

            img {
              width: 100%;
            }
          }

          .ant-list-item-meta-title {
            font-size: 20px;
            color: $primary-color;
            margin-bottom: 8px;
          }

          .ant-list-item-meta-description {
            font-weight: 600;
          }
        }

        .ant-list-item-action {
          .ant-btn {
            width: 40px;
            height: 40px;
            border-radius: 40px;
            border: 1px solid $border-secondary;
            padding: 0;
            text-align: center;

            .icon {
              width: 6px;
              fill: $primary-color;
            }

            &:hover {
              .icon {
                fill: $white;
              }
            }
          }
        }
      }
    }

    .more-blog {
      display: flex;
      align-items: center;
      margin-top: 1.5rem;

      ul {
        list-style-type: none;
        padding-left: 0;
        display: flex;
        margin: 0;

        li {
          width: 25px;
          height: 25px;
          border-radius: 25px;
          overflow: hidden;
          object-fit: cover;
          margin-right: 8px;

          img {
            height: 100%;
          }
        }
      }

      .ant-btn {
        padding: 0;
        border-bottom: 1px solid $primary-color;
        height: 30px;
        line-height: 30px;
        border-radius: 0;
        margin-left: 15px;
        font-size: 16px;
      }
    }
  }


  .explore-section {
    padding: 5rem 0;

    @media screen and (max-width: 767px) {
      padding: 3rem 0;
    }

    .heading {
      font-size: 3.125rem;
      font-weight: 600;
      margin: 0 auto 3.8rem auto;
    }

    .explore-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 5rem 3rem;
      list-style-type: none;
      padding-left: 0;

      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem 1rem;
      }

      @media screen and (max-width: 600px) {
        display: block;
      }

      li {
        @media screen and (max-width: 600px) {
          margin-bottom: 3rem;
        }

        .explore-list-card {
          width: 100%;

          .explore-list-card-image {
            transition: all ease-in 0.1s;
            margin-bottom: 2em;

            @media screen and (max-width: 767px) {
              border-radius: 12px;
            }

            .icon {
              width: 70px;
              fill: $primary-color;
            }
          }

          .explore-caption {
            margin-top: 1rem;

            p {
              margin-bottom: 0;
              font-size: 1.2rem;
              max-width: 350px;
              line-height: 1.6;
            }

            h3 {
              font-size: 1.8rem;
              font-weight: 600;
              margin-top: 1rem;
              margin-bottom: 1rem;
            }

            button {
              margin-top: 2.5rem;
              text-align: left;
              padding: 0;
              height: auto;
              line-height: auto;
              background: none;
              font-weight: normal;
              transition: all ease-in 0.1s;
              box-shadow: none;
              outline: none;

              &::after {
                display: none;
              }

              .icon {
                margin-left: 6px;
                width: 20px;
                transition: all ease-in 0.1s;
              }

              &:hover,
              &:focus {
                background: none;
                color: $secondary-color;
                box-shadow: none;
                outline: none;

                .icon {
                  fill: $secondary-color;
                  transform: translateX(7px);
                }
              }
            }
          }
        }

        .ant-card {
          max-width: 320px;
          position: relative;
          box-shadow: 0 13px 46px rgba(0, 0, 0, 0.24);
          border: 0;

          @media screen and (max-width: 600px) {
            max-width: 100%;
          }

          .ant-card-cover {
            img {
              width: 100%;
            }
          }

          .meta-row {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            color: $white;

            p {
              font-size: 24px;
              font-weight: 500;
              line-height: 1.3;
            }

            h3 {
              font-size: 40px;
              font-weight: 600;
              color: $white;
              margin-bottom: 0;
            }

            small {
              font-size: 1rem;
            }

            .icon {
              fill: $white;
              width: 23px;
              margin-bottom: 5px;
            }
          }

          .ant-card-body {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            padding: 30px;
          }
        }
      }
    }

    .explore-slider {
      .slick-list {
        margin: 0 -1.5rem;

        .slick-slide {
          padding: 1rem 1.5rem;

          @media screen and (max-width: 991px) {
            padding: 1rem 0.8rem;
          }

          .slider-card {
            position: relative;
            overflow: hidden;
            border-radius: 1rem;
            cursor: pointer;

            &::after {
              content: ' ';
              position: absolute;
              width: 100%;
              height: 100%;
              display: inline-block;
              background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
              background: -webkit-linear-gradient(
                              top,
                              rgba(0, 0, 0, 0) 0%,
                              rgba(0, 0, 0, 0.5) 100%
              );
              background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=0);
              z-index: 1;
              top: 0;
              transition: all ease-in 0.2s;
            }

            img {
              width: 100%;
            }

            .caption {
              position: absolute;
              bottom: 30px;
              left: 30px;
              z-index: 2;
              color: $white;

              p {
                font-size: 1.875rem;
                margin-bottom: 0;
                font-weight: normal;
                line-height: 1.2;

                @media screen and (max-width: 991px) {
                  font-size: 1.5rem;
                }
              }

              h3 {
                font-size: 2.5rem;
                font-weight: 600;
                color: $white;
                margin-bottom: 0;

                @media screen and (max-width: 991px) {
                  font-size: 2rem;
                }
              }
            }

            &:hover {
              &::after {
                background: -moz-linear-gradient(
                                top,
                                rgba($secondary-color, 0) 0%,
                                rgba($secondary-color, 0.7) 100%
                );
                background: -webkit-linear-gradient(
                                top,
                                rgba($secondary-color, 0) 0%,
                                rgba($secondary-color, 0.7) 100%
                );
                background: linear-gradient(
                                to bottom,
                                rgba($secondary-color, 0) 0%,
                                rgba($secondary-color, 0.7) 100%
                );
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=0);
              }
            }
          }
        }
      }

      .slick-arrow {
        color: $primary-color;
        font-size: 1.25rem;
        z-index: 2;
        width: auto;
        position: absolute;
        opacity: 1;

        &::before {
          display: none;
        }

        &.slick-next {
          bottom: -42px;
          top: auto;
          right: 0;
        }

        &.slick-prev {
          bottom: -42px;
          top: auto;
          right: 50px;
          left: auto;
        }

        &.slick-disabled {
          color: #718092;
          cursor: inherit;
        }
      }
    }

    .bottom-arrow {
      position: absolute;
      right: 120px;
      width: 50px;
      margin-top: 5px;
      fill: #718092;
      animation: iconcolor-change1 1s infinite;
    }

    .explor-bottom {
      margin-top: 3rem;

      h3 {
        font-size: 1.875rem;
        font-weight: 600;
        text-align: center;
      }

      ul {
        list-style-type: none;
        padding-left: 0;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 1.5rem;
        max-width: 690px;
        margin: 3rem auto auto auto;

        @media screen and (max-width: 740px) {
          grid-template-columns: repeat(3, 1fr);
        }

        li {
          text-align: center;

          .icon {
            height: 50px;
            width: auto;
            fill: $primary-color;
            transition: all ease-in 0.1s;

            @media screen and (max-width: 480px) {
              height: 40px;
            }
          }

          p {
            font-size: 1.25rem;
            margin-top: 1rem;

            @media screen and (max-width: 480px) {
              font-size: 1rem;
            }
          }

          &:hover {
            .icon {
              fill: $secondary-color;
            }
          }
        }
      }
    }
  }




  .howitworks-section {
    .row {
      align-items: center;
    }

    background-color: $primary-color;
    padding: 100px 0;
    color: $white;

    .hw-text-block {
      span {
        display: block;
        font-size: 12px;
        margin-bottom: 15px;
      }

      h2 {
        font-size: 50px;
        line-height: 60px;
        color: $white;
        margin-bottom: 60px;
      }

      ul {
        display: flex;
        align-items: center;
        list-style-type: none;
        justify-content: flex-start;
        padding-left: 0;

        li {
          padding: 0px 15px;

          .ant-btn {
            background: none;
            padding: 0;

            .icon {
              fill: $white;
              width: 17px;
              height: 17px;
              transition: all ease-in 0.1s;

              &:hover {
                fill: #ff7541;
                transform: scale(1.5);
              }
            }
          }
        }
      }
    }

    .hw-explore {
      position: relative;
      width: 419px;
      margin: 0 auto;

      img {
        border-radius: 16px;
        max-width: 100%;
      }

      video {
        width: 100%;
        border-radius: 16px;
      }

      .ant-btn {
        position: absolute;
        bottom: 50px;
        left: 50px;
        height: initial;
        font-size: 18px;
        padding: 16px 32px 16px 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          width: 35px;
          height: 35px;
          fill: $secondary-color;
        }

        &:hover {
          background-color: $secondary-color;
          color: $white;
        }

        &:hover .icon {
          fill: $white;
          transition: none;
          transform: none;
        }
      }
    }
  }

  .letsjoin-section-experience {
    background: url('../../assets/images/thrilling-experience.jpg');
    background-size: cover;
    padding: 12rem 0;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;

    @media screen and (max-width: 991px) {
      background-size: 200%;
    }

    @media screen and (max-width: 767px) {
      padding: 4rem 0;
      background-attachment: inherit;
    }

    @media screen and (max-width: 576px) {
      padding: 2rem 0;
    }

    .letsjoin-row {
      .letsjoin-card {
        background: $secondary-color;
        color: $white;
        max-width: 373px;
        border-radius: 10px;
        padding: 2.5rem 90px 2.5rem 2.5rem;
        margin-left: auto;

        @media screen and (max-width: 767px) {
          padding: 1.5rem;
        }

        .upto-text {
          line-height: 1;
          margin-top: 0.5rem;
          text-transform: uppercase;
        }

        h3 {
          font-size: 1.875rem;
          color: $white;
          font-weight: 600;
          margin-bottom: 2rem;
        }

        h2 {
          font-size: 3.125rem;
          color: $white;
          font-weight: 600;
          margin-bottom: 0;
          margin-top: 0;
        }

        p {
          font-size: 1.25rem;
          color: $white;
          margin-bottom: 0;
        }

        img {
          margin-bottom: 26px;
        }

        .icon {
          width: 29px;
          fill: $white;
        }

        .ant-btn {
          border: 1px solid $white;
          border-radius: 40px;
          height: 40px;
          line-height: 38px;
          padding: 0 20px;
          font-size: 14px;
          color: $white;
          background: none;
          margin-top: 1.5rem;
        }
      }
    }
  }



}

@keyframes textcolor-change1 {
  0% {
    color: $primary-color;
  }

  50% {
    color: $secondary-color;
  }

  100% {
    color: $primary-color;
  }
}

@keyframes iconcolor-change1 {
  0% {
    fill: $secondary-color;
  }

  50% {
    fill: $primary-color;
  }

  100% {
    fill: $secondary-color;
  }
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(15px);
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba($white, 0.3),
    0 0 0 0.55em rgba($white, 0.3),
    0 0 0 1.1em rgba($white, 0.3),
    0 0 0 1.6em rgba($white, 0.3);
  }

  100% {
    box-shadow: 0 0 0 0.5em rgba($white, 0.3),
    0 0 0 1em rgba($white, 0.3),
    0 0 0 1.5em rgba($white, 0.3),
    0 0 0 2em rgba($white, 0);
  }
}

