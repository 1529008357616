@import '../../assets/scss/variables.scss';

.filter-drawer{
    .ant-drawer-header{
        border: 0;
        .close-btn{
            position: absolute;
            width: 40px;
            height: 40px;
            border-radius: 40px;
            top: 30px;
            right: 10%;
            background: #F3F3F4;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            z-index: 2;
            .icon {
                width: 13px;
                fill: $primary-color;
            }
        }
    }
    .ant-drawer-wrapper-body{
        position: relative;
        overflow-y: auto;
        max-height: calc(100vh - 60px);
    }
    .ant-drawer-body{
        max-width: 595px;
        margin: auto;
        width: 100%;
        position: relative;
        padding: 10px 20px;
    }
    .ant-picker-calendar  {
        .ant-picker-content {
            td {
                &.ant-picker-cell-selected {
                    .ant-picker-cell-inner {
                        background: $primary-color;
                    }
                }
            }
        }
    }
    .ant-slider {
        margin: 3.5rem 0;
        .ant-slider-step {
            top: -15px;
            .ant-slider-dot {
                background: $border-secondary;
                height: 50px;
                border: 0;
                width: 4px;
                border-radius: 4px;
                &.ant-slider-dot-active {
                    background: $secondary-color;
                }
                &:nth-child(3n) {
                    height: 66px;
                    top: -8.5px;
                }
            }
        }
        .ant-slider-rail {
            opacity: 0;
        }
        .ant-slider-track {
            opacity: 0;
        }
        .ant-slider-handle {
            width: 16px;
            height: 40px;
            top: -11px;
            box-shadow: 0px 10px 10px #00000029;
            border-radius: 16px;
            &::before {
                width: 16px;
                height: 40px;
                border-radius: 16px;
                background: $secondary-color;
                top: 0;
                left: 0;
            }
            &::after {
                border: 0 !important;
                box-shadow: none !important;
                background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMC4xMjUiIGhlaWdodD0iMzQuODc1IiB2aWV3Qm94PSIwIDAgMTAuMTI1IDM0Ljg3NSI+DQogIDxwYXRoIGlkPSJJY29uX2F3ZXNvbWUtZWxsaXBzaXMtdiIgZGF0YS1uYW1lPSJJY29uIGF3ZXNvbWUtZWxsaXBzaXMtdiIgZD0iTTYuNzUsMTIuOTM4QTUuMDYzLDUuMDYzLDAsMSwxLDEuNjg4LDE4LDUuMDU5LDUuMDU5LDAsMCwxLDYuNzUsMTIuOTM4Wk0xLjY4OCw1LjYyNUE1LjA2Myw1LjA2MywwLDEsMCw2Ljc1LjU2Myw1LjA1OSw1LjA1OSwwLDAsMCwxLjY4OCw1LjYyNVptMCwyNC43NUE1LjA2Myw1LjA2MywwLDEsMCw2Ljc1LDI1LjMxMyw1LjA1OSw1LjA1OSwwLDAsMCwxLjY4OCwzMC4zNzVaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMS42ODggLTAuNTYzKSIgZmlsbD0iI2ZmZiIvPg0KPC9zdmc+DQo=);
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 4px;
                background-position: center center;
                left: 0;
                right: 0;
                top: 0;
                right: 0;
            }
        }
        .ant-slider-mark {
            top: 45px;
            font-weight: 500;
            .ant-slider-mark-text  {
                font-size: 11px;

            }
        }
    }
    .ant-drawer-footer{
        background: #F5FCFC;
        border: 0;
        padding: 0;
        .footer-inner{
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 595px;
            margin: auto;
            padding: 18px 20px;
            h3{
                font-size: 22px;
                margin-bottom: 8px;
                font-weight: 500;
                @media screen and (max-width:576px) {
                    font-size: 18px;
                }
            }
            .ant-btn-link{
                font-size: 14px;
                padding: 0;
                height: 20px;
                line-height: 20px;
                span {
                    text-decoration: underline;
                }
            }
            .ant-btn-primary {
                height: 60px;
                line-height: 60px;
                padding: 0 60px;
                font-size: 18px;
                border-radius: 10px;
                @media screen and (max-width:576px) {
                    height: 50px;
                    line-height: 50px;
                }
            }
        }
    }
    .filterdrawer-inner{
        .title{
            font-size: 30px;
            font-weight: 600;
        }   
        .filter-row{
            padding: 25px 0 10px 0;
            .ant-slider {
                .ant-slider-track{
                    background: $secondary-color;
                }
                .ant-slider-dot{
                    border-color: $secondary-color;
                }
                .ant-slider-handle{
                    border-color: $secondary-color;
                    &:after {
                        box-shadow: 0 0 0 2px $secondary-color;
                    }
                }
            }
            .filter-head{
                h4{
                    font-size: 22px;
                    font-weight: 600;
                    margin-bottom: 8px;
                }
                p{
                    font-size: 14px;
                    margin-bottom: 0;
                }
            }
            .offertype{
                margin-top: 15px;
                .ant-radio-group {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
    
                    .ant-radio-button-wrapper {
                        border: 1px solid $border-secondary;
                        height: 50px;
                        line-height: 50px;
                        padding: 0 20px;
                        border-radius: 50px;
                        color: $primary-color;
                        width: 25%;
                        margin-right: 15px;
                        text-align: center;
                        width: 100%;

                        @media screen and (max-width:767px) {
                            padding: 0 8px;
                            margin-right: 5px;
                            height: 40px;
                            line-height: 40px;
                            font-size: 11px;
                        }
    
                        .ant-radio-button {
                            font-size: 12px;
                            @media screen and (max-width:767px) {
                                font-size: 11px;
                            }
                        }
    
                        &:last-child {
                            margin: 0;
                        }
    
                        &::before {
                            display: none;
                        }
    
                        &.ant-radio-button-wrapper-checked {
                            background: #FFF1EB;
                            border-color: #FFF1EB;
                            color: $secondary-color;
                            font-weight: 500;
                        }
                    }
                }
            }
            .choose-city{
                margin-top: 1rem;
                .ant-radio-group {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
    
                    .ant-radio-button-wrapper {
                        padding: 0;
                        border-radius: 50px;
                        color: $primary-color;
                        margin-right: 17px;
                        text-align: center;
                        width: 100%;
                        height: auto;
                        overflow: hidden;
                        border-radius: 20px;
                        border: 0;
                        max-height: 180px;

                        @media screen and (max-width:576px) {
                            max-height: 120px;
                        }

                        &:hover {
                            box-shadow: 0 13px 36px rgba($black, 0.15);
                        }

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
    
                        .ant-radio-button {
                            font-size: 12px;
                        }
    
                        &:last-child {
                            margin: 0;
                        }
    
                        &::before {
                            display: none;
                        }
    
                        &.ant-radio-button-wrapper-checked {
                            background: #FFF1EB;
                            border-color: #FFF1EB;
                            color: $secondary-color;
                            font-weight: 500;
                            &::after {
                                content: ' ';
                                border-radius: 20px 0 20px 0;
                                background: #6FC3CE;
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                z-index: 1;
                                width: 40px;
                                height: 40px;
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.497' height='15.021' viewBox='0 0 19.497 15.021'%3E%3Cpath id='Path_303889' data-name='Path 303889' d='M195.514 185.145l-6.394-6.391 1.921-1.921 4.473 4.473L206.7 170.124l1.918 1.918Z' transform='translate(-189.12 -170.124)' fill='%23fff'/%3E%3C/svg%3E");
                                background-repeat: no-repeat;
                                background-position: center;
                            }
                        }
                    }
                }
            }
        }
    }
}