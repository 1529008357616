@import "../../../assets/scss/variables.scss";

.deletaccount-modal {
    max-width: calc(100vw - 46px);
    @media screen and (max-width:991px) {
        max-width: calc(100vw - 15px);
    }
    .ant-modal-close{
        right: 25px;
        top: 25px;
        .ant-modal-close-x{
            width: 30px;
            height: 30px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $body-background-light;
            .icon{
                fill: $primary-color;
                width: 10px;
            }
            &:hover{
                background: $secondary-color;
                .icon{
                    fill: $white;
                }
            }
        }
    }
    .ant-modal-body{
        padding: 2rem 1.5rem;
    }

    .ant-modal-content{
        border-radius: 0;
        text-align: center;
        padding: 0;
        h1 {
            font-size: 2rem;
            margin-bottom: 1rem;
            @media screen and (max-width:767px){
                font-size: 2rem;
            }
        }
        p {
            font-size: 1.25rem;
            color: $primary-color;
            line-height: 1.3;
        }
    }
    .otp-input-container{
        width: 100%;
        max-width: 300px;
        justify-content: space-between;
        margin-top: 15px;
        margin-bottom: 10px;
        margin: 3rem auto 10px auto;
        .otp-input{
            input {
                width: 60px !important;
                height: 60px;
                background: $white;
                border: 1px solid $border-primary;
                border-radius: 10px;
                outline: none;
                font-size: 20px;
                font-weight: 600 !important;
            }
        }
    }
    .resend-otp {
        margin-top: 1.5rem;
        .ant-btn {
            background: none;
            height: auto;
            line-height: auto;
            padding: 0;
            color: $secondary-color;
            span {
                text-decoration: underline;
            }
        }
    }
    .delete-btn {
        background: #FF3B00;
        border: 1px solid #FF3B00;
        color: $white;
        box-shadow: 0px 10px 20px #FF3B0050;
        border-radius: 6px;
        &:hover, &:focus {
            background: darken(#FF3B00, 3) !important;
            border-color: darken(#FF3B00, 3) !important;
            color: $white !important;
        }
    }
    .cancel-btn {
        background: none;
        margin-top: 1rem;
        &:hover {
            background: none;
        }
    }

}