@import '../../assets/scss/variables.scss';

.twl-landing-prive {
    @media screen and (max-width:767px) {
        padding-top: 0;
    }

    .prive-mode & {
        background: #132C4A;
        color: $white;
    }

    .landing-banner {
        position: relative;

        .background-image {
            position: absolute;
            z-index: 0;
            width: 100vw;
            height: 100%;
            top: 0;
            background-size: cover;
            background-position: center 0;
        }

        .banner-row {
            position: relative;
            z-index: 2;
            padding: 10rem 0 6rem;
            min-height: 753px;
            align-items: center;

            .play-button {
                border-radius: 100%;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto;
                cursor: pointer;

                .playbtn-inner {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 33px;
                    height: 33px;
                    border-radius: 100%;

                    svg {
                        fill: $white;
                        width: 33px;
                        height: 33px;
                        border-radius: 33px;
                    }
                    animation: ripple2 1s linear infinite;
                    background: rgba(#25364A, 0.03);
                }
            }

            h1 {
                font-size: 4.5rem;
                color: $white;
                margin-bottom: 1rem;
                font-weight: bold;
                line-height: 1.1;

                @media screen and (max-width:1024px) {
                    font-size: 3.2rem;
                }

                span {
                    font-weight: normal;
                    display: block;
                }
            }

            p {
                font-size: 1.8rem;
                color: $white;
            }
        }
    }

    .supercollection-section {
        padding: 5rem 0 5rem 0;

        .heading {
            color: $white;
        }

        .supercollection-card {
            background: $white;
            padding: 3rem;
            max-width: 627px;
            width: 100%;
            margin-top: -13rem;

            @media screen and (max-width:767px) {
                padding: 2.2rem;
            }

            img {
                width: 100%;
                max-width: 100px;
                margin-bottom: 2rem;
            }

            h3 {
                font-size: 2.8rem;

                @media screen and (max-width:767px) {
                    font-size: 2rem;
                }
            }

            p {
                font-size: 1.2rem;
                color: $primary-color;
                line-height: 1.5;
                margin-bottom: 0;

                @media screen and (max-width:767px) {
                    font-size: 1rem;
                }
            }
        }

        .slick-arrow {
            color: $white;
            font-size: 1.25rem;
            z-index: 2;
            width: auto;
            position: absolute;
            opacity: 1;
            bottom: -50px !important;

            &::before {
                display: none;
            }

            &.slick-next {
                bottom: 0;
                top: auto;
                right: 0;
            }

            &.slick-prev {
                bottom: 0;
                top: auto;
                right: 50px;
                left: auto;
            }

            &.slick-disabled {
                color: #718092;
                cursor: inherit;
            }
        }

        .bottom-arrow {
            position: absolute;
            right: 120px;
            width: 50px;
            margin-top: 5px;
            fill: #718092;
            bottom: 0;
            animation: iconcolor-change1 1s infinite;
            bottom: -50px;
        }

        .heading {
            font-size: 3.125rem;
            font-weight: 600;
            margin: 0 auto 2.5rem auto;
        }

        .supercollection-bottom {
            margin-top: 7rem;
        }

        .supercollection-slider {
            .slick-list {
                margin: 0 -15px;

                .slick-track {
                    .slick-slide {
                        padding: 15px;
                    }
                }
            }

            .ant-card {
                border: 0;
                background: none !important;
                overflow: hidden;

                .ant-card-cover {
                    background: none;

                    img {
                        border-radius: 20px;
                    }
                }

                .ant-card-body {
                    position: absolute;
                    z-index: 1;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    right: 0;
                    display: flex;
                    align-items: flex-end;
                    background: rgb(0, 0, 0);
                    background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0.8827906162464986) 0%, rgba(18, 18, 18, 0.4290091036414566) 23%, rgba(0, 0, 0, 0) 100%);
                    background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0.8827906162464986) 0%, rgba(18, 18, 18, 0.4290091036414566) 23%, rgba(0, 0, 0, 0) 100%);
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8827906162464986) 0%, rgba(18, 18, 18, 0.4290091036414566) 23%, rgba(0, 0, 0, 0) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
                    border-radius: 18px;
                    color: $white;
                    border: 0;

                    .meta-row {
                        color: $white;
                        position: relative;
                        z-index: 2;
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        width: 100%;

                        .meta-left {
                            p {
                                font-size: 20px;
                                margin-bottom: 0;
                                line-height: 1;
                            }

                            h2 {
                                font-size: 30px;
                                margin-bottom: 10px;
                                color: $white;
                            }

                            .venues-row {
                                display: flex;
                                align-items: center;
                                font-size: 14px;

                                ul {
                                    list-style-type: none;
                                    padding-left: 0;
                                    margin: 5px 0 0 0;
                                    display: flex;
                                    align-items: center;

                                    li {
                                        width: 22px;
                                        height: 22px;
                                        border-radius: 22px;
                                        overflow: hidden;
                                        margin-right: 7px;

                                        img {
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                        }

                        .ant-btn {
                            height: 40px;
                            border-radius: 40px;
                            padding: 0 20px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    .explore-section {
        padding: 6rem 0 11rem 0;

        @media screen and (max-width:767px) {
            padding: 3rem 0;
        }

        .heading {
            font-size: 3.125rem;
            font-weight: 600;
            margin: 0 auto 3.8rem auto;
            color: $white;
        }

        h3 {
            color: $white;
        }

        .explore-list-row {
            display: flex;

            @media screen and (max-width:991px) {
                display: block;
            }

            .ant-card {
                max-width: 320px;
                position: relative;
                box-shadow: 0 13px 46px rgba(0, 0, 0, 0.24);
                border: 0;
                max-height: 315px;
                margin-top: auto;
                margin-left: auto;

                @media screen and (max-width:991px) {
                    margin-left: 0;
                    margin-top: 6rem;
                }

                @media screen and (max-width:600px) {
                    max-width: 100%;
                }

                .ant-card-cover {
                    img {
                        width: 100%;
                    }
                }

                .meta-row {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                    color: $white;

                    p {
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 1.3;
                    }

                    h3 {
                        font-size: 40px;
                        font-weight: 600;
                        color: $white;
                        margin-bottom: 0;
                    }

                    small {
                        font-size: 1rem;
                    }

                    .icon {
                        fill: $white;
                        width: 23px;
                        margin-bottom: 5px;
                    }
                }

                .ant-card-body {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    padding: 30px;
                }
            }
        }

        .explore-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 5rem 3rem;
            list-style-type: none;
            padding-left: 0;

            @media screen and (max-width:767px) {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 2rem 1rem;
            }

            @media screen and (max-width:600px) {
                display: block;
            }

            li {
                @media screen and (max-width:600px) {
                    margin-bottom: 3rem;
                }

                .explore-list-card {
                    width: 100%;
                    padding-right: 3rem;

                    .explore-list-card-image {
                        transition: all ease-in 0.1s;
                        margin-bottom: 2em;
                        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);

                        @media screen and (max-width:767px) {
                            border-radius: 12px;
                        }

                        .icon {
                            width: 70px;
                            fill: $white;
                            max-height: 70px;
                        }
                    }

                    .explore-caption {
                        margin-top: 1rem;

                        p {
                            margin-bottom: 0;
                            font-size: 1.2rem;
                            max-width: 350px;
                            line-height: 1.6;
                        }

                        h3 {
                            font-size: 1.8rem;
                            font-weight: 600;
                            margin-top: 1rem;
                            margin-bottom: 1rem;
                        }

                        button {
                            margin-top: 2.5rem;
                            text-align: left;
                            padding: 0;
                            height: auto;
                            line-height: auto;
                            background: none;
                            font-weight: normal;
                            transition: all ease-in 0.1s;
                            box-shadow: none;
                            outline: none;
                            color: $white;
                            font-size: 1.2rem;

                            &::after {
                                display: none;
                            }

                            .icon {
                                margin-left: 10px;
                                width: 28px;
                                transition: all ease-in 0.1s;
                                fill: $prive-color;
                            }

                            &:hover,
                            &:focus {
                                background: none;
                                color: $secondary-color;
                                box-shadow: none;
                                outline: none;

                                .icon {
                                    fill: $secondary-color;
                                    transform: translateX(7px);
                                }
                            }
                        }
                    }
                }

            }
        }

        .bottom-arrow {
            position: absolute;
            right: 120px;
            width: 50px;
            margin-top: 5px;
            fill: #718092;
            animation: iconcolor-change1 1s infinite;
        }

    }

    .startyour-section {
        .startyour-row {
            align-items: center;
            padding: 8rem 0;

            @media screen and (max-width:767px) {
                flex-direction: column-reverse;
            }

            .startyour-left {
                @media screen and (max-width:767px) {
                    margin-top: 2rem;
                }

                h1 {
                    margin-bottom: 2rem;
                    font-size: 3.5rem;

                    @media screen and (max-width:767px) {
                        font-size: 3rem;
                    }
                }

                ul {
                    list-style-type: none;
                    padding-left: 0;
                    margin-bottom: 3rem;

                    li {
                        font-size: 1.875rem;
                        display: flex;
                        align-items: center;
                        padding: 0.6rem 0;

                        @media screen and (max-width:991px) {
                            font-size: 1.5rem;
                        }

                        .icon {
                            width: 30px;
                            margin-right: 1.4rem;
                        }
                    }
                }

                .bottom-info {
                    display: flex;
                    align-items: center;
                    max-width: 380px;
                    font-style: italic;

                    img {
                        max-width: 50px;
                        margin-right: 1rem;
                    }

                    p {
                        font-size: 1rem;
                        margin-bottom: 0;
                        line-height: 1.3;
                    }
                }
            }

            .startyour-right {
                position: relative;
                width: 100%;
                margin-top: 3rem;

                .right-img {
                    max-width: 417px;
                    width: 100%;
                    border-radius: 10px;
                }

                .arrow {
                    position: absolute;
                    max-width: 140px;
                    width: 100%;
                    left: 53%;
                    top: -15%;
                    z-index: 1;
                    animation: MoveUpDown 2s linear infinite;
                    fill: $primary-color;
                }

                .subscriptions-card {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    max-width: 382px;
                    width: 100%;
                    transform: translateY(-50%);

                    .subscriptions-card-inner {
                        background: $white;
                        padding: 2rem 2.5rem 1.5rem 2.5rem;
                        box-shadow: 0 17px 30px rgba(0, 0, 0, 0.03);
                        width: 100%;

                        @media screen and (max-width:991px) {
                            width: 90%;
                            margin: auto;
                            padding: 2rem 1.5rem 1.5rem 1.5rem;
                        }
                    }

                    .upper-header {
                        display: inline-flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        margin-bottom: 1rem;

                        .ant-radio-group {
                            background: #F5FCFC;
                            height: 30px;
                            border-radius: 30px;
                            display: flex;
                            align-items: center;
                            padding: 0 5px;

                            .ant-radio-button-wrapper {
                                border: 0;
                                height: 26px;
                                line-height: 26px;
                                border-radius: 30px;
                                width: 50%;
                                min-width: 50px;
                                text-transform: uppercase;
                                font-weight: normal;
                                text-align: center;
                                background: none;
                                box-shadow: none;
                                outline: none;

                                &:hover {
                                    color: $secondary-color;
                                }

                                &::before {
                                    display: none;
                                }

                                .ant-radio-button {
                                    box-shadow: none;
                                    outline: none;
                                }

                                &.ant-radio-button-wrapper-checked {
                                    background: #FFFFFF;
                                    color: $primary-color;
                                    font-weight: 600;
                                }
                            }
                        }

                        .need-help-icon {
                            display: flex;
                            align-items: center;
                            color: $primary-color;
                            text-decoration: underline;

                            .icon {
                                margin-right: 4px;
                            }
                        }
                    }

                    .header {
                        text-transform: uppercase;
                        font-size: 0.875rem;
                    }

                    ul {
                        padding-left: 0;

                        li {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border-bottom: 1px solid $border-primary;
                            padding: 1.3rem 0;

                            .save-badge {
                                background: $success-color;
                                border-radius: 0 20px 20px 20px;
                                font-size: 11px;
                                padding: 3px 8px;
                                color: $white;
                                margin-left: 10px;
                                font-weight: 600;

                                @media screen and (max-width:400px) {
                                    font-size: 10px;
                                    border-radius: 0 15px 15px 15px;
                                    padding: 3px 5px;
                                    margin-left: 5px;
                                }

                                &.secondary-badge {
                                    background: $secondary-color;
                                }
                            }

                            &:last-child {
                                border-bottom: 0;
                            }

                            .list-left {
                                h3 {
                                    font-size: 1.25rem;
                                    font-weight: 600;
                                    margin-bottom: 0;

                                    @media screen and (max-width:400px) {
                                        font-size: 1.1rem;
                                    }
                                }

                                p {
                                    margin-bottom: 0;
                                    font-size: 0.875rem;
                                }
                            }

                            .right-check {
                                border: 1px solid $border-primary;
                                width: 25px;
                                height: 25px;
                                border-radius: 25px;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .icon {
                                    width: 12px;
                                }
                            }

                        }
                    }

                    .ant-btn-link {
                        font-weight: normal;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 1rem;

                        .icon {
                            width: 24px;
                            margin-right: 6px;
                        }

                        &.text-decoration {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .social-section {
        .customer-care-list {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 22px;

            .item {
                width: 40px;
                height: 40px;
                border-radius: 40px;
                margin-left: -14px;

                img {
                    width: 100%;
                }
            }
        }

        .copyright {
            font-size: 10px;
            color: $label-color;
        }
    }

    .discover-section {
        padding: 10rem 0;
        background: darken($primary-color, 3);

        .discover-card {
            display: flex;
            color: $white;

            @media screen and (max-width:991px) {
                display: block;
            }

            .discover-card-left {
                width: 50%;
                overflow: hidden;
                background-size: cover;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                min-height: 400px;

                @media screen and (max-width:991px) {
                    width: 100%;
                    padding: 4rem 1rem;
                }

                .icon {
                    width: 128px;
                    fill: $white;
                }

                img {
                    width: 280px;
                    fill: $white;
                }
            }

            .discover-card-right {
                width: 50%;
                padding: 5rem 4.5rem 5rem 4.5rem;

                @media screen and (max-width:991px) {
                    width: 100%;
                    padding: 3rem 0;
                }

                label {
                    color: $label-color;
                }

                h2 {
                    color: $white;
                    font-size: 2.5rem;
                    font-weight: 600;
                    margin-bottom: 1.8rem;
                    margin-top: 0.5rem;

                    @media screen and (max-width:767px) {
                        font-size: 28px;
                    }
                }

                p {
                    color: rgba($white, 0.7);
                    max-width: 300px;
                    line-height: 1.6;
                }

                ul {
                    list-style-type: none;
                    padding-left: 0;
                    margin: 0;

                    li {
                        width: 50%;
                        display: inline-block;
                        padding-top: 2.4rem;

                        .ds-icon {
                            width: 50px;
                            height: 50px;
                            border-radius: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid rgba($white, 0.31);
                            margin-bottom: 10px;

                            .icon {
                                width: 18px;
                                height: 18px;
                                fill: $white;
                            }
                        }

                        p {
                            color: rgba($white, 0.70);
                        }
                    }
                }
            }
        }
    }

    .faq-section {
        padding: 8rem 0 6rem 0;

        @media screen and (max-width:767px) {
            padding: 1rem 0 2rem;
        }

        h2 {
            font-size: 40px;
            margin-bottom: 15px;
            font-weight: 600;

            .prive-mode & {
                color: $white;
            }

            @media screen and (max-width:767px) {
                font-size: 30px;
            }
        }

        .faq-left {
            h1 {
                font-size: 3.125rem;
                max-width: 450px;
                line-height: 1.2;
                margin-bottom: 30px;

                .prive-mode & {
                    color: $white;
                }

                @media screen and (max-width:767px) {
                    font-size: 2.125rem;
                    margin-bottom: 1.5rem;
                }
            }

            .video-card {
                width: 100%;
                max-width: 362px;
                border-radius: 10px;
                overflow: hidden;
                position: relative;

                @media screen and (max-width:767px) {
                    margin-bottom: 1.5rem;
                }

                .caption {
                    position: absolute;
                    top: 35px;
                    left: 35px;

                    .play-icon {
                        width: 42px;
                        height: 42px;
                        border-radius: 42px;
                        padding: 0;
                        background: none;
                        animation: rippleprive 0.7s linear infinite;

                        &:hover {
                            .icon {
                                border-radius: 42px;
                                fill: $secondary-color;
                                background: $white;
                            }
                        }
                    }

                    .icon {
                        width: 42px;
                        fill: $white;
                    }

                    p {
                        font-size: 1.375rem;
                        font-weight: 600;
                        margin-top: 0.95rem;
                        color: $white;
                        max-width: 160px;
                        line-height: 1.2;
                    }
                }

                img {
                    width: 100%;
                }
            }
        }

        .faq-right {

            .download-btns {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 1rem;
                max-width: 330px;
                margin-top: 1rem;

                @media screen and (max-width:1280px) {
                    grid-template-columns: repeat(3, 1fr);
                }

                @media screen and (max-width:991px) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @media screen and (max-width:400px) {
                    grid-gap: 0.5rem;
                }

                .ant-btn {
                    border: 1px solid #718092;
                    height: 50px;
                    line-height: 50px;
                    padding: 0 15px;
                    font-size: 1rem;
                    display: flex;
                    align-items: center;
                    border-radius: 9px;
                    color: $primary-color;
                    transition: all ease-in 0.1s;
                    background: none;
                    font-weight: normal;

                    .prive-mode & {
                        color: $white;
                    }

                    @media screen and (max-width:767px) {
                        font-size: 0.875rem;
                        padding: 0 8px 0 12px;
                    }

                    @media screen and (max-width:400px) {
                        font-size: 0.8rem;
                    }

                    .icon {
                        fill: $primary-color;
                        height: 25px;
                        width: 25px;
                        margin-right: 10px;
                        transition: all ease-in 0.1s;

                        .prive-mode & {
                            fill: $white;
                        }

                        @media screen and (max-width:767px) {
                            width: 20px;
                            height: 20px;
                            margin-right: 6px;
                        }

                        @media screen and (max-width:400px) {
                            width: 16px;
                            height: 16px;
                            margin-right: 4px;
                        }
                    }

                    &:hover {
                        background: $primary-color;
                        border-color: $primary-color;
                        color: $white;
                        transform: translateY(-4px);

                        .prive-mode & {
                            border-color: #718092;
                        }

                        .icon {
                            fill: $white;
                        }
                    }
                }
            }

            .ant-collapse {
                background: none;
                border: 0;

                .ant-collapse-item {
                    border: 0;
                    margin-bottom: 0.4rem;

                    .ant-collapse-header {
                        background: none;
                        padding: 15px 0;
                        border: 0;
                        font-size: 1.25rem;
                        display: flex;
                        flex-direction: row-reverse;
                        border-bottom: 1px solid gainsboro;
                        border-radius: 0px !important;
                        .prive-mode & {
                            color: $white;
                        }

                        &:hover {
                            color: $secondary-color;
                        }

                        .ant-collapse-expand-icon {
                            height: 30px;
                            display: flex;
                            align-items: center;

                            .icon {
                                width: 16px;
                                height: 16px;

                                .prive-mode & {
                                    fill: $white;
                                }
                            }
                        }
                        .downArrow {
                            transition: .3s;
                            transform: rotate(0);
                        }
                    }

                    &.ant-collapse-item-active {
                        .ant-collapse-header {
                            font-weight: 600;
                        }
                        .downArrow {
                            transform: rotate(90deg);
                            transition: .3s;
                        }
                    }
                    .ant-collapse-content-box {
                        padding: 15px 0 30px 0 !important;
                    }

                    .ant-collapse-content {
                        border: 0;

                        .prive-mode & {
                            background: none;
                            color: $white;
                        }

                        p {
                            font-size: 1rem;
                        }

                        .ant-collapse-content-box {
                            padding: 0 0 0 58px;
                        }
                    }
                }
            }
        }
    }

    .download-section {
        padding: 6rem 0 6rem 0;
        position: relative;
        overflow: hidden;

        @media screen and (max-width:767px) {
            padding: 4rem 0 2rem 0;
        }

        .download-card {
            width: 100%;
            background-size: cover;
            position: relative;
            min-height: 600px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2rem;

            @media screen and (max-width:767px) {
                padding: 2rem 1rem;
            }

            .bg-images-row {
                position: absolute;
                top: 0;
                border-radius: 20px;
                height: 100%;
            }

            .download-card-iner {
                .phone-icon {
                    fill: $white;
                    position: relative;
                    width: 24px;
                    margin-bottom: 1.5rem;
                }

                h1 {
                    font-size: 3.125rem;
                    color: $white;
                    margin-bottom: 1rem;

                    @media screen and (max-width:767px) {
                        font-size: 2rem;
                    }
                }

                p {
                    font-size: 1.25rem;
                    font-weight: normal;
                    max-width: 400px;
                    margin: auto;
                    line-height: 1.4;

                    @media screen and (max-width:767px) {
                        font-size: 1rem;
                    }
                }

                .download-btns {
                    display: flex;
                    grid-gap: 1.5rem;
                    margin-top: 4rem;
                    justify-content: center;
                    flex-wrap: wrap;

                    .ant-btn {
                        border: 1px solid #FFFFFF57;
                        height: 50px;
                        line-height: 50px;
                        padding: 0 18px;
                        color: $white;
                        font-size: 1rem;
                        display: flex;
                        align-items: center;
                        border-radius: 9px;
                        transition: all ease-in 0.1s;
                        background: none;
                        font-weight: normal;

                        @media screen and (max-width:767px) {
                            font-size: 0.875rem;
                            padding: 0 8px 0 12px;
                            width: 40%;
                            justify-content: center;
                        }

                        @media screen and (max-width:767px) {
                            width: 42%;
                        }

                        @media screen and (max-width:400px) {
                            width: 80%;
                        }

                        .icon {
                            fill: $white;
                            height: 25px;
                            width: 25px;
                            margin-right: 10px;
                            transition: all ease-in 0.1s;

                            @media screen and (max-width:767px) {
                                width: 20px;
                                height: 20px;
                                margin-right: 6px;
                            }
                        }

                        &:hover {
                            background: $primary-color;
                            border-color: $primary-color;
                            color: $white;
                            transform: translateY(-4px);

                            .icon {
                                fill: $white;
                            }
                        }
                    }
                }
            }

            .bg-images {
                object-fit: cover;
                height: 100%;
                width: 100%;
                border-radius: 20px;
            }

        }

        .row {
            justify-content: space-between;
            align-items: center;
            min-height: 55vh;

            .download-left {
                max-width: 460px;
                position: relative;
                z-index: 2;

            }

        }
    }
}

.prive-wrapper {
    main {
        overflow-x: hidden;
    }
}

@keyframes ripple2 {
    0% {
        box-shadow: 0 0 0 0 rgba($white, 0.3),
            0 0 0 0.5em rgba($white, 0.3),
            0 0 0 1em rgba($white, 0.3);
    }

    100% {
        box-shadow: 0 0 0 0.5em rgba($white, 0.3),
            0 0 0 1em rgba($white, 0.3),
            0 0 0 2em rgba($white, 0);
    }
}

@keyframes rippleprive {
    0% {
        box-shadow: 0 0 0 0 rgba($white, 0.3),
            0 0 0 0.55em rgba($white, 0.3),
            0 0 0 1.1em rgba($white, 0.3),
            0 0 0 1.6em rgba($white, 0.3);
    }

    100% {
        border-radius: 100%;
        box-shadow: 0 0 0 0.5em rgba($white, 0.3),
            0 0 0 1em rgba($white, 0.3),
            0 0 0 1.5em rgba($white, 0.3),
            0 0 0 2.5em rgba($white, 0);
    }
}