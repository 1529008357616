//Core Variables
@import "./variables.scss";

//Grid System and some useful utilities
@import "./plugins/grid/grid.scss";

//Utilities
@import "./utilities/fonts/fonts.scss";

@import "./custom.scss";

@import 'node_modules/react-modal-video/scss/modal-video.scss';

.ant-btn-link {
  span {
    color: $primary-color !important;
    transition: .2s;

    &:hover {
      color: $secondary-color !important;
      transition: .2s;
    }
  }
}