@import '../../../assets/scss/variables.scss';

.getlistedform-right{
    .offers-list {
        .ant-radio-group{
            .ant-radio-button-wrapper {
                border: 0;
                height: auto;
                @media screen and (max-width:1100px) {
                  padding: 15px 12px;
                }
                &::before {
                    display: none;
                }
                .ctg-list {
                    .ctg-img {
                        width: 136px;
                        height: 136px;
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid $border-primary;
                        margin-bottom: 15px;
                        transition: all ease-in 0.1s;
                        overflow: hidden;
                        padding: 10px;
                        position: relative;
                        @media screen and (max-width:1300px) {
                            max-width: 100px;
                            max-height: 100px;
                        }
                        @media screen and (max-width:1100px) {
                            width: 80px;
                            height: 80px;
                            border-radius: 7px;
                            margin-left: auto;
                            margin-right: auto;
                        }
                        @media screen and (max-width:676px) {
                            width: 60px;
                            height: 60px;
                            border-radius: 7px;
                        }
                        img {
                            max-width: 100%;
                        }
                    }
                    h4 {
                        font-weight: 500;
                        margin-bottom: 0;
                        @media screen and (max-width:1100px) {
                        font-size: 0.8rem;
                        }
                    }
                }
                &.ant-radio-button-wrapper-checked {
                    .ctg-list {
                        .ctg-img {
                            box-shadow: 0px 23px 36px #00000010;
                            border-color: $white;
                        }
                    }
                }
            }
        }
        .ant-checkbox-group{
            .ant-checkbox-wrapper {
                border: 0;
                height: auto;
                @media screen and (max-width:1100px) {
                  padding: 15px 12px;
                }
                &::before {
                    display: none;
                }
                .ant-checkbox {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    opacity: 0;
                }
                .ctg-list {
                    .ctg-img {
                        width: 136px;
                        height: 136px;
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid $border-primary;
                        margin-bottom: 15px;
                        transition: all ease-in 0.1s;
                        overflow: hidden;
                        padding: 10px;
                        position: relative;
                        @media screen and (max-width:1300px) {
                            max-width: 100px;
                            max-height: 100px;
                        }
                        @media screen and (max-width:1100px) {
                            width: 80px;
                            height: 80px;
                            border-radius: 7px;
                            margin-left: auto;
                            margin-right: auto;
                        }
                        @media screen and (max-width:676px) {
                            width: 60px;
                            height: 60px;
                            border-radius: 7px;
                        }
                        img {
                            max-width: 100%;
                        }
                    }
                    h4 {
                        font-weight: 600;
                        margin-bottom: 0;
                        font-size: 16px;
                        @media screen and (max-width:1100px) {
                        font-size: 0.8rem;
                        }
                    }
                }
                &.ant-checkbox-wrapper-checked {
                    .ctg-list {
                        .ctg-img {
                            box-shadow: 0px 23px 36px #00000010;
                            border-color: $white;
                        }
                    }
                }
            }
        }
    }
}