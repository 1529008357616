@import '../../assets/scss/variables.scss';

.communitypage-wrapper {
    padding-top: 4rem;
    .communitypage-banner {
        padding: 5rem 0;
        .col-left {
            h1 {
                font-size: 4.5rem;
                margin-bottom: 2rem;
                @media screen and (max-width:1200px) {
                    font-size: 4rem;
                }
                @media screen and (max-width:1024px) {
                    font-size: 3rem;
                }
                @media screen and (max-width:480px) {
                    font-size: 2.2rem;
                }
            }

            ul {
                list-style-type: none;
                padding-left: 0;
                display: flex;
                width: 100%;

                li {
                    &:first-child {
                        margin-right: 10%;
                    }
                    h3 {
                        font-size: 3rem;
                        font-weight: 600;
                        margin-bottom: 0;
                    }

                    p {
                        font-size: 1.3rem;
                    }
                }
            }
        }
        .col-right {
            .colright-inner {
                max-width: 420px;
                margin-left: auto;
                position: relative;
                @media screen and (max-width:767px) {
                    margin: 4rem auto 0;
                }
                .banner-img {
                    width: 100%;
                }
                .circle-right {
                    width: 144px;
                    height: 144px;
                    border-radius: 144px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    box-shadow: 0px 3px 36px #00000029;
                    position: absolute;
                    z-index: 2;
                    background: $white;
                    top: 60%;
                    left: -5%;
                    transform: translateY(-50%);
                    @media screen and (max-width:767px) {
                        width: 120px;
                        height: 120px;
                        border-radius: 120px;
                    }
                    img {
                        width: 26px;
                        margin-bottom: 5px;
                    }
                    h4 {
                        margin-bottom: 4px;
                        font-size: 1.4rem;
                    }
                    p {
                        margin-bottom: 0;
                        font-size: 0.85rem;
                    }
                }
            }
        }
    }
    .topcreater-section {
        .topcreator-list {
            list-style-type: none;
            padding-left: 0;
            li {
                display: inline-block;
                width: 60px;
                height: 60px;
                border-radius: 60px;
                margin-right: 1.5rem;
                overflow: hidden;
                @media screen and (max-width:1024px) {
                    margin-right: 0.8rem;
                    width: 50px;
                    height: 50px;
                    border-radius: 50px;
                }
                @media screen and (max-width:767px) {
                    margin-right: 0.8rem;
                    width: 40px;
                    height: 40px;
                    border-radius: 40px;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .topcreator-filter {
            list-style-type: none;
            padding-left: 0;
            margin-top: 2rem;
            li {
                display: inline-block;
                margin-right: 0.8rem;
                border: 1px solid $border-primary;
                font-size: 13px;
                padding: 8px 16px;
                border-radius: 30px;
                cursor: pointer;
                transition: all ease-in 0.1s;
                margin-bottom: 0.6rem;
                &:hover {
                    color: $secondary-color;
                    border-color: $secondary-color;
                }
            }
        }
    }
    .communitylist-section {
        .community-list {
            list-style-type: none;
            padding-left: 0;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 1.5rem;
            margin-top: 12px;
            @media screen and (max-width:1024px) {
                grid-template-columns: repeat(3, 1fr);
            }
            @media screen and (max-width:640px) {
                grid-template-columns: repeat(2, 1fr);
            }
            @media screen and (max-width:576px) {
                grid-template-columns: repeat(1, 1fr);
            }
            li {
                margin-bottom: 1rem;
                .image-upper {
                    max-height: 320px;
                    overflow: hidden;
                    border-radius: 10px;
                    margin-bottom: 20px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .bottom-content {
                    .creator-img {
                        width: 25px;
                        height: 25px;
                        border-radius: 25px;
                        img {
                            width: 100%;
                        }
                    }
                    h3 {
                        font-weight: 500;
                        margin: 12px 0;
                        font-size: 17px;
                        max-width: 250px;
                    }
                    .view-row {
                        display: flex;
                        align-items: center;
                        color: $label-color;
                        font-size: 0.8rem;
                        .icon {
                            width: 6px;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}