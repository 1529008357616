@import '../../../assets/scss/variables.scss';

.category-listing-slider {
  margin-top: 10px;
  transition: all ease-in 0.1s;
  width: 100%;

  @media screen and (min-width:768px) {
    width: calc(100% - 90px);
  }

  @media screen and (max-width:991px) {
    margin-top: 15px;
  }

  .slick-arrow {
    color: $primary-color;
    font-size: 1.25rem;
    z-index: 2;
    position: absolute;
    opacity: 1;
    border: 0;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    background: $white;
    box-shadow: 0 3px 6px rgba($black, 0.09);

    @media screen and (max-width:767px) {
      display: none;
    }

    &:hover {
      border-color: $secondary-color;

      .icon {
        fill: $secondary-color;
      }
    }

    .icon {
      fill: $primary-color;
      width: 6px;
    }

    &::before {
      display: none;
    }

    &.slick-next {
      bottom: auto;
      top: 40%;
      right: 15px;
      transform: translateY(-50%);

      @media screen and (max-width:767px) {
        right: -10px;
      }
    }

    &.slick-prev {
      top: 40%;
      bottom: auto;
      right: auto;
      left: -5px;
      transform: translateY(-50%);
    }

    &.slick-disabled {
      opacity: 0.5;
      cursor: inherit;
      pointer-events: none;
    }
  }

  .slick-list {
    .slick-slide {
      padding: 20px 30px 0 0;
      text-align: center;

      @media screen and (max-width:768px) {
        padding: 8px;
      }
    }
  }

  .slider-col {
    p {
      font-size: 15px;

      @media screen and (max-width:768px) {
        font-size: 13px;
        margin-bottom: 0;
      }
    }

    &.selected {
      p {
        font-weight: 600;
        position: relative;
      }
    }

    .list-slider-icon {
      margin-bottom: 12px;
      cursor: pointer;

      .sub-cat-icon{
        height: 45px;
        width: 45px;
        filter: invert(1);
        margin: auto;

        .prive-mode & {
          filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
        }
        
        @media screen and (max-width:768px) {
          height: 40px;
          width: 40px;
        }
      }
      .icon {
        fill: $primary-color;
        height: 38px;
        width: 45px;

        @media screen and (max-width:768px) {
          height: 34px;
          width: 46px;
        }
      }
    }

    &:hover,&.active {
      .sub-cat-icon {
        filter: invert(49%) sepia(67%) saturate(583%) hue-rotate(328deg) brightness(105%) contrast(101%);

        .prive-mode & {
          filter: brightness(0) saturate(100%) invert(46%) sepia(94%) saturate(361%) hue-rotate(7deg) brightness(97%) contrast(87%);
        }
      }
      .icon {
        fill: $secondary-color;
      }

      p {
        color: $secondary-color;
        .prive-mode & {
          color: $prive-color;
        }
      }
    }
  }
}
