@import '../../assets/scss/variables.scss';


.get-listed-wrapper {

    .small-title {
        letter-spacing: 1.5px;
        font-size: 13px;
        margin-bottom: 8px;
        display: block;
    }

    .blog-section {
        &.blog-section-getlisted {
            padding: 3rem 0 5rem 0;
        }
    }

    .testomonial-section {
        &.testomonial-getlisted {
            padding: 6rem 0 2rem 0;
        }
    }

    .topbrand-section {
        &.topbrand-section-getlisted {
            padding: 5rem 0;
        }
    }


    .explore-section {
        &.explore-getlisted-section {
            padding: 12rem 0 8rem 0;
        }
    }

    .get-listed-banner {
        padding: 8rem 0;

        .row {
            align-items: center;
        }

        .banner-left {
            h1 {
                font-size: 4.5rem;
                margin-bottom: 15px;
                @media screen and (max-width:1100px) {
                    font-size: 3.5rem;
                }
            }

            p {
                font-size: 1.9rem;
                max-width: 360px;
                margin-bottom: 2.5rem;
            }

            .ant-btn {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                .icon {
                    margin-left: 15px;
                    width: 13px;
                }
            }
        }

        .banner-right {
            position: relative;

            @media screen and (max-width:767px) {
                margin-top: 2.5rem;
            }

            .card-right {
                position: absolute;
                left: 65px;
                bottom: 50px;
                border-radius: 0;
                background: $white;
                padding: 30px 22px 25px;
                width: 100%;
                max-width: 248px;
                z-index: 2;

                .card-upper {
                    .ant-btn {
                        background: none;
                        padding: 0;
                        margin-right: 15px;

                        .icon {
                            fill: $secondary-color;
                            width: 30px;
                        }
                    }

                    .arrow-icon {
                        width: 50px;
                    }
                }

                .card-bottom {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 0.5rem;

                    p {
                        font-size: 1.3rem;
                        margin-bottom: 0;
                        font-weight: 600;
                    }

                    .icon {
                        width: 44px;
                    }
                }
            }

            .banner-img {
                width: 100%;
                max-height: 555px;
                object-fit: cover;
            }
        }
    }

    .reachout-section {
        padding: 2.5rem 0 9rem 0;

        @media screen and (max-width:767px) {
            padding: 3rem 0;
        }

        .heading {
            font-size: 3.125rem;
            font-weight: 600;
            margin: 0 auto 3.8rem auto;
        }

        .reachout-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 5rem 3rem;
            list-style-type: none;
            padding-left: 0;
            max-width: 900px;

            @media screen and (max-width:767px) {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 2rem 1rem;
            }

            @media screen and (max-width:600px) {
                display: block;
            }

            li {
                @media screen and (max-width:600px) {
                    margin-bottom: 3rem;
                }

                .reachout-list-card {
                    width: 100%;

                    .reachout-list-card-image {
                        transition: all ease-in 0.1s;
                        margin-bottom: 2em;

                        @media screen and (max-width:767px) {
                            border-radius: 12px;
                        }

                        .icon {
                            width: 70px;
                            fill: $primary-color;
                        }
                    }

                    .reachout-caption {
                        margin-top: 1rem;

                        p {
                            margin-bottom: 0;
                            font-size: 1.2rem;
                            max-width: 350px;
                            line-height: 1.6;
                        }

                        h3 {
                            font-size: 1.8rem;
                            font-weight: 600;
                            margin-top: 1rem;
                            margin-bottom: 1rem;
                        }

                        button {
                            margin-top: 2.5rem;
                            text-align: left;
                            padding: 0;
                            height: auto;
                            line-height: auto;
                            background: none;
                            font-weight: normal;
                            transition: all ease-in 0.1s;
                            box-shadow: none;
                            outline: none;

                            &::after {
                                display: none;
                            }

                            .icon {
                                margin-left: 6px;
                                width: 20px;
                                transition: all ease-in 0.1s;
                            }

                            &:hover,
                            &:focus {
                                background: none;
                                color: $secondary-color;
                                box-shadow: none;
                                outline: none;

                                .icon {
                                    fill: $secondary-color;
                                    transform: translateX(7px);
                                }
                            }
                        }
                    }
                }

                .ant-card {
                    max-width: 320px;
                    position: relative;
                    box-shadow: 0 13px 46px rgba(0, 0, 0, 0.24);
                    border: 0;

                    @media screen and (max-width:600px) {
                        max-width: 100%;
                    }

                    .ant-card-cover {
                        img {
                            width: 100%;
                        }
                    }

                    .meta-row {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%;
                        color: $white;

                        p {
                            font-size: 24px;
                            font-weight: 500;
                            line-height: 1.3;
                        }

                        h3 {
                            font-size: 40px;
                            font-weight: 600;
                            color: $white;
                            margin-bottom: 0;
                        }

                        small {
                            font-size: 1rem;
                        }

                        .icon {
                            fill: $white;
                            width: 23px;
                            margin-bottom: 5px;
                        }
                    }

                    .ant-card-body {
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        top: 0;
                        left: 0;
                        padding: 30px;
                    }
                }
            }
        }
    }

    .dashboardr-section {
        background: rgb(216, 244, 241);
        background: -moz-linear-gradient(224deg, rgba(216, 244, 241, 1) 0%, rgba(245, 233, 225, 1) 97%, rgba(255, 229, 219, 1) 100%);
        background: -webkit-linear-gradient(224deg, rgba(216, 244, 241, 1) 0%, rgba(245, 233, 225, 1) 97%, rgba(255, 229, 219, 1) 100%);
        background: linear-gradient(224deg, rgba(216, 244, 241, 1) 0%, rgba(245, 233, 225, 1) 97%, rgba(255, 229, 219, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d8f4f1", endColorstr="#ffe5db", GradientType=1);
        padding: 25rem 0 8rem 0;
        position: relative;
        overflow: hidden;

        @media screen and (max-width:767px) {
            padding: 4rem 0 2rem 0;
        }

        .dashboardr-line {
            position: absolute;
            right: 0;
            top: 0;
            height: 103%;
        }

        .row {
            justify-content: space-between;
            align-items: center;
            min-height: 55vh;

            .dashboardr-left {
                max-width: 460px;
                position: relative;
                z-index: 2;

                .ant-btn {
                    display: flex;
                    align-items: center;
                    flex-direction: row-reverse;
                    .icon {
                        margin-left: 15px;
                        width: 13px;
                    }
                }

                h1 {
                    font-size: 3.125rem;
                }

                p {
                    font-size: 1.25rem;
                    line-height: 1.5;
                }

                .dashboardr-btns {
                    margin-top: 2.5rem;
                }
            }

            .dashboardr-right {
                .right-inner {
                    position: relative;
                    max-width: 460px;
                    width: 100%;
                    margin-left: auto;
                    text-align: center;

                    @media screen and (max-width:991px) {
                        margin: auto;
                        padding-top: 2.5rem;
                    }

                    .dashboardr-circle {
                        width: 500px;
                        height: 500px;
                        border-radius: 500px;
                        display: inline-block;
                        background: transparent linear-gradient(180deg, #FF7541 0%, #FFFFFF41 100%);
                        z-index: 0;
                        position: relative;

                        @media screen and (max-width:991px) {
                            left: 0;
                            right: 0;
                            margin: auto;
                        }

                        @media screen and (max-width:767px) {
                            width: 400px;
                            height: 400px;
                        }

                        @media screen and (max-width:640px) {
                            width: 330px;
                            height: 330px;
                        }
                    }

                    .dashboardr-img-div {
                        z-index: 2;
                        position: absolute;
                        right: -40%;
                        margin: auto;
                        z-index: 3;

                        @media screen and (max-width:991px) {
                            width: 100%;
                            left: -12%;
                            right: auto;
                        }

                        &.web {
                            top: 10%;

                            @media screen and (max-width:991px) {
                                display: none;
                            }
                        }

                        &.mobile {
                            display: none;
                            top: 15%;

                            @media screen and (max-width:991px) {
                                display: block;
                                top: 20%;
                            }
                        }
                    }

                    .dashboardr-img {
                        max-height: 480px;

                        @media screen and (max-width:1100px) {
                            max-height: 480px;
                        }

                        @media screen and (max-width:991px) {
                            right: 0;
                            max-width: 120%;
                            height: auto;
                            max-height: auto;
                        }
                    }
                }
            }
        }
    }

    .listbusiness-section {
        padding: 3rem 0 0 0;
        margin-bottom: -15rem;
        position: relative;
        z-index: 1;

        @media screen and (max-width:767px) {
            margin-bottom: -3rem;
        }

        .listbusiness-card {
            min-height: 680px;
            width: 100%;
            overflow: hidden;
            border-radius: 10px;

            @media screen and (max-width:767px) {
                min-height: 450px;
            }

            .listbusiness-image {
                object-fit: cover;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }

            .listbusiness-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgb(0, 0, 0);
                background: -moz-linear-gradient(261deg, rgba(0, 0, 0, 0.5225665266106442) 0%, rgba(0, 0, 0, 0.09287464985994398) 100%);
                background: -webkit-linear-gradient(261deg, rgba(0, 0, 0, 0.5225665266106442) 0%, rgba(0, 0, 0, 0.09287464985994398) 100%);
                background: linear-gradient(261deg, rgba(0, 0, 0, 0.5225665266106442) 0%, rgba(0, 0, 0, 0.09287464985994398) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                h2 {
                    font-size: 3rem;
                    color: $white;
                    font-weight: 600;
                    @media screen and (max-width:767px) {
                        font-size: 2rem;
                    }
                }

                .ant-btn {
                    display: flex;
                    justify-content: center;
                    margin: auto;
                    align-items: center;
                    flex-direction: row-reverse;
                    .icon {
                        margin-left: 15px;
                        width: 13px;
                    }
                }
            }
        }
    }

    .about-getlisted {
        padding: 6.5rem 0;
        background: $secondary-color;
        color: $white;

        .aboutgetlisted-row {
            display: flex;
            justify-content: space-between;
            padding-right: 490px;

            @media screen and (max-width:1200px) {
                padding-right: 420px;
            }

            @media screen and (max-width:800px) {
                flex-direction: column;
                padding: 0;
            }

            .aboutgetlisted-left {
                max-width: 640px;

                h2 {
                    font-size: 3rem;
                    line-height: 1.4;
                    color: $white;
                    font-weight: 600;

                    @media screen and (max-width:1200px) {
                        font-size: 2rem;
                    }
                }

                p {
                    font-size: 1.4rem;
                    line-height: 1.4;
                    font-weight: 400;
                }
            }

            .aboutgetlisted-right {
                position: absolute;
                max-width: 470px;
                border-radius: 10px;
                width: 100%;
                overflow: hidden;
                right: 0;
                background: $black;

                @media screen and (max-width:1200px) {
                    max-width: 400px;
                }

                @media screen and (max-width:800px) {
                    position: relative;
                    margin-top: 2rem;
                }

                .abuot-img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    opacity: 0.7;
                }

                .aboutgetlisted-right-inner {
                    position: relative;
                    z-index: 1;
                    background: rgba($black, 0.25);
                    padding: 3rem 3.5rem;

                    @media screen and (max-width:640px) {
                        padding: 2rem 2rem;
                    }

                    img {
                        width: 40px;
                        margin-bottom: 1rem;
                    }

                    h3 {
                        font-size: 1.9rem;
                        line-height: 1.4;
                        color: $white;
                        font-weight: 600;
                        max-width: 220px;
                        margin-bottom: 0;
                    }

                    ul {
                        list-style-type: none;
                        padding-left: 0;
                        margin: 0;
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);

                        li {
                            display: inline-block;
                            padding-top: 2.4rem;

                            @media screen and (max-width:1200px) {
                                padding-top: 1rem;
                            }

                            .ds-icon {
                                width: 50px;
                                height: 50px;
                                border-radius: 10px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 1px solid $white;
                                margin-bottom: 10px;

                                .icon {
                                    width: 18px;
                                    height: 18px;
                                    fill: $white;
                                }
                            }

                            p {
                                color: $white;
                                font-size: 0.9rem;
                                max-width: 150px;
                                line-height: 1.5;
                                font-weight: 400;
                                min-height: 40px;
                            }
                        }
                    }
                }
            }
        }
    }
}