@import "../../../assets/scss/variables.scss";


.unexpected-error-card-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4.5rem;

  @media screen and (max-width: 767px) {
    padding-top: 1rem;
  }

  div:first-child {
    max-width: 400px;
    text-align: center;
    color: $primary-color;

    h2 {
      font-size: 1.95rem;
      margin-bottom: 1.2rem;
      font-weight: 600;
    }

    p {
      line-height: 1.5;
      white-space: pre-line;
      text-align: center;
    }

    img {
      cursor: pointer;
      max-width: 220px;
    }

    button {
      margin-top: 2.5rem;
      height: 48px;
      min-width: 180px;
    }
  }
}