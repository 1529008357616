@import "../../../assets/scss/variables.scss";

.search-category-dropdown {
  min-width: 250px !important;
  padding: 15px 15px;

/*  &.in-navbar{
    top: 142px !important;

    @media screen and (max-width: 767px) {
      top: 80px !important;
    }
  }
  */
  .drop-title {
    font-size: 18px;
    font-weight: 600;
    color: $primary-color;
    margin-bottom: 5px;
    padding: 0 15px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  .ant-select-item {

    &.ant-select-item-option-selected {
      font-weight: 400;
      background-color: transparent !important;
    }

    .ant-select-item-option-content {
      padding-top: 4px;
      padding-bottom: 4px;
      display: flex;
      align-items: center;
      font-size: 16px;

      @media screen and (max-width: 767px) {
        font-size: 14px;
      }

      .category-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 9px;
        margin-right: 12px;
        overflow: hidden;

        @media screen and (max-width: 767px) {
          width: 36px;
          height: 36px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}