@import "../../../../assets/scss/variables.scss";

.membershipid-modal {
    @media screen {
        max-width: calc(100vw - 46px);
    }
    .ant-modal-close{
        right: 15px;
        top: 15px;
        .ant-modal-close-x{
            width: 30px;
            height: 30px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $body-background-light;
            .icon{
                fill: $primary-color;
                width: 10px;
            }
            &:hover{
                background: $secondary-color;
                .icon{
                    fill: $white;
                }
            }
        }
    }
    .ant-modal-body{
        padding: 3rem 1.2rem;
    }
    .upper-section {
        .icon {
            width: 174px;
        }

        h3 {
            font-size: 1.875rem;
            font-weight: 600;
            margin-bottom: 5px;
        }

        p {
            font-size: 1.25rem;
            font-weight: normal;
        }
    }

    .id-input {
        margin-top: 0.5rem;

        .ant-input {
            height: 48px;
            line-height: 48px;
            border-color: $primary-color;
            border-radius: 6px;
            font-size: 1rem;
            text-align: center;

            &::placeholder {
                color: $primary-color;
                font-weight: 600;
            }
        }
    }

    .congratulations-row {
        padding-top: 2rem;
        h4 {
            font-size: 1.25rem;
            color: $primary-color;
            line-height: 1;
            margin-bottom: 4px;
        }

        h2 {
            color: $secondary-color;
            font-size: 1.875rem;
            font-weight: bold;
        }

        .eligible-text {
            margin-top: 1rem;
            margin-bottom: 1rem;

            p {
                font-size: 1rem;
                color: $primary-color;
                font-weight: normal;
                margin-bottom: 0;
            }
        }

        .activated-text{
            color: $primary-color;
            p{
                font-size: 0.875rem;
                font-weight: normal;
                margin-bottom: 0;
                b{
                    font-weight: 600;
                    font-size: 1rem;
                }
            }
        }

        &.hide {
            display: none;
        }

        &.show {
            display: block;
        }
    }
}