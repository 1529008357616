@import "../../../assets/scss/variables.scss";

.tp-item-map-card {
  background: $white;
  box-shadow: 0px 13px 36px #0000000A;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  border: 1px solid $white;
  position: relative;
  cursor: pointer;
  transition: all ease 0.1s;

  &:hover {
    border-color: $secondary-color;
  }

  .card-img {
    width: 105px;
    height: 105px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 15px;

    img {
      width: 100%;
    }
  }

  .map-card-right {
    display: flex;
    flex-direction: column;

    .upper-col {
      h3 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      p {
        font-size: 14px;
        color: $label-color;
        display: flex;
        margin-bottom: 0;

        .icon {
          width: 9px;
          fill: $primary-color;
          margin-right: 10px;
        }
      }
    }

    .bottom-col {
      margin-top: auto;
      padding-bottom: 5px;
      font-size: 14px;
      font-weight: 600;
    }

    .ant-btn {
      position: absolute;
      right: 15px;
      top: 15px;
      border: 1px solid $border-primary;
      padding: 0;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        fill: $primary-color;
        width: 10.50px;
      }

      &:hover {
        border-color: $primary-color;

        .icon {
          fill: $white;
        }
      }
    }
  }
}