@import '../../../assets/scss/variables.scss';

.buymembershiphome-wrapper {
    @media screen and (max-width:767px) {
        padding-top: 1rem;
    }

    .banner-section {
        .banner-row {
            align-items: center;
            min-height: 780px;

            @media screen and (max-width:767px) {
               flex-direction: column-reverse;
            }
            
            .banner-left {
                @media screen and (max-width:767px) {
                    margin-top: 2rem;
                }
                h1 {
                    margin-bottom: 2rem;
                    @media screen and (max-width:767px) {
                        font-size: 3rem;
                    }
                }

                ul {
                    list-style-type: none;
                    padding-left: 0;
                    margin-bottom: 3rem;

                    li {
                        font-size: 1.875rem;
                        display: flex;
                        align-items: center;
                        padding: 0.6rem 0;

                        @media screen and (max-width:991px) {
                            font-size: 1.5rem;
                        }

                        .icon {
                            width: 30px;
                            margin-right: 1.4rem;
                        }
                    }
                }

                .bottom-info {
                    display: flex;
                    align-items: center;
                    max-width: 380px;
                    font-style: italic;

                    img {
                        max-width: 50px;
                        margin-right: 1rem;
                    }

                    p {
                        font-size: 1rem;
                        margin-bottom: 0;
                        line-height: 1.3;
                    }
                }
            }

            .banner-right {
                position: relative;
                width: 100%;
                margin-top: 3rem;

                .right-img {
                    max-width: 417px;
                    width: 100%;
                    border-radius: 10px;
                }

                .arrow {
                    position: absolute;
                    max-width: 140px;
                    width: 100%;
                    left: 53%;
                    top: -15%;
                    z-index: 1;
                    animation: MoveUpDown 2s linear infinite;
                    fill: $primary-color;
                }

                .subscriptions-card {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    max-width: 382px;
                    width: 100%;
                    transform: translateY(-50%);

                    .subscriptions-card-inner {
                        background: $white;
                        padding: 2rem 2.5rem 1.5rem 2.5rem;
                        box-shadow: 0 17px 30px rgba(0, 0, 0, 0.03);
                        width: 100%;
                        @media screen and (max-width:991px) {
                            width: 90%;
                            margin: auto;
                            padding: 2rem 1.5rem 1.5rem 1.5rem;
                        }
                    }

                    .upper-header{
                        display: inline-flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        margin-bottom: 1rem;
                        .ant-radio-group{
                            background: #F5FCFC;
                            height: 30px;
                            border-radius: 30px;
                            display: flex;
                            align-items: center;
                            padding: 0 5px;
                            .ant-radio-button-wrapper{
                                border: 0;
                                height: 26px;
                                line-height: 26px;
                                border-radius: 30px;
                                width: 50%;
                                min-width: 50px;
                                text-transform: uppercase;
                                font-weight: normal;
                                text-align: center;
                                background: none;
                                box-shadow: none;
                                outline: none;
                                &:hover{
                                    color: $secondary-color;
                                }
                                &::before{
                                    display: none;
                                }
                                .ant-radio-button{
                                    box-shadow: none;
                                    outline: none;
                                }
                                &.ant-radio-button-wrapper-checked{
                                    background: #FFFFFF;
                                    color: $primary-color;
                                    font-weight: 600;
                                }
                            }
                        }
                        .need-help-icon{
                            display: flex;
                            align-items: center;
                            color: $primary-color;
                            text-decoration: underline;
                            .icon{
                                margin-right: 4px;
                            }
                        }
                    }

                    .header {
                        text-transform: uppercase;
                        font-size: 0.875rem;
                    }
                    

                    .ant-btn-link {
                        font-weight: normal;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 1rem;
                        &:hover {
                            color: $secondary-color;
                        }
                        .icon {
                            width: 24px;
                            margin-right: 6px;
                        }

                        &.text-decoration {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .statics-section {
        padding: 9rem 0;
        @media screen and (max-width:991px) {
            padding: 3rem 0;
        }

        .statics-row {
            display: flex;
            width: 100%;
            align-items: center;

            @media screen and (max-width:1300px) {
                display: block;
            }

            h3 {
                font-size: 1.875rem;
                font-style: italic;
                max-width: 280px;
                line-height: 1.35;

                @media screen and (max-width:1300px) {
                    display: block;
                    max-width: 100%;
                    margin-top: 2rem;
                    text-align: center;
                }
            }

            .card-list {
                display: grid;
                grid-gap: 4rem;
                width: 100%;
                grid-template-columns: repeat(3, 1fr);
                margin-right: 3rem;

                @media screen and (max-width:1300px) {
                    grid-gap: 2rem;
                }

                @media screen and (max-width:676px) {
                    display: flex;
                    overflow-x: auto;
                    overflow-y: hidden;
                    padding-top: 40px;
                }

                .list-item {
                    border: 1px solid $border-primary;
                    padding: 2rem 2.5rem;
                    border-radius: 10px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    min-height: 300px;

                    @media screen and (max-width:576px) {
                        min-width: 230px;
                    }

                    .card-icon {
                        background: $primary-color;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 50px;
                        height: 50px;
                        border-radius: 10px;
                        position: absolute;
                        top: -25px;
                        transition: all ease-in 0.1s;

                        .icon {
                            fill: $white;
                            width: 23px;
                            height: 18px;
                        }
                    }

                    h2 {
                        font-size: 3.125rem;
                        margin-bottom: 0.6rem;
                        font-weight: 600;
                    }

                    p {
                        font-size: 1.125rem;
                        margin-bottom: 0.5rem;
                    }

                    hr {
                        margin: 1.4rem 0 0 0;
                        max-width: 27px;
                        border-color: $primary-color;
                        border-width: 2px 0 0 0;
                    }
                    &:hover{
                        border-color: $secondary-color;
                        .card-icon {
                            background: $secondary-color;
                        }
                    }
                }
            }
        }
    }

    .download-section {
        background: rgb(216,244,241);
        background: -moz-linear-gradient(138deg, rgba(216,244,241,1) 0%, rgba(245,233,225,1) 91%, rgba(255,229,219,1) 100%);
        background: -webkit-linear-gradient(138deg, rgba(216,244,241,1) 0%, rgba(245,233,225,1) 91%, rgba(255,229,219,1) 100%);
        background: linear-gradient(138deg, rgba(216,244,241,1) 0%, rgba(245,233,225,1) 91%, rgba(255,229,219,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d8f4f1",endColorstr="#ffe5db",GradientType=1);
        padding: 6rem 0 8rem 0;
        position: relative;
        overflow: hidden;

        @media screen and (max-width:767px) {
            padding: 4rem 0 2rem 0;
        }

        .download-line {
            position: absolute;
            right: 0;
            top: 0;
            height: 103%;
        }

        .row {
            justify-content: space-between;
            align-items: center;
            min-height: 55vh;

            .download-left {
                max-width: 460px;
                position: relative;
                z-index: 2;

                h1 {
                    font-size: 3.125rem;
                }

                p {
                    font-size: 1.25rem;
                }

                .download-btns {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 1.5rem;
                    max-width: 330px;
                    margin-top: 4rem;

                    .ant-btn{
                        border: 1px solid #718092;
                        height: 50px;
                        line-height: 50px;
                        padding: 0 15px;
                        color: $white;
                        font-size: 1rem;
                        display: flex;
                        align-items: center;
                        border-radius: 9px;
                        color: $primary-color;
                        transition: all ease-in 0.1s;
                        background: none;
                        font-weight: normal;
                        @media screen and (max-width:767px) {
                            font-size: 0.875rem;
                            padding: 0 8px 0 12px;
                        }
                        .icon{
                            fill: $primary-color;
                            height: 25px;
                            width: 25px;
                            margin-right: 10px;
                            transition: all ease-in 0.1s;
                            @media screen and (max-width:767px) {
                                width: 20px;
                                height: 20px;
                                margin-right: 6px;
                            }
                        }
                        &:hover{
                            background: $primary-color;
                            border-color: $primary-color;
                            color: $white;
                            transform: translateY(-4px);
                            .icon{
                                fill: $white;
                            }
                        }
                    }
                }
            }

            .download-right {
                .right-inner {
                    position: relative;
                    max-width: 460px;
                    width: 100%;
                    margin-left: auto;
                    text-align: center;
                    @media screen and (max-width:991px) {
                        margin: auto;
                        padding-top: 2.5rem;
                    }

                    .download-circle {
                        width: 500px;
                        height: 500px;
                        border-radius: 500px;
                        display: inline-block;
                        background: transparent linear-gradient(180deg, #FF7541 0%, #FFFFFF41 100%);
                        z-index: 0;
                        position: relative;
                        @media screen and (max-width:991px) {
                            left: 0;
                            right: 0;
                            margin: auto;
                        }
                        @media screen and (max-width:767px) {
                            width: 400px;
                            height: 400px;
                        }
                        @media screen and (max-width:640px) {
                            width: 330px;
                            height: 330px;
                        }
                    }

                    .download-img-div{
                        z-index: 2;
                        position: absolute;
                        right: -40%;
                        margin: auto;
                        z-index: 3;
                        @media screen and (max-width:991px) {
                            width: 100%;
                            left: -28%;
                            right: auto;
                        }
                        &.web {
                            top: -10%;
                            @media screen and (max-width:991px) {
                                display: none;
                            }
                        }
                        &.mobile{
                            display: none;
                            top: 0;
                            @media screen and (max-width:991px) {
                                display: block;
                            }
                        }
                    }

                    .download-img {
                        max-height: 780px;

                        @media screen and (max-width:1100px) {
                            max-height: 680px;
                        }

                        @media screen and (max-width:991px) {
                            right: 0;
                            max-width: 150%;
                            height: auto;
                            max-height: auto;
                        }
                    }
                }
            }
        }
    }

    .explore-section {
        padding: 10rem 0;
        @media screen and (max-width:767px) {
            padding: 3rem 0;
        }

        .heading {
            font-size: 3.125rem;
            font-weight: 600;
            margin: 0 auto 3.8rem auto;
        }

        .explore-list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 5rem 3rem;
            list-style-type: none;
            padding-left: 0;

            @media screen and (max-width: 767px) {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 2rem 1rem;
            }

            @media screen and (max-width: 600px) {
                display: block;
            }

            li {
                @media screen and (max-width: 600px) {
                    margin-bottom: 3rem;
                }

                .explore-list-card {
                    width: 100%;

                    .explore-list-card-image {
                        transition: all ease-in 0.1s;
                        margin-bottom: 2em;

                        @media screen and (max-width: 767px) {
                            border-radius: 12px;
                        }

                        .icon {
                            width: 70px;
                            fill: $primary-color;
                        }
                    }

                    .explore-caption {
                        margin-top: 1rem;

                        p {
                            margin-bottom: 0;
                            font-size: 1.2rem;
                            max-width: 350px;
                            line-height: 1.6;
                        }

                        h3 {
                            font-size: 1.8rem;
                            font-weight: 600;
                            margin-top: 1rem;
                            margin-bottom: 1rem;
                        }

                        button {
                            margin-top: 2.5rem;
                            text-align: left;
                            padding: 0;
                            height: auto;
                            line-height: auto;
                            background: none;
                            font-weight: normal;
                            transition: all ease-in 0.1s;
                            box-shadow: none;
                            outline: none;

                            &::after {
                                display: none;
                            }

                            .icon {
                                margin-left: 6px;
                                width: 20px;
                                transition: all ease-in 0.1s;
                            }

                            &:hover,
                            &:focus {
                                background: none;
                                color: $secondary-color;
                                box-shadow: none;
                                outline: none;

                                .icon {
                                    fill: $secondary-color;
                                    transform: translateX(7px);
                                }
                            }
                        }
                    }
                }

                .ant-card {
                    max-width: 320px;
                    position: relative;
                    box-shadow: 0 13px 46px rgba(0, 0, 0, 0.24);
                    border: 0;

                    @media screen and (max-width: 600px) {
                        max-width: 100%;
                    }

                    .ant-card-cover {
                        img {
                            width: 100%;
                        }
                    }

                    .meta-row {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%;
                        color: $white;

                        p {
                            font-size: 24px;
                            font-weight: 500;
                            line-height: 1.3;
                        }

                        h3 {
                            font-size: 40px;
                            font-weight: 600;
                            color: $white;
                            margin-bottom: 0;
                        }

                        small {
                            font-size: 1rem;
                        }

                        .icon {
                            fill: $white;
                            width: 23px;
                            margin-bottom: 5px;
                        }
                    }

                    .ant-card-body {
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        top: 0;
                        left: 0;
                        padding: 30px;
                    }
                }
            }
        }

        .explore-slider {
            .slick-list {
                margin: 0 -1.5rem;

                .slick-slide {
                    padding: 1rem 1.5rem;

                    @media screen and (max-width:991px) {
                        padding: 1rem 0.8rem;
                    }

                    .slider-card {
                        position: relative;
                        overflow: hidden;
                        border-radius: 1rem;
                        cursor: pointer;

                        &::after {
                            content: " ";
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            display: inline-block;
                            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
                            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
                            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=0);
                            z-index: 1;
                            top: 0;
                            transition: all ease-in 0.2s;
                        }

                        img {
                            width: 100%;
                        }

                        .caption {
                            position: absolute;
                            bottom: 30px;
                            left: 30px;
                            z-index: 2;
                            color: $white;

                            p {
                                font-size: 1.875rem;
                                margin-bottom: 0;
                                font-weight: normal;
                                line-height: 1.2;

                                @media screen and (max-width:991px) {
                                    font-size: 1.5rem;
                                }
                            }

                            h3 {
                                font-size: 2.5rem;
                                font-weight: 600;
                                color: $white;
                                margin-bottom: 0;

                                @media screen and (max-width:991px) {
                                    font-size: 2rem;
                                }
                            }
                        }

                        &:hover {
                            &::after {
                                background: -moz-linear-gradient(top, rgba($secondary-color, 0) 0%, rgba($secondary-color, 0.7) 100%);
                                background: -webkit-linear-gradient(top, rgba($secondary-color, 0) 0%, rgba($secondary-color, 0.7) 100%);
                                background: linear-gradient(to bottom, rgba($secondary-color, 0) 0%, rgba($secondary-color, 0.7) 100%);
                                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=0);
                            }
                        }
                    }
                }
            }

            .slick-arrow {
                color: $primary-color;
                font-size: 1.25rem;
                z-index: 2;
                width: auto;
                position: absolute;
                opacity: 1;

                &::before {
                    display: none;
                }

                &.slick-next {
                    bottom: -42px;
                    top: auto;
                    right: 0;
                }

                &.slick-prev {
                    bottom: -42px;
                    top: auto;
                    right: 50px;
                    left: auto;
                }

                &.slick-disabled {
                    color: #718092;
                    cursor: inherit;
                }
            }
        }

        .bottom-arrow {
            position: absolute;
            right: 120px;
            width: 50px;
            margin-top: 5px;
            fill: #718092;
            animation: iconcolor-change1 1s infinite;
        }

        .explor-bottom {
            margin-top: 3rem;

            h3 {
                font-size: 1.875rem;
                font-weight: 600;
                text-align: center;
            }

            ul {
                list-style-type: none;
                padding-left: 0;
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-gap: 1.5rem;
                max-width: 690px;
                margin: 3rem auto auto auto;

                @media screen and (max-width:740px) {
                    grid-template-columns: repeat(3, 1fr);
                }

                li {
                    text-align: center;

                    .icon {
                        height: 50px;
                        width: auto;
                        fill: $primary-color;
                        transition: all ease-in 0.1s;

                        @media screen and (max-width:480px) {
                            height: 40px;
                        }
                    }

                    p {
                        font-size: 1.25rem;
                        margin-top: 1rem;

                        @media screen and (max-width:480px) {
                            font-size: 1rem;
                        }
                    }

                    &:hover {
                        .icon {
                            fill: $secondary-color;
                        }
                    }

                }
            }
        }
    }

    .faq-section {
        padding: 1rem 0 10rem;

        @media screen and (max-width:767px) {
            padding: 1rem 0 2rem;
        }

        .faq-left {
            h1 {
                font-size: 3.125rem;
                max-width: 450px;
                line-height: 1.2;
                margin-bottom: 0;
                @media screen and (max-width:767px) {
                    font-size: 2.125rem;
                    margin-bottom: 1.5rem;
                }
            }

            .ant-card {
                max-width: 320px;
                position: relative;
                box-shadow: 0 13px 46px rgba(0, 0, 0, 0.24);
                border: 0;

                @media screen and (max-width: 600px) {
                    max-width: 100%;
                }

                .ant-card-cover {
                    img {
                        width: 100%;
                    }
                }

                .meta-row {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                    color: $white;

                    p {
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 1.3;
                    }

                    h3 {
                        font-size: 40px;
                        font-weight: 600;
                        color: $white;
                        margin-bottom: 0;
                    }

                    small {
                        font-size: 1rem;
                    }

                    .icon {
                        fill: $white;
                        width: 23px;
                        margin-bottom: 5px;
                    }
                }

                .ant-card-body {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    padding: 30px;
                }
            }
        }

        .faq-right {
            .download-btns {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 1rem;
                max-width: 330px;
                margin-top: 1rem;

                @media screen and (max-width:1280px) {
                    grid-template-columns: repeat(3, 1fr);
                }

                @media screen and (max-width:991px) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @media screen and (max-width:400px) {
                    grid-gap: 0.5rem;
                }

                .ant-btn{
                    border: 1px solid #718092;
                    height: 50px;
                    line-height: 50px;
                    padding: 0 15px;
                    color: $white;
                    font-size: 1rem;
                    display: flex;
                    align-items: center;
                    border-radius: 9px;
                    color: $primary-color;
                    transition: all ease-in 0.1s;
                    background: none;
                    font-weight: normal;
                    @media screen and (max-width:767px) {
                        font-size: 0.875rem;
                        padding: 0 8px 0 12px;
                    }
                    @media screen and (max-width:400px) {
                        font-size: 0.8rem;
                    }
                    .icon{
                        fill: $primary-color;
                        height: 25px;
                        width: 25px;
                        margin-right: 10px;
                        transition: all ease-in 0.1s;
                        @media screen and (max-width:767px) {
                            width: 20px;
                            height: 20px;
                            margin-right: 6px;
                        }
                        @media screen and (max-width:400px) {
                            width: 16px;
                            height: 16px;
                            margin-right: 4px;
                        }
                    }
                    &:hover{
                        background: $primary-color;
                        border-color: $primary-color;
                        color: $white;
                        transform: translateY(-4px);
                        .icon{
                            fill: $white;
                        }
                    }
                }
            }
            .ant-collapse {
                background: none;
                border: 0;

                .ant-collapse-item {
                    border: 0;
                    margin-bottom: 0.4rem;

                    .ant-collapse-header {
                        background: none;
                        border: 0;
                        font-size: 1.25rem;

                        &:hover{
                            color: $secondary-color;
                        }

                        .ant-collapse-expand-icon {
                            height: 30px;
                            display: flex;
                            align-items: center;
                            margin-right: 15px;

                            .icon {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }

                    &.ant-collapse-item-active {
                        .ant-collapse-header {
                            font-weight: 600;
                        }
                    }

                    .ant-collapse-content {
                        border: 0;

                        p {
                            font-size: 1rem;
                        }

                        .ant-collapse-content-box {
                            padding: 0 0 0 58px;
                        }
                    }
                }
            }
        }
    }

    .letsjoin-section {
        background: url('../../../assets/images/lets-join.jpg');
        background-size: cover;
        padding: 12rem 0;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-position: center center;

        @media screen and (max-width:991px) {
            background-size: 200%;
        }

        @media screen and (max-width:767px) {
            padding: 4rem 0;
            background-attachment: inherit;
        }

        @media screen and (max-width:576px) {
            padding: 2rem 0;
        }

        .letsjoin-row {
            .letsjoin-card {
                background: $secondary-color;
                color: $white;
                max-width: 373px;
                border-radius: 10px;
                padding: 2.5rem 90px 2.5rem 2.5rem;
                margin-left: auto;

                @media screen and (max-width:767px) {
                    padding: 1.5rem;
                }

                .upto-text{
                    line-height: 1;
                    margin-top: 0.5rem;
                }

                h3 {
                    font-size: 1.875rem;
                    color: $white;
                    font-weight: 600;
                    margin-bottom: 2rem;
                }

                h2 {
                    font-size: 3.125rem;
                    color: $white;
                    font-weight: 600;
                    margin-bottom: 0;
                    margin-top: 0;
                }

                p {
                    font-size: 1.25rem;
                    color: $white;
                    margin-bottom: 0;
                }

                .icon {
                    width: 29px;
                    fill: $white;
                }

                .ant-btn {
                    border: 1px solid $white;
                    border-radius: 40px;
                    height: 40px;
                    line-height: 38px;
                    padding: 0 20px;
                    font-size: 14px;
                    color: $white;
                    background: none;
                    margin-top: 1.5rem;
                }
            }
        }
    }

    .testomonial-section {
        padding: 8rem 0;

        @media screen and (max-width: 767px) {
            padding: 4rem 0;
        }

        .heading {
            font-size: 3.125rem;
            max-width: 425px;
            margin-bottom: 3.5rem;
        }

        .heading-p-tag {
            font-size: 1rem;
        }

        .testomonial-row {
            display: flex;
            align-items: center;
            max-width: 1100px;
            padding: 4rem 0 5rem 0;

            @media screen and (max-width: 767px) {
                display: block;
                padding: 0 0 2rem 0;
            }

            .testomonial-image {
                max-width: 360px;
                min-width: 360px;
                border-radius: 17px;
                width: 100%;

                @media screen and (max-width: 767px) {
                    min-width: 100%;
                }
            }

            .right-text {
                padding-left: 3.5rem;

                @media screen and (max-width: 767px) {
                    padding-left: 0;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }

                p {
                    font-size: 1.875rem;
                    font-style: italic;
                    margin-bottom: 2rem;

                    @media screen and (max-width: 767px) {
                        font-size: 1.2rem;
                    }
                }

                h3 {
                    font-size: 3.4375rem;
                    font-family: 'Ephesis', cursive;
                    margin-bottom: 0.8rem;
                    font-weight: normal;

                    @media screen and (max-width: 767px) {
                        font-size: 3rem;
                        margin-top: 1rem;
                    }
                }

                hr {
                    width: 104px;
                    margin-left: 0;
                }
            }
        }

        .testomonial-slider {
            .slick-arrow {
                color: $primary-color;
                font-size: 1.25rem;
                z-index: 2;
                position: absolute;
                opacity: 1;
                border: 1px solid $border-secondary;
                width: 40px;
                height: 40px;
                border-radius: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    fill: $primary-color;
                    width: 6px;
                }

                &::before {
                    display: none;
                }

                &.slick-next {
                    bottom: 0;
                    top: auto;
                    right: 0;
                }

                &.slick-prev {
                    bottom: 0;
                    top: auto;
                    right: 50px;
                    left: auto;
                }

                &.slick-disabled {
                    opacity: 0.5;
                    cursor: inherit;
                }
            }
        }

        .bottom-arrow {
            position: absolute;
            right: 120px;
            width: 50px;
            margin-top: 5px;
            fill: #718092;
            bottom: 0;
            animation: iconcolor-change1 1s infinite;
        }
    }
}

@keyframes textcolor-change1 {
    0% {
        color: $primary-color;
    }

    50% {
        color: $secondary-color;
    }

    100% {
        color: $primary-color;
    }
}

@keyframes iconcolor-change1 {
    0% {
        fill: $secondary-color;
    }

    50% {
        fill: $primary-color;
    }

    100% {
        fill: $secondary-color;
    }
}

@keyframes MoveUpDown {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(15px);
    }
}


@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba($white, 0.3),
            0 0 0 0.55em rgba($white, 0.3),
            0 0 0 1.1em rgba($white, 0.3),
            0 0 0 1.6em rgba($white, 0.3);
    }

    100% {
        box-shadow: 0 0 0 0.5em rgba($white, 0.3),
            0 0 0 1em rgba($white, 0.3),
            0 0 0 1.5em rgba($white, 0.3),
            0 0 0 2em rgba($white, 0);
    }
}
