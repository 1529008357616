@import "../../../assets/scss/variables.scss";

.tp-item-card-list {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 1.5rem;
  margin-bottom: 6rem;

  @media screen and (min-width:576px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width:991px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width:1124px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (min-width:1640px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media screen and (min-width:1880px) {
    grid-template-columns: repeat(6, 1fr);
  }
}
