@import '../../../../assets/scss/variables.scss';

.cardpay-section{
    .cardtitle{
        font-size: 1rem;
        font-weight: 600;
        color: $primary-color;
        img{
            margin-left: 12px;
            @media screen and (max-width:640px) {
                max-width: 100px;
            }
        }
    }
    .savedcard-slider{
        margin-top: 1rem;
        .slick-list{
            .slick-slide{
                padding-right: 1.3rem;
                &:last-child{
                    padding-right: 0;
                }
                .slider-card{
                    background: #FFF1EB;
                    border-radius: 10px;
                    padding: 20px;
                    position: relative;
                    &.selected{
                        &::after{
                            content: " ";
                            width: 26px;
                            height: 26px;
                            border-radius: 20px 0 6px 0;
                            background-color: #6FC3CE;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            background-repeat: no-repeat;
                            background-position: 8px 9px;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.666' height='9.758' viewBox='0 0 12.666 9.758'%3E%3Cpath id='Path_303889' data-name='Path 303889' d='M193.274 179.882l-4.154-4.152 1.248-1.248 2.906 2.906 7.267-7.265 1.246 1.246Z' transform='translate(-189.12 -170.124)' fill='%23fff'/%3E%3C/svg%3E");
                        }
                    }
                    .card-upper{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .card-icons{
                            width: 45px;
                            height: 45px;
                            img{
                                width: 100%;
                            }
                        }
                        .ant-btn{
                            background: $white;
                            width: 30px;
                            height: 30px;
                            border-radius: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0;
                            .icon{
                                width: 9px;
                                fill: $primary-color;
                            }
                        }
                    }
                    .card-bottom{
                        padding-top: 1rem;
                        h3{
                            font-size: 1rem;
                            font-weight: 600;
                        }
                        p{
                            font-size: 0.875rem;
                            margin-bottom: 0;
                        }
                    }
                }
                &:nth-child(1){
                    .slider-card{
                        background: #FFF1EB;
                    }
                }
                &:nth-child(2){
                    .slider-card{
                        background: #F1EAF2;
                    }
                }
                &:nth-child(3){
                    .slider-card{
                        background: #F0F9FA;
                    }
                }
                &:nth-child(4){
                    .slider-card{
                        background: #FFF1EB;
                    }
                }
                &:nth-child(5){
                    .slider-card{
                        background: #F1EAF2;
                    }
                }
                &:nth-child(6){
                    .slider-card{
                        background: #F0F9FA;
                    }
                }
            }
        }
        .slick-arrow{
            border: 1px solid $border-primary;
            width: 25px;
            height: 25px;
            border-radius: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            text-align: center;
            position: absolute;
            &::before{
                display: none;
            }
            .icon {
                width: 8px;
                height: 8px;
            }
            &.slick-next{
                right: 0;
                top: -35px;
            }
            &.slick-prev{
                right: 35px;
                top: -35px;
                left: auto;
            }
            &:hover{
                background: $primary-color;
                border-color: $primary-color;
                .icon{
                    fill: $white;
                }
            }
        }
    }
    .cards-bottom-row{
        margin-top: 1.5rem;
        .ant-btn{
            border: 1px solid $border-primary;
            height: 38px;
            line-height: 38px;
            padding: 0 12px 0 7px;
            font-size: 0.8125rem;
            border-radius: 38px;
            display: flex;
            align-items: center;
            .icon{
                background: $secondary-color;
                fill: $white;
                width: 24px;
                height: 25px;
                border-radius: 20px;
                padding: 7px;
                margin-right: 6px;
                transition: all ease-in 0.1s;
            }
            &:hover, &:focus{
                color: $white;
                background: $secondary-color;
                border-color: $secondary-color;
                .icon{
                    fill: $secondary-color;
                    background: $white;
                }
            }
        }
        .autorenew-text{
            font-size: 0.9rem;
            padding-right: 8px;
            padding-top: 5px;
            display: inline-block;
            font-weight: normal;
        }
    }
}

.emptycard-section{
    text-align: center;
    .upper-icon{
        padding: 2rem 0 1.5rem 0;
        .icon{
            width: 135px;
        }
    }
    .ant-btn{
        border: 1px solid $border-primary;
        height: 38px;
        line-height: 38px;
        padding: 0 12px 0 7px;
        font-size: 0.8125rem;
        border-radius: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        .icon{
            background: $secondary-color;
            fill: $white;
            width: 24px;
            height: 25px;
            border-radius: 20px;
            padding: 7px;
            margin-right: 6px;
            transition: all ease-in 0.1s;
        }
        &:hover, &:focus{
            color: $white;
            background: $secondary-color;
            border-color: $secondary-color;
            .icon{
                fill: $secondary-color;
                background: $white;
            }
        }
    }
}