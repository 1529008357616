@import "../../../assets/scss/variables.scss";

.search-results-dropdown {
  border-radius: 10px !important;
  box-shadow: 0 3px 36px rgba(0, 0, 0, 0.16);
  min-width: 400px !important;

  &.in-navbar{
    top: 142px !important;

    @media screen and (max-width: 767px) {
      top: 80px !important;
    }
  }
  
  @media screen and (max-width: 580px) {
    min-width: 94vw !important;
    max-width: 94vw;
    left: 0 !important;
    right: 0 !important;
    margin: auto;
  }

  .ant-radio-group {
    padding: 15px 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .ant-radio-button-wrapper {
      border: 1px solid $border-secondary;
      height: 30px;
      line-height: 30px;
      padding: 0 20px;
      border-radius: 30px;
      color: $primary-color;
      margin-right: 15px;
      text-align: center;
      width: 100%;
      

      .ant-radio-button {
        font-size: 12px;
      }

      &:last-child {
        margin: 0;
      }

      &::before {
        display: none;
      }

      &.ant-radio-button-wrapper-checked {
        background: #fff1eb;
        border-color: #fff1eb;
      }
    }
  }


  .ant-select-item {
    padding: 10px 15px;

    &:first-child {
      background: none;
    }

    .search-list-inner {
      display: flex;
      align-items: center;
      
      h3 {
        font-size: 15px;
        font-weight: 500;
      }

      .search-tags-row {
        border-bottom: 1px dashed $border-primary;
        width: 100%;
        padding: 0 0 10px 0;
      }

      .search-tag-list {
        display: flex;
        align-items: center;

        .tags {
          background: #e2f8ff;
          display: flex;
          align-items: center;
          padding: 4px 10px;
          border-radius: 4px;
          margin-right: 10px;
          border: 1px solid transparent;

          &:nth-child(1) {
            background: #e2f8ff;

            &:hover,
            &.selected {
              border-color: #71c4cf;
            }
          }

          &:nth-child(2) {
            background: #fff2fa;

            &:hover,
            &.selected {
              border-color: #ff7bcc;
            }
          }

          &:nth-child(3) {
            background: #f6efea;

            &:hover,
            &.selected {
              border-color: #ffb37c;
            }
          }

          .icon {
            margin-right: 4px;
          }
        }
      }

      .left-avatar {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        background: $white;
        border: 1px solid $border-secondary;

        img {
          height: 100%;
          object-fit: cover;
        }

        svg {
          height: 16px;
          fill: $primary-color;
        }
      }

      .search-list-right {
        line-height: 1.4;

        p {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 500;
          color: $primary-color;
        }

        label {
          font-size: 13px;
          color: $label-color;

          .icon {
            width: 8px;
            fill: $label-color;
          }
        }
      }
    }
  }
}