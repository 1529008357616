@import '../../assets/scss/variables.scss';

.listing-wrapper {
    .main-header {
        padding-left: 0;
        padding-right: 0;
    }

    .container {
        width: 100%;
        max-width: 2400px;
        padding: 0 80px;

        @media screen and (max-width:1200px) {
            padding: 0 40px;
        }

        @media screen and (max-width:767px) {
            padding: 0 25px;
        }
    }
}

.twl-listing-wrapper {

    .prive-mode & {
        background: $primary-color;
        color: $white;
    }
    
    .banner-section {
        background: #F5FCFC;
        padding-top: 8rem;
    }

    .globalsearch-section {
        margin-top: -3rem;
    }

    .category-section-main {
        background: $white;
        position: fixed;
        top: 70px;
        left: 0;
        right: 0;
        z-index: 9;

        .prive-mode & {
            background: $primary-color;
            color: $white;
        }

        .listing-container {
            width: 100%;
            padding: 0 80px;
            max-width: 2400px;

            @media screen and (max-width:1200px) {
                padding: 0 40px;
            }

            @media screen and (max-width:767px) {
                padding: 0 15px;
            }
        }
        
        .category-section {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.fixedCategory {
                border-bottom: 1px solid rgba($border-primary, 0.2);

                .category-listing-slider {
                    margin-top: 0;

                    .slick-list {
                        .slick-slide {
                            padding-bottom: 0;
                        }
                    }
                }
            }

            .ant-btn-primary {
                width: 80px;
                height: 40px;
                border-radius: 40px;
                padding: 0 15px;
                font-size: 13px;

                .icon {
                    width: 12px;
                    margin-left: 4px;
                }
            }
        }
    }

    .listing-bottom {
        padding: 12rem 0 2rem 0;

        @media screen and (max-width:991px) {
            padding: 12rem 0 0 0;
        }

        @media screen and (max-width:767px) {
            padding: 11rem 0 0 0;
        }

        .listing-container {
            width: 100%;
            padding: 0 80px;
            max-width: 2400px;

            @media screen and (max-width:1200px) {
                padding: 0 40px;
            }

            @media screen and (max-width:767px) {
                padding: 0 25px;
            }
        }

        .listing-bottom-header {
            align-items: center;

            h2 {
                font-size: 40px;
                margin-bottom: 0;

                @media screen and (max-width:991px) {
                    font-size: 30px;
                }

                span {
                    font-weight: normal;
                    display: block;
                }
            }

            .text-right {
                @media screen and (max-width:576px) {
                    display: flex;
                    margin-top: 20px;

                    .ant-btn {
                        width: 100%;
                    }
                }
            }

            .ant-btn {
                height: 40px;
                line-height: 40px;
                border-radius: 40px;
                padding: 0 20px;
                font-size: 16px;

                .icon {
                    margin-left: 8px;
                }

                &:nth-child(2) {
                    margin-left: 20px;

                    .icon {
                        fill: $white;
                    }
                }
            }
        }
        
        .map-btn {
            height: 50px;
            line-height: 50px;
            border-radius: 50px;
            padding: 0 36px;
            margin: 2rem auto auto auto;
            box-shadow: 0px 8px 13px #0A131F29;
            position: fixed;
            bottom: 85px;
            left: 0;
            right: 0;
            max-width: 125px;
            z-index: 9;

            @media screen and (max-width:991px) {
                bottom: 60px;
                height: 40px;
                line-height: 40px;
                max-width: 90px;
                padding: 0 20px;
                font-size: 13px;
            }

            .icon {
                fill: $white;
                margin-left: 8px;

                @media screen and (max-width:991px) {
                    width: 14px;
                    height: 10px;
                    margin-left: 5px;
                }
            }
        }
    }
}

