@import '../../../assets/scss/variables.scss';

.footer {
  margin-top: auto;
  background: $footer-bg;

  .upper-footer {
    padding: 3.5rem 0;

    .footer-col1 {
      @media screen and (max-width: 992px) {
        text-align: center;
      }
    }

    .download-link{
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      margin-top: 2.5rem;
      
      .ant-btn{
        border: 1px solid #718092;
        height: 50px;
        line-height: 50px;
        padding: 0 15px;
        color: $white;
        font-size: 1rem;
        display: flex;
        align-items: center;
        border-radius: 9px;
        color: $primary-color;
        transition: all ease-in 0.1s;
      }
    }
    
    .download-btns {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1.2rem;
      max-width: 330px;
      margin-top: 2.5rem;
      @media screen and (max-width: 992px) {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem;
      }

      a {
        border: 1px solid #718092;
        height: 50px;
        line-height: 50px;
        padding: 0 15px;
        color: $white;
        font-size: 1rem;
        display: flex;
        align-items: center;
        border-radius: 9px;
        color: $primary-color;
        transition: all ease-in 0.1s;
        @media screen and (max-width: 400px) {
          font-size: 0.9rem;
        } 

        .icon {
          fill: $primary-color;
          height: 25px;
          width: 25px;
          margin-right: 10px;
          transition: all ease-in 0.1s;
          @media screen and (max-width: 400px) {
            width: 20px;
            height: 20px;
          }
        }

        &:hover {
          background: $primary-color;
          border-color: $primary-color;
          color: $white;
          transform: translateY(-4px);

          .icon {
            fill: $white;
          }
        }
      }
    }

    h4 {
      margin-bottom: 1rem;
      margin-top: 1rem;
      font-size: 1rem;
      font-weight: 600;
      text-transform: uppercase;
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        padding: 10px 0;

        a {
          color: $text-primary;
          font-size: 1rem;

          &:hover {
            color: $secondary-color;
          }
        }
      }
    }
  }

  .bottom-footer {
    text-align: center;
    border-top: 1px solid rgba($border-primary, 0.5);
    padding: 1rem 0;

    p {
      font-size: 0.75rem;
      color: $primary-color;
      margin: 0;
    }
  }
}