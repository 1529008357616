@import "../../../assets/scss/variables.scss";

.cancel-sub {
    span {
        color: $primary-color;
        text-decoration: underline;
    }
}
.ant-btn.cancel-text {
    display: block;
    text-align: center;
    margin: 0 auto;
    font-size: 20px;
    letter-spacing: 0.4px;
    color: $primary-color !important;
}
.data-p {
    font-size: 20px;
    font-weight: 500;
    padding: 0 50px;
}