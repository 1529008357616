@import '../../../assets/scss/variables.scss';

.getlistedform-right {
    .contact-form {
        max-width: 600px;
        margin: auto;
        .ant-input {
            font-size: 1rem;
            resize: none;

            &::placeholder {
                font-weight: 600;
                color: $primary-color;
            }

            &::-moz-placeholder {
                font-weight: 600;
                color: $primary-color;
            }

            &::-webkit-placeholder {
                font-weight: 600;
                color: $primary-color;
            }
        }
    }
}