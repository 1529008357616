@import '../../../assets/scss/variables.scss';

.blog-detail-text{
    font-size: 1.3rem;
    line-height: 1.5;
}

.blogdtl-bottom-section{
    padding: 4rem 0;
    .blogdtl-header{
        h3{
            font-size: 2.5rem;
            font-weight: 600;
            margin-bottom: 8px;
            @media screen and (max-width:991px) {
                font-size: 1.1rem;
            }
        }
        h2{
            font-size: 3rem;
            font-weight: 700;
            @media screen and (max-width:991px) {
                font-size: 2.3rem;
            }
        }
    }
    .blog-details-row{
        margin: 2.5rem 0;
        .blog-details-header{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .header-left {
                margin-bottom: 2.5rem;
                .heading1 {
                    font-size: 3.2rem;
                    font-weight: 300;
                    margin-bottom: 0;
                    @media screen and (max-width:991px) {
                        font-size: 2.2rem;
                    }
                }
                .heading2 {
                    font-size: 3.2rem;
                    font-weight: 600;
                    margin-bottom: 0;
                    @media screen and (max-width:991px) {
                        font-size: 2.2rem;
                    }
                }
            }
            .header-right {
                text-align: right;
                margin-bottom: 2.5rem;
                .icon {
                    width: 13px;
                }
                p {
                    margin-bottom: 5px;
                    margin-top: 6px;
                    @media screen and (max-width:991px) {
                        font-size: 0.9rem;
                    }
                }
                h4 {
                    margin-bottom: 0;
                    font-size: 1.3rem;
                    @media screen and (max-width:991px) {
                        font-size: 0.9rem;
                    }
                }
            }
        }
        .blog-details-image{
            img {
                width: 100%;
                border-radius: 20px;
            }
        }
        .blog-details-bottom{
            margin-top: 30px;
            p {
                font-size: 1.4rem;
                line-height: 1.5;
                max-width: 900px;
                @media screen and (max-width:991px) {
                    font-size: 1.2rem;
                }
            }
        }
    }
}