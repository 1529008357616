@import "../../../assets/scss/variables.scss";

.search-city-dropdown{
  min-width: 250px !important;
  padding: 15px 30px;

/*  &.in-navbar{
    top: 142px !important;

    @media screen and (max-width: 767px) {
      top: 80px !important;
    }
  }*/
  
  .drop-title {
    font-size: 18px;
    font-weight: 600;
    color: $primary-color;
    margin-bottom: 5px;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  .ant-select-item {
    &.ant-select-item-group {
      display: flex;
      align-items: center;
      padding: 12px 0 5px 0;
      font-size: 14px;

      .city-img {
        width: 18px;
        height: 18px;
        border-radius: 18px;
        overflow: hidden;
        margin-right: 6px;

        .icon {
          width: 100%;
        }
      }
    }

    &.ant-select-item-option {
      padding: 8px 0;
      background: none;

      .ant-select-item-option-content {
        display: flex;
        align-items: center;
        font-size: 16px;

        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
        
        .city-img {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 9px;
          margin-right: 12px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}