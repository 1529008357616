@import '../../../assets/scss/variables.scss';

.getlistedform-inner {
    .offer-upper {
        text-align: left;
        padding-bottom: 0.8rem;
        .offerlist-img {
            background: $white;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90px;
            height: 90px;
            box-shadow: 0px 23px 36px #00000010;
            margin-bottom: 1.3rem;
            img {
                width: 100%;
            }
        }
        h3{
            font-size: 32px;
            margin-bottom: 5px;
            font-weight: 500;
        }
        h1{
            font-size: 40px;
            font-weight: 600;
        }
    }
}