@import '../../assets/scss/variables.scss';

.contactus-wrapper{
    display:flex;
    max-width: 100vw;
    width: 100%;
    @media screen and (max-width:767px) {
        flex-direction: column-reverse;
    }
    .auth-left{
        width: 50%;
        object-fit: fill;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        background: $primary-color;
        @media screen and (max-width:767px) {
            width: 100%;
        }
        .dextop-logo{
            @media screen and (max-width:767px) {
                display: none;
            }
        }
        .auth-bg {
            max-height: 100vh;
            width: 100%;
            object-fit: cover;
            @media screen and (max-width:767px) {
                width: auto;
                height: 100%;
                max-height: 100%;
                position: absolute;
            }
        }
        .left-middle{
            padding: 0 8%;
            @media screen and (max-width:767px) {
                padding: 35px 8%;
            }
            p{
                font-size: 18px !important;
                font-style: normal !important;
                a{
                    color: $secondary-color;
                    text-decoration: underline;
                }
            }
            ul {
                display: flex;
                align-items: center;
                list-style-type: none;
                padding-left: 0;
                li {
                    width: 40px;
                    height: 40px;
                    border-radius: 40px;
                    overflow: hidden;
                    margin-left: -15px;
                    &:first-child {
                        margin-left: 0;
                    }
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .banner-video{
            min-height: 100vh;
            max-height: 100vh;
            width: 100%;
            margin: auto;
            left: 0;
            right: 0;
            object-fit: cover;
            opacity: 0.7;
            @media screen and (max-width:767px) {
                width: auto;
                height: 100%;
                max-height: 100%;
                position: absolute;
            }
        }
        .authleft-inner{
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: absolute;
            width: 100%;
            padding: 5% 0 0 0;
            z-index: 1;
            @media screen and (max-width:767px) {
                position: inherit;
            }
            .left-upper{
                padding: 0 8%;
            }
            h1{
                color: $white;
                line-height: 1;
                margin-top: 3rem;
                font-size: 5rem;
                @media screen and (max-width:767px) {
                    font-size: 2rem;
                }
            }
            .awse-img{
                width: 70px;
                height: 70px;
                border-radius: 18px;
                object-fit: cover;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 1.4rem;
                img{
                    height: 100%;
                }
            }
            h3{
                font-size: 1.8rem;
                font-weight: 600;
                font-style: italic;
                margin-bottom: 8px;
                color: $white;
                margin-top: 0;
            }
            p{
                font-size: 1.8rem;
                font-style: italic;
                line-height: 1.4;
                max-width: 400px;
                color: $white;
                margin-bottom: 0;
                margin-top: 0;
            }
            .left-name {
                font-family: 'Ephesis', cursive;
                color: $white;
                font-size: 3.5rem;
                line-height: 1;
                margin-top: 20px;
            }
        }
        .contact-row{
            padding: 0 8%;
            margin-bottom: 10px;
            .colums{
                padding-bottom: 15px;
            }
            .icon-box{
                width: 52px;
                height: 52px;
                min-height: 52px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $white;
                margin-bottom: 18px;
                .icon {
                    fill: $white;
                    width: 18px;
                    height: 18px;
                }
            }
            h4{
                font-size: 17px;
                color: $white;
                margin-bottom: 6px;
                font-weight: 600;
            }
            p{
                font-size: 15px;
                font-style: normal;
                font-weight: normal !important;
            }
        }
        .bottom-row{
            border-top: 1px dashed rgba($white, 0.33);
            padding: 0 8%;
            .ant-btn {
                background: none;
                border: 0;
                padding: 15px 0;
                margin-right: 20px;
                .icon {
                    fill: $white;
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
    .auth-right{ 
        width: 50%;
        min-height: 100vh;
        text-align: center;
        display: flex;
        align-items: center;
        @media screen and (max-width:767px) {
            width: 100%;
            min-height: auto;
        }
        .success-wrapper {
            width: 100%;
            background-repeat: no-repeat;
            background-position: top center;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width:767px) {
                padding: 80px 20px;
            }
            .success-wrapper-inner{
                .check-circle{
                    border: 1px solid $success-color;
                    width: 145px;
                    height: 145px;
                    border-radius: 145px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: auto auto 25px auto;
                    .icon {
                        fill: $success-color;
                        width: 80px;
                    }
                }
                h2{
                    font-size: 30px;
                }
                p{
                    font-size: 20px;
                    margin-bottom: 40px;
                }
                .ant-btn {
                    padding: 0 50px;
                }
            }
        }
        .authright-inner{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            max-width: 700px;
            margin: auto;
            width: 100%;
            padding: 5vh 3vw;
            min-height: 100vh;
            @media screen and (max-width:1500px) {
                padding: 5vh 6vw;
            }
            @media screen and (max-width:767px) {
                min-height: 80vh;
            }
            .right-upper{
                .mobile-logo {
                    display: none;
                    text-align: left;
                    margin-bottom: 2rem;
                    @media screen and (max-width:767px) {
                        display: block;
                    }
                }
                .upper-inner{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    h2{
                        font-size: 2rem;
                        font-weight: 600;
                        margin: 0;
                    }
                    span {
                        color: #718092;
                        font-size: 13px;
                    }
                    .right-head{
                        text-align: right;
                        display: flex;
                        align-items: center;
                    }
                    .auth-upper-btn{
                        border: 1px solid $border-primary;
                        height: 30px;
                        line-height: 30px;
                        padding: 0 12px;
                        border-radius: 30px;
                        font-size: 12px;
                        font-weight: normal;
                        margin-left: 10px;
                        color: $text-primary;
                        min-width: 64px;
                        display: inline-block;
                        text-decoration: none;
                        text-align: center;
                        &:hover, &:focus{
                            color: $white;
                            border-color: $primary-color;
                        }
                    }
                }
            }
            .right-form{
                .ant-form-item{
                    .select-tnquiry{
                        position: relative;
                        .inquirytyle-icon {
                            width: 20px;
                            border-radius: 20px;
                            fill: $primary-color;
                            position: absolute;
                            z-index: 1;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 15px;
                        }
                        .ant-select{
                            .ant-select-selector{
                                padding-left: 45px;
                            }
                        }
                    }
                    .ant-form-item-label{
                        margin-bottom: 5px;
                        padding-left: 0;
                        font-size: $font-size-base;
                        label {
                            color: $primary-color;
                        }
                    }
                    .ant-input-affix-wrapper{
                        .ant-input-prefix{
                            .icon {
                                width: 20px;
                                border-radius: 20px;
                                fill: $primary-color;
                            }
                            .ccode{
                                font-weight: 600;
                                color: $primary-color;
                                font-size: 1rem;
                                margin-left: 4px;
                            }
                        }
                    }
                    .ant-input{
                        font-size: 1rem;
                        &::placeholder {
                            font-weight: 600;
                            color: $primary-color;
                        }
                    }
                }
            }
            .bottom-info {
                font-size: 14px;
                margin-top: 10px;
                a{
                    color: $secondary-color;
                }
            }
        }
    }
}

.inquery-type-drop{
    max-width: 320px;
    min-width: auto !important;
    padding: 0;
    .ant-select-item{
        color: $primary-color;
        font-size: 14px;
        font-weight: 600;
        padding: 8px 20px;
        &.ant-select-item-group {
            border-bottom: 1px dashed $border-primary;
            font-size: 20px;
            padding: 15px;
            font-weight: 600;
            color: $primary-color;
        }
    }
}